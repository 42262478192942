<template>
  <div class="wrap">
    <div class="table">
      <div class="header">
        <div
          v-for="each in columnDefs"
          :key="each"
          :class="[{ hidden: each.hidden, sortable: each.sort }, 'item']"
          :data-value="getField(each.field)"
          :style="{ flex: each.flex ? each.flex : 0 }"
          @click.stop="sort"
        >
          <input v-if="each.type === 'checkbox'" type="checkbox" @change="handleMasterCheckboxChange($event)" />
          {{ each.name }}
          <span class="direction">{{ getField(each.field) !== sortField.field ? '' : sortField.order === 'asc' ? '⇑' : '⇓' }}</span>
          <div v-if="searchable && each.field === 'symbol'" class="search-wrap">
            <div class="img" @click.stop="showSearch"><img :src="search" alt="" /></div>
            <div class="searc-input-wrap">
              <input v-model="searchQuery" placeholder="Поиск..." type="text" />
              <div class="close-search" @click="hideSearch">
                <img :src="cross" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="body" @click.stop="rowClick">
        <div
          v-for="row in processingRows(filteredTableData)"
          :key="row.key"
          :class="['row', row.symbol === selectedSymbol ? 'selected' : '', row.status === 'error' ? 'error' : '']"
          :data-value="row.symbol"
        >
          <template v-for="column in columnDefs" :key="column.field">
            <div
              v-if="['delete', 'remove'].includes(column.field)"
              v-show="row.deletedRow"
              :class="column.field === 'delete' ? 'delete-symbol' : 'remove-instrument'"
              @click.stop="deleteRow(row.data)"
            >
              <div>
                <!--                <img v-if="column.field === 'delete'" :src="cross" alt="" />-->
                <!--                <img v-if="row.deletedRow" :src="trash" alt="" />-->
                <img :src="column.field === 'delete' ? cross : trash" alt="" />
              </div>
            </div>
            <div v-else :class="[column.field === 'symbol' ? 'key' : '', 'item', column.field]" :style="{ flex: column.flex || 0 }">
              <div v-if="column.field === 'checkbox'">
                <input v-model="row.selected" type="checkbox" @change="handleCheckboxChange(row)" />
              </div>
              <div
                v-if="utils.formatValue(getValue(column, row), column.type)"
                :class="[column.color ? utils.getColorClass(getValue(column, row)) : '', 'item-title']"
              >
                {{ utils.formatValue(getValue(column, row), column.type) }}
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="footer"></div>
    </div>
    <!--    <div class="hide-overlay" @click="hideSearch"></div>-->
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import utils from '@/utils';
import cross from '@/assets/icon/close.svg';
import trash from '@/assets/icon/trash.svg';
import search from '@/assets/icon/search.svg';

export default {
  name: 'ComponentTable',
  emits: ['close', 'action'],
  data() {
    return {
      searchQuery: '',
      cross,
      trash,
      search,
      sortField: {}, // 'asc', // 'desc'
      filterArr: [],
      utils,
    };
  },
  components: {},
  props: {
    searchable: {
      type: Boolean,
      default: false,
    },
    columnDefs: {
      type: Array,
      requred: true,
    },
    rows: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters({
      selectedSymbol: 'getSelectedSymbol',
    }),
    filteredTableData() {
      return this.rows.filter((item) => {
        return item?.symbol.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({}),
    handleMasterCheckboxChange(event) {
      const masterCheckboxState = event.target.checked;
      this.filteredTableData.forEach((row) => {
        row.selected = masterCheckboxState;
        // row.selected = masterCheckboxState;
      });
      console.log('all :', masterCheckboxState);
    },
    handleCheckboxChange(row) {
      console.log(row);
      // row.selected = !row.selected;
    },
    showSearch(e) {
      // console.log('show :', e.target.closest('.search-wrap'));
      const el = e.target.closest('.search-wrap');
      el.classList.add('active');
    },
    hideSearch() {
      // console.log(123);
      let searchWraps = document.querySelectorAll('.search-wrap');
      searchWraps.forEach((searchWrap) => {
        if (searchWrap.classList.contains('active')) {
          searchWrap.classList.remove('active');
        }
      });
    },
    rowClick: function (e) {
      // console.log('row');
      // this.hideSearch();
      const target = e.target;
      const symbol = target.closest('.row').dataset.value;
      this.updateSelectedSymbol(symbol);
      if (this.isMobile) this.$router.push({ name: 'orders' });
    },
    getValue: (column, row) => {
      if (typeof column.field === 'string') return row[column.field];
      if (Array.isArray(column.field)) {
        const result = [];
        for (const item of column.field) result.push({ value: row[item.field], type: item.type });
        return result;
      }
      return null;
    },
    deleteRow(data) {
      this.$emit('action', data);
    },
    getField(field) {
      if (Array.isArray(field)) return field[0].field;
      // console.log('field :', field);
      // if (field === 'checkbox') {
      //   console.log(123);
      // }
      return field;
    },
    sort(event) {
      // this.hideSearch();
      const target = event.target;
      // console.log(target);
      if (target.classList.contains('sortable')) {
        let field = target.dataset.value;
        // console.log(typeof field, field, this.sortField, this.sortField.field !== field);
        if (Object.keys(this.sortField).length === 0 || this.sortField.field !== field)
          return void (this.sortField = { field, order: 'asc' });
        if (this.sortField.order === 'asc') return void (this.sortField.order = 'desc');
        if (this.sortField.order === 'desc') return void (this.sortField = {});
      }
    },
    processingRows(rows) {
      const localRows = rows.map((row) => ({ ...row }));
      // console.log('table :', localRows);
      if (Object.keys(this.sortField).length === 0) return localRows;
      return localRows.sort((a, b) => {
        const order = this.sortField.order === 'asc' ? 1 : -1;
        return order * (a[this.sortField.field] > b[this.sortField.field] ? 1 : a[this.sortField.field] < b[this.sortField.field] ? -1 : 0);
      });
    },
  },
  watch: {},
  created() {},
  mounted() {
    // document.addEventListener('click', this.hideSearch);
  },
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.row {
  display: flex;
}

.table {
  overflow-y: auto;

  .header {
    background: var(--table-header-bg-color);
    box-shadow: -1px 1px 3px var(--table-header-shadow-color);
    color: var(--table-column-text-header-color);
    font-size: 12px;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 2;

    .item {
      border: none;
      font-weight: 400;
      position: relative;
      overflow: visible;

      .title {
        pointer-events: none;
      }

      span.direction {
        position: absolute;
        right: 22px;
        top: 50%;
        transform: translate(0, -50%);
      }

      &[data-value='delete'],
      &[data-value='remove'] {
        width: 0;
        flex: 0;
        padding: 0;
      }

      &[data-value='symbol'] {
        z-index: 10;
      }
    }
  }

  .row {
    color: var(--table-text-color);
    background-color: transparent;
    transition: background-color 0.2s ease-in;
    position: relative;
    cursor: pointer;

    &.selected,
    &:hover {
      background-color: var(--table-row-bg-hover-color);

      .delete-symbol {
        pointer-events: all;

        img {
          opacity: 1;
          transform: scale(1);
        }

        &:not(:empty) + .item {
          .item-title {
            transform: translate(10px, 0);
          }
        }
      }
    }

    &:last-child {
      border-bottom: none;
    }

    &.error {
      outline: 1px solid var(--error);
      color: var(--error);
      font-weight: bold;
    }
  }

  .item {
    padding: 5px 10px;
    border: 1px solid var(--table-totals-divider-line-color);
    border-right: none;
    border-top: none;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:last-child {
      border-right: 1px solid var(--table-totals-divider-line-color);
    }

    //&.checkbox {
    //  display: none !important;
    //}

    &.hidden {
      display: none;
    }
  }
}

.key {
  font-weight: bold;
}

.item-title {
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.delete-symbol {
  pointer-events: none;
  cursor: pointer;
  padding: 0 4px;
  width: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  & > div {
    width: 100%;
  }

  img {
    transform: scale(0.5);
    transition: all 0.2s ease;
    width: 100%;
    height: 100%;
    opacity: 0;
    object-fit: contain;
  }
}

.remove-instrument {
  @extend .delete-symbol;
  right: 10px;
  left: auto;
}

.search-wrap {
  //position: absolute;
  //right: 5px;
  //top: 50%;
  //left: 0;
  //pointer-events: none;
  //transform: translate(0, -50%);

  .img {
    width: 14px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &.active {
    .searc-input-wrap {
      transform: translate(0, -50%);

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: -5px;
        bottom: 0;
        z-index: -1;
        backdrop-filter: blur(10px);
      }
    }
  }

  .searc-input-wrap {
    position: absolute;
    top: 50%;
    transform: translate(-100%, -50%);
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .close-search {
      margin-left: 4px;
      width: 14px;
      height: 14px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

//.hide-overlay {
//  top: 0;
//  bottom: 0;
//  left: 0;
//  right: 0;
//  background: rgba(125, 134, 178, 0.3);
//  z-index: 99;
//
//  &.active {
//    position: fixed;
//  }
//}
</style>
