<template>
  <div class="keys">
    <form>
      <div class="info">
        <button v-if="!telegramId" class="send primary" @click.prevent="invite">Пригласить</button>
        <div v-if="telegramId">{{ telegramId }}</div>
        <div class="description">
          Telegram bot очень удобен, позволяет быть на связи даже при ограниченном интергете и хранит историю всех отправленных приказов
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'TelegramComponent',
  components: {},
  data: function () {
    return {
      telegramId: null,
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      inviteTelegram: 'inviteTelegram',
    }),
    invite() {
      this.inviteTelegram();
    },
  },
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.keys {
  form {
    width: 60%;
    @include breakpoint-down(md) {
      width: 100%;
    }

    .input-item {
      margin-bottom: 6px;
    }

    input {
      width: 100%;
    }
  }

  .info {
    display: flex;
    gap: 0 20px;
    margin-top: 20px;

    .description {
      font-size: 11px;
    }
  }

  label {
    color: var(--table-column-text-header-color);
    font-size: 12px;
    display: block;
  }
}
</style>
