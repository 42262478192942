<template>
  <div class="terminal-data-setting">
    <div class="setting-item">
      <div class="title">Время обновления</div>
      <div class="flex-wrap">
        <c-select v-model="localTimeUpdate" :options="timeUpdateTerminalArray" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import CSelect from '@/components/UI/ComponentSelect.vue';

export default {
  name: 'ChartSetting',
  components: { CSelect },
  data: function () {
    return {
      localTimeUpdate: null,
      timeUpdateTerminalArray: [
        { value: '5', text: '5 секунд' },
        { value: '10', text: '10 секунд' },
        { value: '15', text: '15 секунд' },
        { value: '30', text: '30 секунд' },
        { value: '60', text: '1 минута' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      timeUpdateTerminal: 'getTimeUpdateTerminal',
    }),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      updateSettings: 'updateSettings',
    }),
  },
  watch: {
    timeUpdateTerminal(newState) {
      this.localTimeUpdate = newState;
    },
    localTimeUpdate(newState) {
      if (this.timeUpdateTerminal !== newState)
        this.updateSettings({
          setting: { main: { refresh: newState } },
          update: true,
        });
    },
  },
  created() {},
  mounted() {
    this.localTimeUpdate = this.timeUpdateTerminal;
  },
  unmounted() {},
};
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 8px;
}
</style>
