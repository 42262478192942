<template>
  <div class="agreement">
    <div class="title">
      Appendix 1 to the Management investments agreement. Agreement of adherence (Individuals) <br />
      Приложение 1 к Договору управления активами. Соглашение о присоединении (физ. лицо)
    </div>
    <div class="subtitle">
      Agreement of adherence / Соглашение о присоединении <br />
      NoPF
    </div>
    <div class="agreement-item">
      <div>
        <div class="agreement-item-title">Disclosure</div>
        <div class="agreement-item-info">
          Partner Finance Limited (the Manager) holds the license AFSA-A-LA-2022-0042 conditions of obtaining and use of which are aimed at
          protection of interests of Clients. To achieve this goal the Manager has to comprehensively understand the Client, the nature of
          his business, the source of funds and have a full understanding of his investment goals, financial situation and investment
          experience.
        </div>
      </div>
      <div>
        <div class="agreement-item-title">Уведомление</div>
        <div class="agreement-item-info">
          Partner Finance Limited (Управляющий) обладает лицензией AFSA-A-LA-2020-0042 условия получения и использования которой направлены
          на защиту интересов Клиентов. Для исполнения данной цели Управляющему необходимо всесторонне понять Клиента, характер его бизнеса,
          источник средств и иметь полное понимание его инвестиционных целей, финансового положения и инвестиционного опыта.
        </div>
      </div>
    </div>

    <div class="agreement-item">
      <div>
        <div class="agreement-item-info">
          In accordance with the anti-money laundering policies, procedures and programs, the Client confirms that all statements made in
          this Agreement and all information provided to the Manager, documentary or otherwise, is complete, true and accurate. The
          information is provided on the Client's initiative and the Client agrees to be fully responsible for all the information provided
          and has no objections to the fact that the information is collected to confirm his business reputation in accordance with the
          practice used on international securities markets.
        </div>
      </div>
      <div>
        <div class="agreement-item-info">
          В соответствии с политикой, процедурами и программами по противодействию отмыванию денежных средств, Клиент подтверждает, что все
          заявления, сделанные в данном Соглашении, и вся информация, предоставленная Управляющему в документальной или иной форме, является
          полной, правдивой и точной. Информация предоставляется по инициативе Клиента, и он соглашается нести за нее полную ответственность
          и не возражает против того, что информация собирается для подтверждения его деловой репутации в соответствии с практикой,
          применяемой на международных рынках ценных бумаг.
        </div>
      </div>
    </div>

    <div class="agreement-item">
      <div>
        <div class="agreement-item-info">
          In accordance with the anti-money laundering policies, procedures and programs, the Client confirms that all statements made in
          this Agreement and all information provided to the Manager, documentary or otherwise, is complete, true and accurate. The
          information is provided on the Client's initiative and the Client agrees to be fully responsible for all the information provided
          and has no objections to the fact that the information is collected to confirm his business reputation in accordance with the
          practice used on international securities markets.
        </div>
      </div>
      <div>
        <div class="agreement-item-info">
          В соответствии с политикой, процедурами и программами по противодействию отмыванию денежных средств, Клиент подтверждает, что все
          заявления, сделанные в данном Соглашении, и вся информация, предоставленная Управляющему в документальной или иной форме, является
          полной, правдивой и точной. Информация предоставляется по инициативе Клиента, и он соглашается нести за нее полную ответственность
          и не возражает против того, что информация собирается для подтверждения его деловой репутации в соответствии с практикой,
          применяемой на международных рынках ценных бумаг.
        </div>
      </div>
    </div>

    <div class="agreement-item">
      <div>
        <div class="agreement-item-info">
          Failure to provide the information requested in this Agreement deprives the Manager of the ability to properly assess the
          suitability of the requested services, which will result in the inability to provide services to the Client.
        </div>
      </div>
      <div>
        <div class="agreement-item-info">
          Непредоставление информации, запрошенной в данном Соглашении, лишает Управляющего возможности правильно оценить пригодность
          запрашиваемых услуг, что приведёт к невозможности предоставлять услуги Клиенту.
        </div>
      </div>
    </div>

    <div class="agreement-item">
      <div>
        <div class="agreement-item-info">
          The information provided in this Agreement must remain confidential and must not be disclosed by the Manager to a third party
          without the Client's consent, except in cases where this is explicitly required in accordance with applicable laws and
          regulations. The Client agrees and acknowledges that, if it is required to disclose any such information, the Manager must,
          without the Client's additional consent, provide information only to the extent required by the relevant rules and only to the
          person specified in such rules.
        </div>
      </div>
      <div>
        <div class="agreement-item-info">
          Информация, представленная в этом Соглашении, должна оставаться конфиденциальной и не должна раскрываться Управляющим третьей
          стороне без согласия Клиента, за исключением случаев, когда это явным образом требуется в соответствии с применимыми законами и
          правилами. Клиент соглашается и признает, что, если требуется раскрыть любую такую информацию, Управляющий должен без
          дополнительного согласия Клиента предоставлять информацию только в той степени, которая требуется соответствующими правилами, и
          только лицу, указанному в таких правилах.
        </div>
      </div>
    </div>

    <div class="agreement-item">
      <div>
        <div class="agreement-item-info">Please fill out the fields COMPLETELY using clear, capital letters.</div>
      </div>
      <div>
        <div class="agreement-item-info">Пожалуйста, заполните поля ПОЛНОСТЬЮ используя понятные, заглавные буквы.</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'AgreementInfoComponent',
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({}),
  },
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style scoped lang="scss">
.agreement {
  color: var(--white);
  .title {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 30px;
  }

  .subtitle {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 18px;
  }

  .agreement-item {
    display: flex;

    & > div {
      width: 50%;
      padding: 0 40px;
    }
  }

  .agreement-item-title {
    margin-bottom: 10px;
    font-weight: 500;
  }

  .agreement-item-info {
    margin-bottom: 12px;
  }
}
</style>
