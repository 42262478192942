export default [
  {
    contact_id: '313f5bac-26ca-fa5c-4f0c-61f38a54d9c7',
    full_name: 'Краснослабодцев Дмитрий Сергеевич',
    telegram_id: null,
    unique: '313f5bac-26ca-fa5c-4f0c-61f38a54d9c7_117f86a4-ac17-1c24-b284-65439d046cf7',
    contract_id: '117f86a4-ac17-1c24-b284-65439d046cf7',
    contract_name: 'PF20230243',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '7cbb1b84-6685-2e9c-db85-5de3aadad652',
    full_name: 'Сулименко Алексей Сергеевич',
    telegram_id: '368288031',
    unique: '7cbb1b84-6685-2e9c-db85-5de3aadad652_1454d4c6-cc8b-0799-8971-64a7e1af39a4',
    contract_id: '1454d4c6-cc8b-0799-8971-64a7e1af39a4',
    contract_name: '808972557',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '7cbb1b84-6685-2e9c-db85-5de3aadad652',
    full_name: 'Сулименко Алексей Сергеевич',
    telegram_id: '368288031',
    unique: '7cbb1b84-6685-2e9c-db85-5de3aadad652_60421453-be41-ef7d-987c-642581533873',
    contract_id: '60421453-be41-ef7d-987c-642581533873',
    contract_name: 'PF20220015',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 50000,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '7cbb1b84-6685-2e9c-db85-5de3aadad652',
    full_name: 'Сулименко Алексей Сергеевич',
    telegram_id: '368288031',
    unique: '7cbb1b84-6685-2e9c-db85-5de3aadad652_92dd72f0-5f92-b9c0-0dde-5de688b49446',
    contract_id: '92dd72f0-5f92-b9c0-0dde-5de688b49446',
    contract_name: 'U2430752',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 30000,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'bf51acc4-560d-04aa-a11d-61680b15a5f3',
    full_name: 'Бельгимбаев Асет Балташевич',
    telegram_id: '504926374',
    unique: 'bf51acc4-560d-04aa-a11d-61680b15a5f3_59e40927-fa4c-97b7-157f-6405add58d7f',
    contract_id: '59e40927-fa4c-97b7-157f-6405add58d7f',
    contract_name: 'PF20230054',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '6c32792a-59aa-4ea6-2442-5e01fc4be7fb',
    full_name: 'Иващенко Андрей Иванович',
    telegram_id: null,
    unique: '6c32792a-59aa-4ea6-2442-5e01fc4be7fb_164e23b7-5392-391f-ce39-65c4d0a68366',
    contract_id: '164e23b7-5392-391f-ce39-65c4d0a68366',
    contract_name: 'PF20240400',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '77faf151-46f5-862c-32c8-6565d0af6c73',
    full_name: 'Абдуллаева Самая Эльхан Кызы',
    telegram_id: null,
    unique: '77faf151-46f5-862c-32c8-6565d0af6c73_17f00df5-a667-7360-6378-6565d1948940',
    contract_id: '17f00df5-a667-7360-6378-6565d1948940',
    contract_name: '7K9469',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'c38bbc99-9d9c-979a-06f5-614c4d48e2e0',
    full_name: 'Искаков Каиржан Арманович',
    telegram_id: '228821581',
    unique: 'c38bbc99-9d9c-979a-06f5-614c4d48e2e0_1b2295bc-b5bc-07d9-08b8-6537887eae2f',
    contract_id: '1b2295bc-b5bc-07d9-08b8-6537887eae2f',
    contract_name: '299335220',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '558d2258-8a95-173e-3cb0-6048a5057214',
    full_name: 'Пыхарев Станислав Сергеевич',
    telegram_id: '429327636',
    unique: '558d2258-8a95-173e-3cb0-6048a5057214_5a69fe6a-cfcd-440d-3aaf-6527e1c0b985',
    contract_id: '5a69fe6a-cfcd-440d-3aaf-6527e1c0b985',
    contract_name: '265739364',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '76ade264-e914-a456-4ce9-5e3bcb0a248d',
    full_name: 'Пономаренко Елена Сергеевна',
    telegram_id: null,
    unique: '76ade264-e914-a456-4ce9-5e3bcb0a248d_ca4a94eb-46c2-4ea2-ff79-6503175752f1',
    contract_id: 'ca4a94eb-46c2-4ea2-ff79-6503175752f1',
    contract_name: 'PF20230264',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '51009622-c54b-3501-bb17-5e3134f9145e',
    full_name: 'Бекмухаметов Рамин Гафурович',
    telegram_id: '675243459',
    unique: '51009622-c54b-3501-bb17-5e3134f9145e_2357e51b-88bf-c3fe-428c-64e86103b903',
    contract_id: '2357e51b-88bf-c3fe-428c-64e86103b903',
    contract_name: 'PF20230132',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'd9b762a1-d96f-572e-5973-63bbf49621fc',
    full_name: 'Самофалов Евгений',
    telegram_id: '728368646',
    unique: 'd9b762a1-d96f-572e-5973-63bbf49621fc_250422ba-715f-a988-b66b-644bbdfcb3ee',
    contract_id: '250422ba-715f-a988-b66b-644bbdfcb3ee',
    contract_name: 'PF20230072',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'da5f8cb3-f34e-65b0-3828-65127b4c554b',
    full_name: 'Нусупова Юлия Михайловна',
    telegram_id: '463412346',
    unique: 'da5f8cb3-f34e-65b0-3828-65127b4c554b_253ef574-bf5f-449f-2f97-6516cb8ecb77',
    contract_id: '253ef574-bf5f-449f-2f97-6516cb8ecb77',
    contract_name: 'PF20230360',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 9000,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'aae40e4f-d150-0a2e-64bf-5ebbc88c492f',
    full_name: 'Хон Атама Геннадьевич',
    telegram_id: null,
    unique: 'aae40e4f-d150-0a2e-64bf-5ebbc88c492f_d3c88908-1855-42d1-b450-655f0d8f2f36',
    contract_id: 'd3c88908-1855-42d1-b450-655f0d8f2f36',
    contract_name: 'PF20230201',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '1f07ee4f-5256-6ffc-9c7b-621f33c31097',
    full_name: 'Искендиров Досболат',
    telegram_id: '815340624',
    unique: '1f07ee4f-5256-6ffc-9c7b-621f33c31097_31391bba-2118-84ac-6054-64f5da118a5d',
    contract_id: '31391bba-2118-84ac-6054-64f5da118a5d',
    contract_name: 'PF20230228',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'a5b029df-6624-a160-f36b-610121371a1f',
    full_name: 'Арынгазин Алибек',
    telegram_id: '455455785',
    unique: 'a5b029df-6624-a160-f36b-610121371a1f_8807ccba-ec53-a785-b5cc-6151dc17da4d',
    contract_id: '8807ccba-ec53-a785-b5cc-6151dc17da4d',
    contract_name: 'U7161141',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: null,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '16d745c2-e292-8a02-ad61-5eb15ce51129',
    full_name: 'Ушаков Виталий Александрович',
    telegram_id: '1214089235',
    unique: '16d745c2-e292-8a02-ad61-5eb15ce51129_350334bf-4510-2cae-df42-5eb15c18604c',
    contract_id: '350334bf-4510-2cae-df42-5eb15c18604c',
    contract_name: 'U3366051',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 17000,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'd9096ea0-1842-79ce-0ed5-5e0636f10efc',
    full_name: 'Ибрагимова Аида Кадыровна',
    telegram_id: '649435014',
    unique: 'd9096ea0-1842-79ce-0ed5-5e0636f10efc_4ecf99da-0dea-801e-4852-652693ca14aa',
    contract_id: '4ecf99da-0dea-801e-4852-652693ca14aa',
    contract_name: '278496217',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'd9096ea0-1842-79ce-0ed5-5e0636f10efc',
    full_name: 'Ибрагимова Аида Кадыровна',
    telegram_id: '649435014',
    unique: 'd9096ea0-1842-79ce-0ed5-5e0636f10efc_b483d4bd-ffd3-72b5-121c-64fef0987590',
    contract_id: 'b483d4bd-ffd3-72b5-121c-64fef0987590',
    contract_name: 'PF20230249',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '32798710-be1a-e13e-4159-5de6486b9c2e',
    full_name: 'Куантаев Нысанбай Есеркепулы',
    telegram_id: null,
    unique: '32798710-be1a-e13e-4159-5de6486b9c2e_3f67ea76-a71d-eaac-5148-65b0ec145b48',
    contract_id: '3f67ea76-a71d-eaac-5148-65b0ec145b48',
    contract_name: 'PF20230204',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '6a9f98e0-08c4-7411-6dee-6298946dc878',
    full_name: 'Бердюгин Александр Анатольевич',
    telegram_id: null,
    unique: '6a9f98e0-08c4-7411-6dee-6298946dc878_40a616e0-f4a0-c98f-750a-64a7e2b45ede',
    contract_id: '40a616e0-f4a0-c98f-750a-64a7e2b45ede',
    contract_name: 'PA3JCPWSL801',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '6a9f98e0-08c4-7411-6dee-6298946dc878',
    full_name: 'Бердюгин Александр Анатольевич',
    telegram_id: null,
    unique: '6a9f98e0-08c4-7411-6dee-6298946dc878_aef35c83-8a9e-fbb5-ce81-64a7e1eaf955',
    contract_id: 'aef35c83-8a9e-fbb5-ce81-64a7e1eaf955',
    contract_name: '166127067',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '6be90d58-08ec-bf81-3290-5f72bf58c089',
    full_name: 'Нурманов Сагди Исильбекович',
    telegram_id: '326835047',
    unique: '6be90d58-08ec-bf81-3290-5f72bf58c089_445c824b-01d6-34df-d827-5face535e6ee',
    contract_id: '445c824b-01d6-34df-d827-5face535e6ee',
    contract_name: 'U4747821',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 10000,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'dfc8a562-5f83-8d1f-f5a3-5e608b64a0b8',
    full_name: 'Абдыкасымова Лидия Киымбековна',
    telegram_id: null,
    unique: 'dfc8a562-5f83-8d1f-f5a3-5e608b64a0b8_450d8014-36c0-582a-827d-64f9d4336bfe',
    contract_id: '450d8014-36c0-582a-827d-64f9d4336bfe',
    contract_name: 'PF20230237',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '931b86b5-42a5-d296-0143-61935ee4be0f',
    full_name: 'Адилханов Алпамыс Ерланович',
    telegram_id: '409546010',
    unique: '931b86b5-42a5-d296-0143-61935ee4be0f_4a9b69aa-0901-5aa5-4015-6540ebef6052',
    contract_id: '4a9b69aa-0901-5aa5-4015-6540ebef6052',
    contract_name: '294583623',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'b7e4aba6-0410-a549-98bc-63edd52e1658',
    full_name: 'Тургамбаева Галия Сабыржановна',
    telegram_id: '1506555',
    unique: 'b7e4aba6-0410-a549-98bc-63edd52e1658_488ff65c-7914-253b-c81b-6406fdb53148',
    contract_id: '488ff65c-7914-253b-c81b-6406fdb53148',
    contract_name: 'PF20230066',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 100000,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'ccfdec0b-4774-2562-e690-5e06392e28e7',
    full_name: 'Успанова Гульмира Избергеновна',
    telegram_id: '867973946',
    unique: 'ccfdec0b-4774-2562-e690-5e06392e28e7_4a339194-0e63-04bc-222e-653228ad3087',
    contract_id: '4a339194-0e63-04bc-222e-653228ad3087',
    contract_name: '301105961',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '93b02797-9937-2a77-1c12-5ff41f05a53b',
    full_name: 'Темирбаев Жанболат Ермекулы',
    telegram_id: '148751914',
    unique: '93b02797-9937-2a77-1c12-5ff41f05a53b_bb25e7bc-00a5-5f03-d111-64ede60b150d',
    contract_id: 'bb25e7bc-00a5-5f03-d111-64ede60b150d',
    contract_name: 'PF20230105',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '78d4acc1-a992-b073-c25a-625d41abdbad',
    full_name: 'Уразбаев Ганибек Жанибекович',
    telegram_id: null,
    unique: '78d4acc1-a992-b073-c25a-625d41abdbad_4b55f74b-a198-4a73-f1da-648ac34ca9be',
    contract_id: '4b55f74b-a198-4a73-f1da-648ac34ca9be',
    contract_name: 'PF20230078',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '277d4066-7e6d-5a0e-192f-601bdf61a115',
    full_name: 'Иман Дулат Бекетович',
    telegram_id: null,
    unique: '277d4066-7e6d-5a0e-192f-601bdf61a115_4c021d93-8cba-f06a-2542-65c5d9f9816b',
    contract_id: '4c021d93-8cba-f06a-2542-65c5d9f9816b',
    contract_name: 'PF20240399',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '83fefe63-fbae-0678-9d04-5de63d0b2f7c',
    full_name: 'Левина Лариса Жоржевна',
    telegram_id: null,
    unique: '83fefe63-fbae-0678-9d04-5de63d0b2f7c_4f4a5d22-9ed8-8473-1dab-65b3a7d87fb1',
    contract_id: '4f4a5d22-9ed8-8473-1dab-65b3a7d87fb1',
    contract_name: 'PF20240394',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '6a16c6ed-b0f0-6666-8417-65b8ecafef66',
    full_name: 'Данияров Жомарт Канатулы',
    telegram_id: '494330142',
    unique: '6a16c6ed-b0f0-6666-8417-65b8ecafef66_500d8fe8-af17-2656-1a57-65c2053fa752',
    contract_id: '500d8fe8-af17-2656-1a57-65c2053fa752',
    contract_name: 'PF20240397',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '63a0f1e5-63eb-52cf-755e-5e060c4d31b6',
    full_name: 'Абраимов Алмаз Маратович',
    telegram_id: '776124782',
    unique: '63a0f1e5-63eb-52cf-755e-5e060c4d31b6_5485aea8-5290-8285-4252-5e060d761f40',
    contract_id: '5485aea8-5290-8285-4252-5e060d761f40',
    contract_name: 'U3154743',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: null,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'bf7ebb5c-a8ea-e0ca-96a1-65d60325e7f6',
    full_name: 'Аруин Михаил',
    telegram_id: null,
    unique: 'bf7ebb5c-a8ea-e0ca-96a1-65d60325e7f6_55203e23-ecbe-fd7d-2e11-65d604705965',
    contract_id: '55203e23-ecbe-fd7d-2e11-65d604705965',
    contract_name: '7O4551',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'ee8029b4-7df8-17bd-0356-5eaa99180b31',
    full_name: 'Жаншуакова Айым Акановна',
    telegram_id: '758287071',
    unique: 'ee8029b4-7df8-17bd-0356-5eaa99180b31_5a6c4d36-2a1b-e36e-8869-64f99201a465',
    contract_id: '5a6c4d36-2a1b-e36e-8869-64f99201a465',
    contract_name: 'PF20230366',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '393dfa46-a137-b666-2535-603774df3716',
    full_name: 'Кутявин Андрей Александрович',
    telegram_id: null,
    unique: '393dfa46-a137-b666-2535-603774df3716_5bea4d4b-04c0-0309-f0a8-657ac0c0c641',
    contract_id: '5bea4d4b-04c0-0309-f0a8-657ac0c0c641',
    contract_name: 'PF20230276',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '8e9b5c0e-3ccf-f260-b7d5-625d42df9729',
    full_name: 'Синенко Виктор Владимирович',
    telegram_id: null,
    unique: '8e9b5c0e-3ccf-f260-b7d5-625d42df9729_5dc68395-72a1-2bbf-57ad-64e3295d1ab1',
    contract_id: '5dc68395-72a1-2bbf-57ad-64e3295d1ab1',
    contract_name: 'PF20230093',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '71b39323-8c41-c47a-f49c-6346839247f1',
    full_name: 'Сақып Дариға Ыбрайқызы',
    telegram_id: '1080206696',
    unique: '71b39323-8c41-c47a-f49c-6346839247f1_62fbc064-9da5-3aed-1500-6360ebcafaeb',
    contract_id: '62fbc064-9da5-3aed-1500-6360ebcafaeb',
    contract_name: 'PF20220024',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'e56e198f-56a8-7e49-0a3b-654484d48b34',
    full_name: 'Руденко Виктор',
    telegram_id: '259941197',
    unique: 'e56e198f-56a8-7e49-0a3b-654484d48b34_6562feb4-c303-6bac-b3e5-657057043645',
    contract_id: '6562feb4-c303-6bac-b3e5-657057043645',
    contract_name: 'PF20230387',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'bbaa0929-be5f-17ad-809c-606d5f736cdb',
    full_name: 'Темиржанов Ильдар Рашидович',
    telegram_id: '729332128',
    unique: 'bbaa0929-be5f-17ad-809c-606d5f736cdb_65cf5031-adb5-6663-c690-607d8a3165b6',
    contract_id: '65cf5031-adb5-6663-c690-607d8a3165b6',
    contract_name: 'U5969032',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: null,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '27e6e8e3-4c29-7411-136a-651c1f73464b',
    full_name: 'Сулименко Александр Сергеевич',
    telegram_id: null,
    unique: '27e6e8e3-4c29-7411-136a-651c1f73464b_66ef0873-63c5-1f3d-be55-64a7e1dea604',
    contract_id: '66ef0873-63c5-1f3d-be55-64a7e1dea604',
    contract_name: 'PA3H713MGP1I',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'a5c6d7ad-7c05-9d9e-69ce-6013ce06c096',
    full_name: 'Тукулов Бахыт Азатканович',
    telegram_id: '6624118539',
    unique: 'a5c6d7ad-7c05-9d9e-69ce-6013ce06c096_aa6c1e57-5194-d629-88b4-64f5dfa34a95',
    contract_id: 'aa6c1e57-5194-d629-88b4-64f5dfa34a95',
    contract_name: 'PF20230111',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'e7cd6723-4f51-0057-fe69-5eb91a9f3ba7',
    full_name: 'Матвеева Наталья Анатольевна',
    telegram_id: '514809818',
    unique: 'e7cd6723-4f51-0057-fe69-5eb91a9f3ba7_693b68b3-db12-ae1d-f306-65045d3a6f4c',
    contract_id: '693b68b3-db12-ae1d-f306-65045d3a6f4c',
    contract_name: 'PF20230348',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'deb74232-cae1-9821-f630-63be9ec521f0',
    full_name: 'Куменов Алмаз',
    telegram_id: '57224180',
    unique: 'deb74232-cae1-9821-f630-63be9ec521f0_6d65035a-7713-ae6d-1c29-645b82fa84ad',
    contract_id: '6d65035a-7713-ae6d-1c29-645b82fa84ad',
    contract_name: 'PF20230084',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'c899a0e3-99d9-4c82-9e7e-5de648945e9a',
    full_name: 'Ишмухамбетов Мухтар Рыспаевич',
    telegram_id: null,
    unique: 'c899a0e3-99d9-4c82-9e7e-5de648945e9a_6da7430a-1ef8-0f33-c1d9-64df6fc0e7ae',
    contract_id: '6da7430a-1ef8-0f33-c1d9-64df6fc0e7ae',
    contract_name: 'PF20230099',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'dc03c2a1-5823-e019-8ac6-6242ac4ab4b9',
    full_name: 'Каракулов Болатбек, Коспанович',
    telegram_id: '1267852259',
    unique: 'dc03c2a1-5823-e019-8ac6-6242ac4ab4b9_6de725c8-3c8a-fd65-69b3-62555ae80364',
    contract_id: '6de725c8-3c8a-fd65-69b3-62555ae80364',
    contract_name: 'U8242663',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: null,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'c5f3e729-2c9b-a034-7ebb-5de648658306',
    full_name: 'Владимиров Владимир Викторович',
    telegram_id: null,
    unique: 'c5f3e729-2c9b-a034-7ebb-5de648658306_6e6f7b79-0fa6-ff5f-a955-655dcedf58de',
    contract_id: '6e6f7b79-0fa6-ff5f-a955-655dcedf58de',
    contract_name: 'PF20230207',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'd18bbb83-eaa5-b149-c0c2-640580635e72',
    full_name: 'Хусеинова Гюльчохра Сурхаевна',
    telegram_id: '1345810196',
    unique: 'd18bbb83-eaa5-b149-c0c2-640580635e72_721cbb58-0399-3b4b-6542-645ccbd2b9e1',
    contract_id: '721cbb58-0399-3b4b-6542-645ccbd2b9e1',
    contract_name: 'PF20230075',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '8a79beea-d520-fcf1-6724-63cf9ba60e87',
    full_name: 'Мукатов Самет Ергалиевич',
    telegram_id: '6449707291',
    unique: '8a79beea-d520-fcf1-6724-63cf9ba60e87_8b3cc0c0-71b0-baec-24eb-65dc37a1b581',
    contract_id: '8b3cc0c0-71b0-baec-24eb-65dc37a1b581',
    contract_name: '322914536',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'ba4d237c-fce7-d63d-3f6e-5f698ad32472',
    full_name: 'Ильяева Яна Анатольевна',
    telegram_id: null,
    unique: 'ba4d237c-fce7-d63d-3f6e-5f698ad32472_79a4509a-fccb-a566-1927-6532446fa70a',
    contract_id: '79a4509a-fccb-a566-1927-6532446fa70a',
    contract_name: '286125723',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'cd8004fd-2624-43c0-a242-63f5f9143bca',
    full_name: 'Сыргабаев Азамат Маратович',
    telegram_id: '394641223',
    unique: 'cd8004fd-2624-43c0-a242-63f5f9143bca_82837161-a3e0-c5d9-dc5c-64ff0f20fd75',
    contract_id: '82837161-a3e0-c5d9-dc5c-64ff0f20fd75',
    contract_name: 'PF20230363',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '7ee635ba-e642-7cb0-6c19-6075554f9ff9',
    full_name: 'Жумагулов Ерлан',
    telegram_id: null,
    unique: '7ee635ba-e642-7cb0-6c19-6075554f9ff9_a3a910e9-0954-6b7f-0b2d-65704313417d',
    contract_id: 'a3a910e9-0954-6b7f-0b2d-65704313417d',
    contract_name: 'PF20230225',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '228900fc-d35b-247a-f1b0-62d642beedbe',
    full_name: 'ЫБРАЙ АЛМАТ БЕЙБІТҰЛЫ',
    telegram_id: '266667910',
    unique: '228900fc-d35b-247a-f1b0-62d642beedbe_868cd8c6-606f-c966-0aba-6380c6cd1348',
    contract_id: '868cd8c6-606f-c966-0aba-6380c6cd1348',
    contract_name: 'PF20220045',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '33b84acc-a640-8de8-6dc1-5e06184b1fbb',
    full_name: 'Клюев Станислав Анатольевич',
    telegram_id: null,
    unique: '33b84acc-a640-8de8-6dc1-5e06184b1fbb_dae8013f-1321-7e2e-2c5f-6551fa605147',
    contract_id: 'dae8013f-1321-7e2e-2c5f-6551fa605147',
    contract_name: 'PF20230339',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '427300d6-205e-cdea-9e2e-5ef07ecf2c4d',
    full_name: 'Андагулова Зарина',
    telegram_id: null,
    unique: '427300d6-205e-cdea-9e2e-5ef07ecf2c4d_897ebd68-c6f3-9d06-abc1-655c91640557',
    contract_id: '897ebd68-c6f3-9d06-abc1-655c91640557',
    contract_name: 'PF20230288',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '8c08c0b2-9021-5b56-9741-636395f770a1',
    full_name: 'Едилханов Акылбай Меделович',
    telegram_id: '993549817',
    unique: '8c08c0b2-9021-5b56-9741-636395f770a1_8f0432e6-9fd3-f27b-3870-659d39465182',
    contract_id: '8f0432e6-9fd3-f27b-3870-659d39465182',
    contract_name: 'PF20230390',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '41dd0328-1755-cef1-6286-61b760f0154c',
    full_name: 'Арысов Кыдырбек Айнабекович',
    telegram_id: '5141470610',
    unique: '41dd0328-1755-cef1-6286-61b760f0154c_8ff4125f-644d-7c2b-10be-61f126cfa9a5',
    contract_id: '8ff4125f-644d-7c2b-10be-61f126cfa9a5',
    contract_name: 'U9005164',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: null,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '10155903-0ab5-10f1-c6bf-5f687deb3263',
    full_name: 'Кузьмин Алексей Николаевич',
    telegram_id: null,
    unique: '10155903-0ab5-10f1-c6bf-5f687deb3263_94fb3167-d119-a73d-1bda-65cb6cb2d99f',
    contract_id: '94fb3167-d119-a73d-1bda-65cb6cb2d99f',
    contract_name: 'PF20230234',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '4dc56511-fe82-33a7-3bed-623072adff90',
    full_name: 'Ким Алексей Сергеевич',
    telegram_id: '1956496722',
    unique: '4dc56511-fe82-33a7-3bed-623072adff90_99a819c9-763c-0fdc-a6bd-64f6f12c2563',
    contract_id: '99a819c9-763c-0fdc-a6bd-64f6f12c2563',
    contract_name: 'PF20230147',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'eccdcb1d-b998-9682-95dc-6321c92095af',
    full_name: 'Төлеу Балтабай Жалғасұлы',
    telegram_id: '5279026772',
    unique: 'eccdcb1d-b998-9682-95dc-6321c92095af_9d1d0140-367c-9f63-36fa-635bce26907b',
    contract_id: '9d1d0140-367c-9f63-36fa-635bce26907b',
    contract_name: 'PF20220018',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '73cf9489-a975-9f26-3295-65ae2f01a62d',
    full_name: 'Воронцова Елена Евгеньевна',
    telegram_id: null,
    unique: '73cf9489-a975-9f26-3295-65ae2f01a62d_9dc22e1d-bd9d-4054-1179-65b1211de49f',
    contract_id: '9dc22e1d-bd9d-4054-1179-65b1211de49f',
    contract_name: 'PF20240393',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '4830718f-656e-ba8e-4321-5f17d98862db',
    full_name: 'Есенова Гульчехра Зайнидиновна',
    telegram_id: null,
    unique: '4830718f-656e-ba8e-4321-5f17d98862db_ca3ee427-3652-2bcb-c431-64faf87f33e0',
    contract_id: 'ca3ee427-3652-2bcb-c431-64faf87f33e0',
    contract_name: '275798873',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '86ffa793-5c43-3552-8ccd-5f9a7ab2d7b7',
    full_name: 'Матвеев Евгений Викторович',
    telegram_id: '429689165',
    unique: '86ffa793-5c43-3552-8ccd-5f9a7ab2d7b7_afa358dc-f9f4-5723-cf37-5fc4a7e7a579',
    contract_id: 'afa358dc-f9f4-5723-cf37-5fc4a7e7a579',
    contract_name: 'U3612284',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: null,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'a96d0441-11e0-7e06-c7f6-6454e85149b6',
    full_name: 'ЫБРАЙ КАМИЛА БЕЙБІТҚЫЗЫ',
    telegram_id: '501903857',
    unique: 'a96d0441-11e0-7e06-c7f6-6454e85149b6_afbee890-1354-2cc5-6fa3-645b681e7695',
    contract_id: 'afbee890-1354-2cc5-6fa3-645b681e7695',
    contract_name: 'PF20230081',
    contract_status: 'Signed',
    contract_type: 'auto',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
  {
    contact_id: '7dc53a61-f3cc-1111-00ea-5e0606169f65',
    full_name: 'Кулагина Галина Ивановна',
    telegram_id: '451426719',
    unique: '7dc53a61-f3cc-1111-00ea-5e0606169f65_b6d93929-14f0-450e-1985-5e0606b7b4d3',
    contract_id: 'b6d93929-14f0-450e-1985-5e0606b7b4d3',
    contract_name: 'U2934737',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: null,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'ae570800-8bbf-ed7d-3f91-606583cecf44',
    full_name: 'Пархоменко Зинаида',
    telegram_id: null,
    unique: 'ae570800-8bbf-ed7d-3f91-606583cecf44_e890ae44-1be1-3abe-8858-61fa480a0296',
    contract_id: 'e890ae44-1be1-3abe-8858-61fa480a0296',
    contract_name: 'U8740647',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: null,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'a95e9b19-7cda-09d5-633c-5e0638d1bb5d',
    full_name: 'Нем Людмила Анатольевна',
    telegram_id: '757187286',
    unique: 'a95e9b19-7cda-09d5-633c-5e0638d1bb5d_d2e56545-68c8-7184-da43-5e06388fb4e2',
    contract_id: 'd2e56545-68c8-7184-da43-5e06388fb4e2',
    contract_name: 'U3366760',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: null,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'c4196b4c-99cd-465c-ab22-5e2fecb346a8',
    full_name: 'Турлыков Сергей Николаевич',
    telegram_id: '1797371616',
    unique: 'c4196b4c-99cd-465c-ab22-5e2fecb346a8_d91c65c2-edbb-68ca-b63f-5e2fef8c1398',
    contract_id: 'd91c65c2-edbb-68ca-b63f-5e2fef8c1398',
    contract_name: 'U3366933',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: null,
    quantity: null,
    sum: null,
  },
  {
    contact_id: 'b63d06d2-0dd5-2b9f-c13e-65f2c37dd14f',
    full_name: 'Крылов Юрий',
    telegram_id: '873720241',
    unique: 'b63d06d2-0dd5-2b9f-c13e-65f2c37dd14f_f1656964-8cba-3ca0-8145-65f2c7b0db73',
    contract_id: 'f1656964-8cba-3ca0-8145-65f2c7b0db73',
    contract_name: '1315206',
    contract_status: 'Signed',
    contract_type: 'telegram',
    contract_value: 0,
    quantity: null,
    sum: null,
  },
];
