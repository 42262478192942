<template>
  <c-modal
    ref="modalSendOrder"
    :visible="visible"
    confirmation
    title="Подтверждение заявки"
    @close="close"
    @confirm="confirm"
    @refuse="refuse"
  >
    <div class="info">
      Количество бумаг, выставленных в приказе, превышает количество бумаг в вашем портфеле, это приведёт к открытию позиции SHORT.
    </div>
  </c-modal>
</template>
<script>
import CModal from '@/components/UI/ComponentModal.vue';

export default {
  name: 'confirmation-short',
  emits: ['close', 'refuse', 'confirm'],
  components: {
    CModal,
  },
  data() {
    return {};
  },
  computed: {},
  props: {
    order: {
      type: Object,
    },
    visible: {
      type: Boolean,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    refuse() {
      this.$emit('refuse');
    },
    confirm() {
      this.$emit('confirm');
    },
  },
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
.info {
  max-width: 400px;
  margin: 0 auto 160px;
  text-align: center;
}
</style>
