<template>
  <div class="change-password">
    <router-link :to="{ name: 'password' }" />
    <div class="icon"><img :src="icons.shield" alt="" /></div>
    Смена пароля
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { icons } from '@/assets/svg-img';

export default {
  name: 'CabinetComponent',
  components: {},
  data: function () {
    return { icons };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({}),
  },
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
.change-password {
  position: relative;
  display: flex;

  .icon {
    width: 15px;
    height: 15px;
    margin-right: 5px;

    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: cover;
    }
  }

  a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
