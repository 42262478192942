<template>
  <div>
    <c-title title="Watch list">
      <template #instrument>
        <div class="add-watch" @click.stop="addInstrument"><img :src="icons.addWatch" alt="" /></div>
      </template>
    </c-title>
    <c-table :columnDefs="columnDefs" :rows="localWatchList" @delete="deleteInstrumentWL" @select="selectRow"></c-table>
    <c-modal ref="modalProfile" :visible="modals.addInstrument.visible" title="Добавить инструмент" @close="closeModal">
      <search-list />
    </c-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { icons } from '@/assets/svg-img';
import CTable from '@/components/UI/ComponentTable';
import CTitle from '@/components/UI/ComponentBlockTitle.vue';
import CModal from '@/components/UI/ComponentModal.vue';
import SearchList from '@/components/SearchList';

export default {
  name: 'watchlist-component',
  components: { CTable, CModal, SearchList, CTitle },
  data() {
    return {
      icons,
      localWatchList: [],
      columnDefs: [
        { name: 'Тикер', field: 'symbol', type: 'string', sort: true, search: 'text', flex: 1 },
        { name: 'Посл.цен', field: 'price', type: 'currency', sort: true, flex: 1 },
        { name: 'Изм.', field: 'change', type: 'currency', sort: true, flex: 1 },
        { name: 'Изм.в %', field: 'changeP', type: 'percent', sort: true, flex: 1 },
        { name: '', field: 'delete', hidden: true },
      ],
      modals: {
        addInstrument: { visible: false },
      },
    };
  },
  computed: {
    ...mapGetters({
      symbols: 'getSymbols',
      watchList: 'getWatchList',
      isMobile: 'isMobile',
    }),
  },
  methods: {
    ...mapMutations({
      setDefaultOrder: 'setDefaultOrder',
    }),
    ...mapActions({
      deleteInstrumentWL: 'deleteInstrumentWL',
      updateSelectedSymbol: 'updateSelectedSymbol',
    }),
    openSearchList() {
      this.modals.addInstrument.visible = true;
    },
    closeModal() {
      this.modals.addInstrument.visible = false;
    },
    addInstrument() {
      this.isMobile ? this.$router.push({ name: 'search.list' }) : this.openSearchList();
    },
    selectRow(data) {
      this.updateSelectedSymbol(data.symbol);
      this.setDefaultOrder();
    },
  },
  watch: {
    watchList: {
      handler(newState) {
        this.localWatchList = [];
        for (const each of newState) {
          this.localWatchList.push({
            symbol: each.symbol,
            price: each.price,
            change: each.change,
            changeP: each.changeP,
            data: each,
            deletedRow: true,
          });
        }
      },
      deep: true,
    },
  },
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.add-watch {
  width: 14px;
  height: 14px;
  margin-left: 6px;
  cursor: pointer;

  img {
    width: 100%;
  }

  @include breakpoint-down(md) {
    width: 18px;
    margin-left: 12px;
  }
}
</style>
