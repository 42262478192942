<template>
  <div class="main-page">
    <div class="chart widget-bg">
      <div class="selected">{{ selectedSymbol }}</div>
      <chart-component />
    </div>
    <div class="widget-bg">
      <order-form-component />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import ChartComponent from '@/components/ChartComponent';
import OrderFormComponent from '@/components/OrderFormComponent';

export default {
  name: 'MobileOrder',
  components: {
    ChartComponent,
    OrderFormComponent,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      selectedSymbol: 'getSelectedSymbol',
    }),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({}),
  },
  watch: {},
  created() {
    if (!this.selectedSymbol) this.$router.push({ name: 'wl' });
  },
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';
.main-page {
  margin: 12px;
  .chart {
    height: 33vh;
    margin-bottom: 2px;
  }
}
</style>
