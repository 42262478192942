<template>
  <div v-for="each of columns" :key="each.field">
    <div v-if="addFilter(each)" class="seaech-wrap">
      <input v-model="filters[each.field].value[0]" placeholder="Поиск..." type="text" />
    </div>
  </div>

  <div class="wrap" @click="onClick">
    <div
      v-for="(item, index) in filteredTableData"
      :key="index"
      :class="['item', { active: index === activeIndex, error: item.status === 'error' }]"
      :data-index="index"
      :data-key="item.key"
      :data-symbol="item.symbol"
    >
      <div class="head">
        <div
          v-for="(column, name) in columns"
          :key="name"
          :class="['head-item', column.class.map((e) => e), column.colored ? utils.getColorClass(utils.getValue(item, column.field)) : '']"
        >
          <div v-if="column.field === 'cancel'">
            <div @click.stop="cancelationOrder(item.data)">
              <div :style="{ opacity: item.deletedRow ? '1' : '0.2' }" class="actionIcon" @click="animation">
                <img :src="icons.cross" alt="" />
              </div>
            </div>
          </div>

          <div v-if="column.field === 'delete'">
            <div @click.stop="deleteInstrument(item.data)">
              <div class="actionIcon" @click="animation">
                <img :src="icons.trash" alt="" />
              </div>
            </div>
          </div>

          <div v-if="column.field !== 'cancel' && column.field !== 'delete'">
            <div class="hint">{{ column.title }}</div>
            <div class="head-value">{{ utils.formatValue(utils.getValue(item, column.field), column.type) }}</div>
          </div>
        </div>
        <div v-if="detailRows.length > 0" class="head-item img">
          <div class="icon">
            <img :src="icons.up" alt="" />
          </div>
        </div>
      </div>
      <div v-if="detailRows.length > 0" v-show="index === activeIndex" class="detail">
        <div v-for="(row, index) in detailRows" :key="index" class="detail-item-wrap">
          <div
            v-if="row.type !== 'button'"
            :class="['detail-item', row.colored ? utils.getColorClass(utils.getValue(item, row.field)) : '']"
          >
            <div>{{ row.title }}</div>
            <div class="detail-value">{{ utils.formatValue(utils.getValue(item, row.field), row.type) }}</div>
          </div>
          <div v-else class="detail-item last">
            <button class="primary">{{ item.data.status === 'approval' ? 'подтвердить' : row.title }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { icons } from '@/assets/svg-img';
import utils from '@/utils';
import animation from '@/utils/animation';

export default {
  name: 'ComponentPosition',
  emits: ['action', 'cancel', 'delete'],
  components: {},
  data() {
    return {
      filters: {},
      icons,
      activeIndex: null,
      canceledStatus: false,
      utils,
    };
  },
  computed: {
    ...mapGetters({}),
    filteredTableData() {
      return this.positions.filter((item) => {
        // Фильтруем данные по полю symbol
        // return item.symbol.toLowerCase().includes(this.searchQuery.toLowerCase());
        return Object.entries(this.filters).every(([key, filter]) => {
          return item[key].toLowerCase().includes(filter.value[0].toLowerCase());
        });
      });
    },
  },
  props: {
    positions: {
      type: Array,
      requred: true,
    },
    columns: {
      type: Array,
      requred: true,
    },
    detailRows: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      cancelOrder: 'cancelOrder',
    }),
    animation(e) {
      animation.compression(e);
    },
    addFilter(column) {
      if (column.search === undefined) return false;
      if (!(column.field in this.filters))
        this.filters[column.field] = {
          field: column.field,
          type: column.search,
          value: [''],
        };
      return true;
    },
    cancelationOrder(item) {
      if (item.canceled) this.cancelOrder(item);
    },
    deleteInstrument(data) {
      return void this.$emit('delete', data);
    },
    onClick(e) {
      const item = e.target.closest('.item');
      if (item) {
        if (e.target.tagName === 'BUTTON') return void this.$emit('action', item.dataset.key);
        else if (item.dataset.symbol !== 'USD') {
          if (this.detailRows.length === 0) return void this.$emit('action', item.dataset.key);
          const index = parseInt(item.dataset.index);
          this.activeIndex === index ? (this.activeIndex = null) : (this.activeIndex = index);
        }
      }
    },
  },
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.wrap {
  padding: 10px 15px 20px;
}

.item {
  padding: 8px 12px;
  border-radius: var(--main-border-radius);
  border: 1px solid var(--input-alt-default-border-outside);
  margin-bottom: 10px;
  box-shadow: 0 3px 6px var(--dropdown-alt-border-outside);
  background: rgba(24, 25, 27, 0.4);

  &.active {
    .icon {
      transform: translate(0, -50%) rotateX(0);
    }
  }

  .head {
    display: flex;
    position: relative;
    align-items: center;
    font-weight: 500;

    &-value {
      height: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-item {
      width: 60px;
      margin-left: auto;

      &:first-child {
        margin-left: 0;
      }

      &.img {
        width: 20px;
        position: relative;
      }
    }
  }

  .cancel {
    transform: translate(-3px, 0px);
  }

  .delete {
    transform: translate(3px, 0px);
  }

  .cancel,
  .delete {
    width: 20px;
    height: 20px;
    position: relative;
    display: flex;
    align-items: center;

    .actionIcon {
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .hint {
    color: var(--hint-color);
    font-size: 8px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 3px;
  }

  .icon {
    width: 15px;
    height: 2px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%) rotateX(180deg);
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;

    img {
      width: 100%;
    }
  }

  &.error {
    border-color: var(--error) !important;
  }

  .symbol {
    font-weight: bold;
    color: var(--white);
    width: 80px;
    margin-right: 8px;
    margin-left: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .price {
    color: var(--white);
    width: 100px;
    margin-right: 8px;
    margin-left: 0;
  }

  .quantity {
    width: 20%;
    margin-right: 8px;
  }

  .detail {
    border-top: 1px solid var(--input-alt-default-border-outside);
    margin-top: 10px;
    padding-top: 15px;
    color: var(--white);

    &-item-wrap {
      padding: 4px;
      border-bottom: 1px solid var(--input-alt-default-border-outside);
      box-shadow: 0 1px 1px var(--dropdown-alt-border-outside);
      margin-bottom: 6px;

      &:last-child {
        border: none;
        box-shadow: 0 0 0 var(--dropdown-alt-border-outside);
      }
    }

    &-item {
      display: flex;
      justify-content: space-between;

      button {
        margin: 0 auto;
        text-transform: uppercase;
        font-weight: 500;
      }
    }

    &-value {
      text-transform: uppercase;
      text-align: right;
    }
  }
}

.seaech-wrap {
  height: 40px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding: 0 15px;

  .icon-search {
    margin-left: 20px;
    width: 20px;
    height: 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  input {
    //width: calc(100% - 45px);
    width: 100%;
    height: 100%;
    padding: 0 10px;
  }
}
</style>
