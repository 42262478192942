<template>
  <div class="nav-main">
    <div :class="[$route.name === 'portfolio' ? 'active' : '', 'item']">
      <div class="img"><img :src="icons.portfolio" alt="" /></div>
      <router-link :to="{ name: 'portfolio' }"></router-link>
      Portfolio
    </div>
    <div :class="[$route.name === 'wl' ? 'active' : '', 'item']">
      <div class="img"><img :src="icons.watchList" alt="" /></div>
      <router-link :to="{ name: 'wl' }"></router-link>
      Watch list
    </div>
    <div :class="[$route.name === 'orders' ? 'active' : '', 'item']">
      <div class="img"><img :src="icons.paperclip" alt="" /></div>
      <router-link :to="{ name: 'orders' }"></router-link>
      Order
    </div>
  </div>
</template>

<script>
import { icons } from '@/assets/svg-img';

export default {
  name: 'AppBar',
  components: {},
  data() {
    return { icons };
  },
  computed: {},
  methods: {},
  watch: {},
  created() {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.nav-main {
  background-image: linear-gradient(to right, var(--widget-header-grad-color-1), var(--widget-header-grad-color-2));
  display: flex;
  justify-content: space-between;
  height: var(--nav-main-height);
  position: fixed;
  left: 0;
  right: 0;
  bottom: env(safe-area-inset-bottom);

  &::after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    bottom: -100%;
    top: 0;
    background-image: linear-gradient(to right, var(--widget-header-grad-color-1), var(--widget-header-grad-color-2));
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.63);
    z-index: -1;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    flex: 1;
    border-right: solid 1px rgba(0, 0, 0, 0.3);
    position: relative;
    flex-direction: column;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      right: 50%;
      height: 2px;
      background: transparent;
      transition: all 0.2s ease-in-out;
    }

    &:last-child {
      border-right: none;
    }

    &.active {
      &::before {
        left: 0;
        right: 0;
        transition: all 0.2s ease-in-out;
        background: rgba(86, 100, 182, 0.74);
      }
    }

    .img {
      width: 25px;
      height: 25px;

      img {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    a {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
</style>
