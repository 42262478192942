<template>
  <div class="custom-radio">
    <label>
      <div>{{ title }}</div>
      <input
        :name="name"
        :type="type"
        :checked="modelValue"
        :value="modelValue"
        @change="$emit('update:modelValue', $event.target.checked)"
      />
      <div class="indicator"><img :src="icons.check" alt="" /></div>
    </label>
  </div>
</template>

<script>
import { icons } from '@/assets/svg-img';

export default {
  name: 'CustomCheck',
  emits: ['update:modelValue'],
  components: {},
  props: {
    title: { type: String, default: () => '' },
    // value: [String, Number, Boolean],
    // checked: { type: Boolean, default: () => false },
    name: { type: String, default: () => '' },
    type: {
      type: String,
      default: 'checkbox',
      validator: (type) => ['checkbox', 'radio'].includes(type),
    },
    modelValue: {
      type: Boolean,
      default: () => false,
      // type: [String, Number, Boolean, Array, Object],
    },
  },
  data() {
    return { icons };
  },
  computed: {
    // isChecked() {
    //   if (this.type === 'checkbox') {
    //     if (this.checked) return true;
    //     if (typeof this.modelValue === Boolean) return true;
    //     if (Array.isArray(this.modelValue)) {
    //       return this.modelValue.includes(this.value);
    //     } else {
    //       return false;
    //     }
    //   } else if (this.type === 'radio') {
    //     return this.modelValue === this.value;
    //   }
    //   return false;
    // },
  },
  methods: {
    // handleChange(event) {
    // const { checked, value } = event.target;
    // if (this.type === 'checkbox') {
    //   if (Array.isArray(this.modelValue)) {
    //     const index = this.modelValue.indexOf(value);
    //     if (checked && index === -1) {
    //       this.$emit('update:modelValue', [...this.modelValue, value]);
    //     } else if (!checked && index !== -1) {
    //       this.$emit('update:modelValue', [...this.modelValue.slice(0, index), ...this.modelValue.slice(index + 1)]);
    //     }
    //   } else {
    // this.$emit('update:modelValue', event.target.checked);
    //   }
    // } else {
    //   if (checked) {
    // this.$emit('update:modelValue', event.target.value);
    //   }
    // }
    // },
  },
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.custom-radio {
  label {
    display: flex !important;
    align-items: center;
    cursor: pointer;
    position: relative;
    color: var(--pale-violet);

    input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0;

      &:checked + .indicator {
        img {
          opacity: 1;
        }
      }
    }

    &:hover {
      .indicator {
        border: 1px solid var(--input-hovered-border-outside-color);
      }
    }
  }

  .indicator {
    width: 16px;
    height: 16px;
    background-color: rgb(130 136 201 / 37%);
    border: solid 1px var(--table-header-shadow-color);
    margin-left: 4px;
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border 0.2s ease-in-out;
    flex-shrink: 0;
    @include breakpoint-down(md) {
      width: 22px;
      height: 22px;
    }
  }

  img {
    opacity: 0;
    transition: all 0.2s ease;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
