<template>
  <div class="questionnaire">
    <c-modal
      ref="modalInfo"
      :visible="modals.info.visible"
      size="medium"
      title="Agreement of adherence / Соглашение о присоединении"
      @close="closeModal"
    >
      <agreement />
    </c-modal>

    <!-- <div class="item-block"> -->
    <div class="wrap widget-bg">
      <form @submit.prevent="processingForm">
        <section>
          <!-- Section 1. GENERAL CLIENT INFORMATION -->
          <div class="section-tile">Раздел 1. ОБЩИЕ ДАННЫЕ КЛИЕНТА</div>

          <div class="content">
            <div class="item-block">
              <div class="col">
                <!-- Personal information -->
                <h4>Персональная информация</h4>
                <!-- First Name -->
                <div class="form-info-wrap">
                  <div class="input-wrap">
                    <label> Имя </label>
                    <input v-model="document.firstName" type="text" />
                  </div>
                </div>
                <!-- Last Name -->
                <div class="form-info-wrap">
                  <div class="input-wrap">
                    <label> Фамилия </label>
                    <input v-model="document.lastName" type="text" />
                  </div>
                </div>
                <!-- Middle Name (if applicable) -->
                <div class="form-info-wrap">
                  <div class="input-wrap">
                    <label> Отчество </label>
                    <input v-model="document.middleName" type="text" />
                  </div>
                </div>
                <!-- Date & Place of birth -->
                <div class="form-info-wrap">
                  <div class="input-wrap">
                    <label> Дата и Место рождения </label>
                    <input v-model="document.birthdate" type="text" />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-info-wrap">
                  <!-- Gender -->
                  <h4>Пол</h4>
                  <div class="flex">
                    <!-- Male -->
                    <c-check v-model="document.gender" name="gender" title="Мужской" type="radio" value="male" />
                    <!-- Female -->
                    <c-check v-model="document.gender" name="gender" title="Женский" type="radio" value="female" />
                  </div>
                </div>

                <div class="form-info-wrap">
                  <!-- Marital status -->
                  <h4>Семейное положение</h4>
                  <div class="flex">
                    <!-- Single -->
                    <c-check v-model="document.marriedStatus" name="marriedStatus" title="Не состоит в браке" type="radio" value="single" />
                    <!-- Married -->
                    <c-check v-model="document.marriedStatus" name="marriedStatus" title="Состоит в браке" type="radio" value="married" />
                    <!-- Divorced -->
                    <c-check v-model="document.marriedStatus" name="marriedStatus" title="В разводе" type="radio" value="divorced" />
                    <!-- Widowed -->
                    <c-check v-model="document.marriedStatus" name="marriedStatus" title="Овдовевший(-ая)" type="radio" value="widowed" />
                  </div>
                </div>

                <div class="form-info-wrap">
                  <div class="input-wrap">
                    <!-- Number of dependents -->
                    <label>Количество лиц на Вашем обеспечении (дети, родители)</label>
                    <input v-model="document.numberDependents" type="text" />
                  </div>
                </div>
              </div>
            </div>

            <div class="item-block">
              <div class="col">
                <div class="form-info-wrap">
                  <!-- Documents -->
                  <h4>Документы</h4>
                  <div class="flex">
                    <!-- ID -->
                    <c-check
                      v-model="document.identityType"
                      name="identityType"
                      title="Удостоверение личности"
                      type="radio"
                      value="IDcard"
                    />
                    <!-- Passpotrt -->
                    <c-check v-model="document.identityType" name="identityType" title="Паспорт" type="radio" value="Passport" />
                    <!-- Other -->
                    <c-check v-model="document.identityType" name="identityType" title="Иное" type="radio" value="Otherr" />
                  </div>
                </div>

                <div class="form-info-wrap">
                  <div class="input-wrap">
                    <!-- Date of issue/expire -->
                    <label>Дата выдачи/окончания действия</label>
                    <input v-model="document.issuedDate" type="text" />
                  </div>
                </div>

                <div class="form-info-wrap">
                  <div class="input-wrap">
                    <!-- Document No. -->
                    <label>No документа</label>
                    <input v-model="document.identity" type="text" />
                  </div>
                </div>

                <div class="form-info-wrap">
                  <div class="input-wrap">
                    <!-- Issuing authority -->
                    <label>Орган, выдавший документ</label>
                    <input v-model="document.issuingAuthority" type="text" />
                  </div>
                </div>

                <!-- Permanent residence / registration addresses: -->
                <h4>Адрес места жительства/ регистрации:</h4>

                <div class="form-info-wrap">
                  <!-- Mark if addresses match -->
                  <c-check
                    v-model="document.addressTrue"
                    name="noTax"
                    title="Отметка, если адреса совпадают"
                    type="checkbox"
                    value="Mark if addresses match "
                  />
                </div>

                <div class="form-info-wrap">
                  <label>
                    <!-- Post code, country, region, settlement, district, building, block, apartment -->
                    Индекс, страна, регион, населенный пункт, район, No здания, No блока, квартира
                  </label>
                  <input v-model="document.postCodeCountryRegion" type="text" />
                </div>
              </div>
              <div class="col">
                <div class="form-info-wrap">
                  <!-- Citizenship -->
                  <h4>Гражданство</h4>
                  <div class="flex">
                    <!-- Republic of Kazakhstan -->
                    <c-check
                      v-model="document.citizenship"
                      name="citizenship"
                      title="Республика Казахстан"
                      type="radio"
                      value="Republic of Kazakhstan"
                    />
                    <!-- Without citizenship -->
                    <c-check
                      v-model="document.citizenship"
                      name="citizenship"
                      title="Без гражанства"
                      type="radio"
                      value="Without citizenship"
                    />
                    <!-- Other State -->
                    <c-check v-model="document.citizenship" name="citizenship" title="Иное государство" type="radio" value="Other State" />
                  </div>
                </div>

                <div v-show="document.citizenship === 'Other State'">
                  <div class="form-info-wrap">
                    <!-- Additional document for foreign citizens: -->
                    <h4>Дополнительный документ для иностранных граждан:</h4>
                    <div class="flex">
                      <!-- Migration card -->
                      <c-check
                        v-model="document.additionalDocument"
                        name="additionalDocument"
                        title="Миграционная карточка"
                        type="radio"
                        value="Migration card"
                      />
                      <!-- Visa -->
                      <c-check v-model="document.additionalDocument" name="additionalDocument" title="Виза" type="radio" value="Visa" />

                      <!-- Other -->
                      <c-check v-model="document.additionalDocument" name="additionalDocument" title="Иное" type="radio" value="Other" />
                    </div>
                  </div>

                  <div class="form-info-wrap">
                    <div class="input-wrap">
                      <!-- Date of issue/expire -->
                      <label>Дата выдачи/окончания действия</label>
                      <input v-model="document.otherDateDocumentIssueExpire" type="text" />
                    </div>
                  </div>

                  <div class="form-info-wrap">
                    <div class="input-wrap">
                      <!-- Document No. -->
                      <label>No документа</label>
                      <input v-model="document.otherDocumentNumber" type="text" />
                    </div>
                  </div>

                  <div class="form-info-wrap">
                    <div class="input-wrap">
                      <!-- Issuing authority -->
                      <label>Орган, выдавший документ</label>
                      <input v-model="document.otherIssuingAuthority" type="text" />
                    </div>
                  </div>
                </div>

                <div class="form-info-wrap">
                  <!-- Tax residence -->
                  <h4>Налоговое резидентство</h4>
                  <div class="flex">
                    <!-- Republic of Kazakhstan -->
                    <c-check
                      v-model="document.taxResidence"
                      name="taxResidence"
                      title="Республика Казахстан"
                      type="radio"
                      value="Republic of Kazakhstan"
                    />
                    <!-- Other state -->
                    <c-check
                      v-model="document.taxResidence"
                      name="taxResidence"
                      title="Иное государство"
                      type="radio"
                      value="Other state"
                    />
                  </div>
                </div>

                <div class="form-info-wrap">
                  <!-- Are you a U.S. tax resident? -->
                  <h4>Являетесь ли вы налоговым резидентом США?</h4>
                  <div class="flex">
                    <c-check v-model="document.usTaxResident" name="taxUSA" title=" да" type="radio" value="yes" />
                    <c-check v-model="document.usTaxResident" name="taxUSA" title=" нет" type="radio" value="no" />
                  </div>
                </div>

                <div class="form-info-wrap">
                  <div class="input-wrap">
                    <!-- Individual Identification Number or Tax Identification Number -->
                    <label>Индивидуальный идентификационный номер или налоговой идентификационный номер</label>
                    <input v-model="document.taxNumber" type="text" />
                  </div>
                </div>

                <div class="form-info-wrap">
                  <!-- If Not, specify the reason for absence of number -->
                  <c-check
                    v-model="document.usTaxResident"
                    name="noTax"
                    title="Если Нет, укажите причину отсутствия номера"
                    type="checkbox"
                    value="Not specify the reason for absence of number"
                  />
                  <input type="text" />
                </div>
              </div>
            </div>

            <div class="item-block" style="display: none">
              <div class="col">
                <!-- Do you have any previous experience (at least for two year) in one of the following professional capacities? -->
                <h4>Есть ли у Вас предыдущий опыт (минимум в течении двух лет) в одном из следующих профессиональных качеств?</h4>

                <div class="flex">
                  <!-- Financial markets analyst -->
                  <c-check
                    v-model="document.professionalCapacities"
                    name="employmentType"
                    title="Аналитик финансовых рынков"
                    type="radio"
                    value="Financial markets analyst"
                  />

                  <!-- Financial instruments broker/dealer -->
                  <c-check
                    v-model="document.professionalCapacities"
                    name="employmentType"
                    title="Брокер/Дилер финансовых инструментов"
                    type="radio"
                    value="Financial instruments broker/dealer"
                  />

                  <!-- Investment Portfolio Manager -->
                  <c-check
                    v-model="document.professionalCapacities"
                    name="employmentType"
                    title=" Управляющий инвестиционным портфелем"
                    type="radio"
                    value="Investment Portfolio Manager"
                  />

                  <!-- Investment/Financial Consultant -->
                  <c-check
                    v-model="document.professionalCapacities"
                    name="employmentType"
                    title="Инвестиционный/Финансовый консультант"
                    type="radio"
                    value="Investment/Financial Consultant"
                  />

                  <!-- Other, similar financial positions -->
                  <c-check
                    v-model="document.professionalCapacities"
                    name="employmentType"
                    title="Иные, похожие финансовые позиции"
                    type="radio"
                    value="Other, similar financial positions"
                  />

                  <!-- No previous financial experience -->
                  <c-check
                    v-model="document.professionalCapacities"
                    name="employmentType"
                    title="Нет профессионального финансового опыта"
                    type="radio"
                    value="No previous financial experience"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-info-wrap">
                  <!-- Employment type -->
                  <h4>Вид занятости</h4>
                  <div class="flex">
                    <!-- Employee -->
                    <c-check
                      v-model="document.employmentType"
                      name="employmentType"
                      title="Наемный работник"
                      type="radio"
                      value="Employee"
                    />
                    <!-- Student -->
                    <c-check v-model="document.employmentType" name="employmentType" title="Студент" type="radio" value="Student" />
                    <!-- Self-employed -->
                    <c-check
                      v-model="document.employmentType"
                      name="employmentType"
                      title="Самозанятый"
                      type="radio"
                      value="Self-employed"
                    />
                    <!-- Business owner -->
                    <c-check
                      v-model="document.employmentType"
                      name="employmentType"
                      title="Владелец бизнеса"
                      type="radio"
                      value="Business owner"
                    />
                    <!-- Retired -->
                    <c-check v-model="document.employmentType" name="employmentType" title="Пенсионер" type="radio" value="Retired" />

                    <!-- Other -->
                    <c-check v-model="document.employmentType" name="employmentType" title="Иное" type="radio" value="Other" />
                  </div>
                </div>

                <div class="form-info-wrap">
                  <label>
                    <!-- Activity profile (if applicable) Describe the main business - company name, position, industry, address -->
                    Профиль деятельности (если применимо) Опишите основной бизнес - название компании, должность, отрасль, адрес
                  </label>
                  <input v-model="document.companyName" type="text" />
                </div>

                <!-- Education & Economic profile -->
                <h4>Образование и Экономический профиль</h4>
                <div class="flex">
                  <!-- Higher education in economics and finance -->
                  <c-check
                    v-model="document.educationEconomicProfile"
                    name="education"
                    title="Высшее образование в области экономики и финансов"
                    type="radio"
                    value="Higher education in economics and finance"
                  />
                  <!-- Higher education not related to economics or finance -->
                  <c-check
                    v-model="document.educationEconomicProfile"
                    name="education"
                    title="Высшее образование не в области экономики и финансов"
                    type="radio"
                    value="Higher education not related to economics or finance"
                  />
                  <!-- Other education, specify -->
                  <c-check
                    v-model="document.educationEconomicProfile"
                    name="education"
                    title="Иное образование, укажите"
                    type="radio"
                    value="Other education"
                  />
                </div>

                <div v-show="document.educationEconomicProfile === 'Other education'" class="form-info-wrap">
                  <!-- Other education -->
                  <label>Иное образование</label>
                  <input v-model="document.otherEducationName" type="text" />
                </div>
              </div>
            </div>
          </div>

          <!-- PEP-->
          <div hidden>
            <div class="item-block">
              <div class="item-title">
                The following information is requested for the purpose of compliance with Anti-Money Laundering and Counter Financing
                Terrorism legislation
                <br />Следующая информация запрашивается в целях исполнения требований законодательства о противодействии легализации
                (отмыванию) доходов, полученных преступным путем, и финансированию терроризма
              </div>
            </div>

            <div class="item-block">
              <div class="content-title">Political Exposure Политические связи</div>
              <h4>
                Are you a politically exposed person (PEP), i.e. a person appointed or elected, holding any position in a legislative,
                executive, administrative or judicial body of a state, as well as any person performing any public function for a state?
                Являетесь ли Вы публичным должностным лицом (ПДЛ), т.е. лицом, назначаемым или избираемым, занимающим какую-либо должность в
                законодательном, исполнительном, административном или судебном органе государства, а также любым лицом, выполняющим
                какую-либо публичную функцию для государства?
              </h4>

              <div class="radio-checkbox-wrap">
                <label>Yes да</label>
                <input v-model="document.pep" name="pep" type="radio" value="yes" />
              </div>
              <div class="radio-checkbox-wrap">
                <label>No нет</label>
                <input v-model="document.pep" name="pep" type="radio" value="no" />
              </div>
            </div>

            <div class="form-info-wrap">
              <label>Position Должность</label>
              <input v-model="document.pepPosition" type="text" />
            </div>

            <div class="form-info-wrap">
              <label>Government organization Государственный орган</label>
              <input v-model="document.pepGovernmentOrganization" type="text" />
            </div>

            <div class="form-info-wrap">
              <label>Name of the state Название государства</label>
              <input v-model="document.pepNameState" type="text" />
            </div>

            <h4>
              Do you have any affiliation and/or involvement with family members and close relatives of a politically exposed person? Имеете
              ли Вы какую-либо принадлежность и (или) причастность к членам семьи и близким родственникам публичного должностного лица?
            </h4>

            <div class="radio-checkbox-wrap">
              <label>
                Yes, my spouse and close relatives are (were) PEP Да, мой супруг (супруга) и близкие родственники являются (являлись) ПДЛ
              </label>
              <input v-model="document.pepAffiliationFamily" name="pep" type="radio" value="yes" />
            </div>
            <div class="radio-checkbox-wrap">
              <label>
                No, neither me nor my spouse nor my close relatives are (were) PEP Нет, ни я, ни мой супруг (супруга), ни мои близкие
                родственники не являются (не являлись) ПДЛ.
              </label>
              <input v-model="document.pepAffiliationFamily" name="pep" type="radio" value="no" />
            </div>

            <div class="form-info-wrap">
              <label>Position Должность</label>
              <input v-model="document.pepAffiliationPosition" type="text" />
            </div>

            <div class="form-info-wrap">
              <label>Government organization Государственный орган</label>
              <input v-model="document.pepAffiliationGovernmentOrganization" type="text" />
            </div>

            <div class="form-info-wrap">
              <label>Name of the state Название государства</label>
              <input v-model="document.pepAffiliationNameState" type="text" />
            </div>

            <div class="form-info-wrap">
              <label>Form of affiliation and (or) involvement Форма принадлежности и (или) причастности</label>
              <input v-model="document.pepAffiliationForm" type="text" />
            </div>
          </div>
          <!-- END PEP -->

          <!-- Financial information -->
          <!-- <h4>Финансовая информация</h4> -->
          <!-- Please provide the following information (approximately annualized figures in USD, based on data for the current/previous year) -->
          <h3 hidden>
            Пожалуйста, предоставьте следующую информацию<br />
            (ориентировочные годовые показатели в долларах США, основанные на данных за текущий/прошлый год)
          </h3>
          <div class="item-block" style="display: none">
            <div class="col">
              <div class="form-info-wrap">
                <!-- Size of regular annual income -->
                <h4>Размер регулярного годового дохода</h4>

                <div class="flex">
                  <c-check
                    v-model="document.sizeAnnualIncome"
                    name="sizeAnnualIncome"
                    title="0$ - 10 000$"
                    type="radio"
                    value="0$ - 10 000$"
                  />

                  <c-check
                    v-model="document.sizeAnnualIncome"
                    name="sizeAnnualIncome"
                    title="10 000$ - 50 000$"
                    type="radio"
                    value="10 000$ - 50 000$"
                  />

                  <c-check
                    v-model="document.sizeAnnualIncome"
                    name="sizeAnnualIncome"
                    title="50 000$ - 100 000$"
                    type="radio"
                    value="50 000$ - 100 000$"
                  />

                  <c-check
                    v-model="document.sizeAnnualIncome"
                    name="sizeAnnualIncome"
                    title="100 000$ - 500 000$"
                    type="radio"
                    value="100 000$ - 500 000$"
                  />

                  <c-check
                    v-model="document.sizeAnnualIncome"
                    name="sizeAnnualIncome"
                    title="500 000$ - 1 000 000$"
                    type="radio"
                    value="500 000$ - 1 000 000$"
                  />

                  <c-check
                    v-model="document.sizeAnnualIncome"
                    name="sizeAnnualIncome"
                    title=">1 000 000$"
                    type="radio"
                    value=">1 000 000$"
                  />
                </div>
              </div>

              <div class="form-info-wrap">
                <!-- Source of regular annual income (can be more than one) -->
                <h4>Источник дохода (можно отметь несколько)</h4>
                <div class="flex">
                  <!-- Salary -->
                  <c-check
                    v-model="document.sourceAnnualIncome"
                    name="sourceAnnualIncome"
                    title="Заработная плата"
                    type="checkbox"
                    value="Salary"
                  />
                  <!-- Rental -->
                  <c-check
                    v-model="document.sourceAnnualIncome"
                    name="sourceAnnualIncome"
                    title="Доход от аренды"
                    type="checkbox"
                    value="Rental"
                  />

                  <!-- Inheritance -->
                  <c-check
                    v-model="document.sourceAnnualIncome"
                    name="sourceAnnualIncome"
                    title="Наследство"
                    type="checkbox"
                    value="Inheritance"
                  />

                  <!-- Dividends/Interests -->
                  <c-check
                    v-model="document.sourceAnnualIncome"
                    name="sourceAnnualIncome"
                    title="Дивиденды/Проценты"
                    type="checkbox"
                    value="Dividends/Interests"
                  />

                  <!-- Business profits -->
                  <c-check
                    v-model="document.sourceAnnualIncome"
                    name="sourceAnnualIncome"
                    title="Доход от бизнеса"
                    type="checkbox"
                    value="Business profits"
                  />

                  <!-- Pension -->
                  <c-check v-model="document.sourceAnnualIncome" name="sourceAnnualIncome" title="Пенсия" type="checkbox" value="Pension" />

                  <!-- Other income, explain -->
                  <c-check
                    v-model="document.sourceAnnualIncome"
                    name="sourceAnnualIncome"
                    title="Другой доход, поясните"
                    type="checkbox"
                    value="Other income"
                  />
                </div>
              </div>

              <div class="form-info-wrap">
                <label> Other income, explain Другой доход, поясните </label>
                <input v-model="document.otherSourceAnnualIncome" type="text" />
              </div>
            </div>
            <div class="col">
              <div class="form-info-wrap">
                <!-- Estimated net worth (property, securities, deposits, etc. (excluding value of the primary residence) -->
                <h4>Оценочный чистый капитал (недвижимость, ценные бумаги, депозиты и т.д. (исключая стоимость основного жилья)</h4>
                <div class="flex">
                  <c-check
                    v-model="document.estimatedWorth"
                    name="estimatedWorth"
                    title="0$ - 10 000$"
                    type="checkbox"
                    value="0$ - 10 000$"
                  />

                  <c-check
                    v-model="document.estimatedWorth"
                    name="estimatedWorth"
                    title="10 000$ - 50 000$"
                    type="checkbox"
                    value="10 000$ - 50 000$"
                  />

                  <c-check
                    v-model="document.estimatedWorth"
                    name="estimatedWorth"
                    title="50 000$ - 100 000$"
                    type="checkbox"
                    value="50 000$ - 100 000$"
                  />

                  <c-check
                    v-model="document.estimatedWorth"
                    name="estimatedWorth"
                    title="100 000$ - 500 000$"
                    type="checkbox"
                    value="100 000$ - 500 000$"
                  />

                  <c-check
                    v-model="document.estimatedWorth"
                    name="estimatedWorth"
                    title="500 000$ - 1 000 000$"
                    type="checkbox"
                    value="500 000$ - 1 000 000$"
                  />

                  <c-check
                    v-model="document.estimatedWorth"
                    name="estimatedWorth"
                    title=">1 000 000$"
                    type="checkbox"
                    value=">1 000 000$"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section hidden>
          <!-- Section 2. INVESTMENT OBJECTIVES & EXPERIENCE -->
          <div class="section-tile">Раздел 2. ИНВЕСТИЦИОННЫЕ ЦЕЛИ И ОПЫТ</div>
          <!-- Indicate and detail your experience with the following financial instruments (approximately in years): -->
          <h3>Укажите и уточните ваш опыт работы со следующими финансовыми инструментами (ориентировочно в годах):</h3>
          <div class="item-block">
            <div class="col">
              <div class="form-info-wrap">
                <!-- Money market (Deposits, bonds and other fixed income instruments) -->
                <h4>Денежный рынок (Депозиты, облигации и другие инструменты с фиксированной доходностью )"</h4>
                <div class="flex">
                  <c-check v-model="document.experience.moneyMarket" name="moneyMarket" title="1 год" type="radio" value="1 year" />
                  <c-check v-model="document.experience.moneyMarket" name="moneyMarket" title="1-3 годa" type="radio" value="1-3 years" />
                  <c-check
                    v-model="document.experience.moneyMarket"
                    name="moneyMarket"
                    title="сыше 3-х лет"
                    type="radio"
                    value="over 3 years"
                  />
                </div>
              </div>

              <div class="form-info-wrap">
                <!-- Stocks and ETFs -->
                <h4>Акци и биржевые фонды</h4>
                <div class="flex">
                  <c-check v-model="document.experience.stocksETFs" name="Stocks" title="1 год" type="radio" value="1 year" />
                  <c-check v-model="document.experience.stocksETFs" name="Stocks" title="1-3 годa" type="radio" value="2 years" />
                  <c-check v-model="document.experience.stocksETFs" name="Stocks" title="свыше 3-х лет" type="radio" value="over 3 years" />
                </div>
              </div>

              <div class="form-info-wrap">
                <!-- Futures -->
                <h4>Фьючерсы</h4>
                <div class="flex">
                  <c-check v-model="document.experience.futures" name="Futures" title="1 год" type="radio" value="1 year" />
                  <c-check v-model="document.experience.futures" name="Futures" title="1-3 года" type="radio" value="1-3 years" />
                  <c-check v-model="document.experience.futures" name="Futures" title="свыше 3-х лет" type="radio" value="over 3 years" />
                </div>
              </div>

              <div class="form-info-wrap">
                <!-- Options -->
                <h4>Опционы</h4>
                <div class="flex">
                  <c-check v-model="document.experience.options" name="options" title="1 год" type="radio" value="1 year" />
                  <c-check v-model="document.experience.options" name="options" title="1-3 года" type="radio" value="1-3 years" />
                  <c-check v-model="document.experience.options" name="options" title="свыше 3-х лет" type="radio" value="over 3 years" />
                </div>
              </div>

              <div class="form-info-wrap">
                <!-- Forex -->
                <h4>Форекс</h4>
                <div class="flex">
                  <c-check v-model="document.experience.forex" name="forex" title="1 год" type="radio" value="1 year" />
                  <c-check v-model="document.experience.forex" name="forex" title="1-3 года" type="radio" value="1-3 years" />
                  <c-check v-model="document.experience.forex" name="forex" title="свыше 3-х лет" type="radio" value="over 3 years" />
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-info-wrap">
                <!-- How frequently do you operate on the financial markets? -->
                <h4>Как часто Вы работаете на финансовых рынках?</h4>
                <div class="flex">
                  <!-- Never -->
                  <c-check
                    v-model="document.frequentlyOperateMarkets"
                    name="frequentlyOperateMarkets"
                    title="Никогда"
                    type="radio"
                    value="Never"
                  />

                  <!-- Daily -->
                  <c-check
                    v-model="document.frequentlyOperateMarkets"
                    name="frequentlyOperateMarkets"
                    title="Ежедневно"
                    type="radio"
                    value="Daily"
                  />

                  <!-- Weekly -->
                  <c-check
                    v-model="document.frequentlyOperateMarkets"
                    name="frequentlyOperateMarkets"
                    title="Еженедельно"
                    type="radio"
                    value="Weekly"
                  />

                  <!-- Monthly -->
                  <c-check
                    v-model="document.frequentlyOperateMarkets"
                    name="frequentlyOperateMarkets"
                    title="Ежемесячно"
                    type="radio"
                    value="Monthly"
                  />
                </div>
              </div>

              <div class="form-info-wrap">
                <!-- Have you got an experience in receiving the following services? -->
                <h4>У Вас есть опыт получения следующих услуг?</h4>

                <div class="flex">
                  <!-- Brokerage services -->
                  <c-check
                    v-model="document.experienceReceivingFollowingServices"
                    name="experienceReceivingFollowingServices"
                    title="Брокерские услуги"
                    type="radio"
                    value="Brokerage services"
                  />

                  <!-- Financial advisor -->
                  <c-check
                    v-model="document.experienceReceivingFollowingServices"
                    name="experienceReceivingFollowingServices"
                    title="Финансового консультанта"
                    type="radio"
                    value="Financial advisorFinancial advisor"
                  />

                  <!-- Assets management -->
                  <c-check
                    v-model="document.experienceReceivingFollowingServices"
                    name="experienceReceivingFollowingServices"
                    title="Управления активами"
                    type="radio"
                    value="Assets management"
                  />

                  <!-- Other financial services, specify -->
                  <c-check
                    v-model="document.experienceReceivingFollowingServices"
                    name="experienceReceivingFollowingServices"
                    title="Другие финансовые услуги"
                    type="radio"
                    value="Other financial services"
                  />
                </div>
              </div>

              <div class="form-info-wrap">
                <!-- Other financial services -->
                <label>Другие финансовые услуги</label>
                <input v-model="document.otherSourceAnnualIncomeSpecify" type="text" />
              </div>

              <div class="form-info-wrap">
                <!-- Purpose of your investment -->
                <h4>Цель Ваших инвестиций</h4>

                <div class="flex">
                  <!-- Capital preservation -->
                  <c-check
                    v-model="document.purposeInvestment"
                    name="purposeInvestment"
                    title="Сохранение капитала"
                    type="radio"
                    value="Capital preservation"
                  />

                  <!-- Hedge -->
                  <c-check v-model="document.purposeInvestment" name="purposeInvestment" title="Хеджирование" type="radio" value="Hedge" />

                  <!-- Trading profit -->
                  <c-check
                    v-model="document.purposeInvestment"
                    name="purposeInvestment"
                    title="Доход от торговли"
                    type="radio"
                    value="Trading profit"
                  />

                  <!-- Income -->
                  <c-check
                    v-model="document.purposeInvestment"
                    name="purposeInvestment"
                    title="Получение дохода"
                    type="radio"
                    value="Income"
                  />

                  <!-- Capital growth -->
                  <c-check
                    v-model="document.purposeInvestment"
                    name="purposeInvestment"
                    title="Рост капитала"
                    type="radio"
                    value="Capital growth"
                  />

                  <!-- Speculation -->
                  <c-check
                    v-model="document.purposeInvestment"
                    name="purposeInvestment"
                    title="Спекуляции"
                    type="radio"
                    value="Speculation"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="item-block">
            <!-- Risk profile of the Client -->
            <!-- <h4>Риск профиль Клиента</h4> -->
            <div class="col">
              <div class="form-info-wrap">
                <!-- How much of your capital (approximately) is currently invested or planned for investment? -->
                <h4>Какая доля Вашего капитала (примерно) инвестирована или планируется для инвестирования?</h4>

                <div class="flex">
                  <c-check v-model="document.riskProfile" name="riskProfile" title="0 - 25%" type="radio" value="0 - 25%" />
                  <c-check v-model="document.riskProfile" name="riskProfile" title="25 - 50%" type="radio" value="25 - 50%" />
                  <c-check v-model="document.riskProfile" name="riskProfile" title="50 - 75%" type="radio" value="50 - 75%" />
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-info-wrap">
                <!-- Your expected investment period -->
                <h4>Ваш ожидаемый период инвестирования</h4>

                <div class="flex">
                  <!-- 0 - 1 years -->
                  <c-check v-model="document.investmentPeriod" name="investmentPeriod" title="0 - 1 лет" type="radio" value="0 - 1 years" />

                  <!-- 1 - 3 years -->
                  <c-check v-model="document.investmentPeriod" name="investmentPeriod" title="1 - 3 лет" type="radio" value="1 - 3 years" />
                  <!-- 3 - 10 years -->
                  <c-check
                    v-model="document.investmentPeriod"
                    name="investmentPeriod"
                    title="3 - 10 лет"
                    type="radio"
                    value="3 - 10 years"
                  />

                  <!-- More than 10 years -->
                  <c-check
                    v-model="document.investmentPeriod"
                    name="investmentPeriod"
                    title="Более 10 лет"
                    type="radio"
                    value="More than 10 years"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <div class="confirms-wrap">
          <!-- The Client hereby confirms that: -->
          <h4>Настоящим Клиент подтверждает, что:</h4>
          <rules />

          <c-check v-model="document.confirmRules" name="confirmRules" title="Я согласен" type="checkbox" />
        </div>

        <!-- <button :disabled="document.confirmRules !== true" class="primary" type="submit">send</button> -->
        <button class="primary" type="submit">send</button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Rules from '@/components/RulesQuestionnaire.vue';
import CModal from '@/components/UI/ComponentModal.vue';
import CCheck from '@/components/UI/ComponentCustomCheck.vue';
import Agreement from '@/components/AgreementInfoComponent.vue';

export default {
  name: 'QuestionnairePage',
  emits: ['close'],
  components: { CModal, Agreement, CCheck, Rules },
  data() {
    return {
      modals: {
        info: { visible: false },
      },
      document: {
        firstName: '',
        lastName: '',
        middleName: '',
        gender: '',
        marriedStatus: '',
        numberDependents: '',
        identityType: '',
        birthdate: '',
        issuedDate: '',
        identity: '',
        issuingAuthority: '',
        otherDateDocumentIssueExpire: '',
        otherDocumentNumber: '',
        otherIssuingAuthority: '',
        taxResidence: '',
        usTaxResident: '',
        taxNumber: '',
        addressTrue: null,
        postCodeCountryRegion: '',
        pep: null,
        pepPosition: '',
        pepGovernmentOrganization: '',
        pepNameState: '',
        pepAffiliationFamily: '',
        pepAffiliationPosition: '',
        pepAffiliationGovernmentOrganization: '',
        pepAffiliationNameState: '',
        pepAffiliationForm: '',
        educationEconomicProfile: '',
        otherEducationName: '',
        employmentType: '',
        otherEmploymentType: '',
        companyName: '',
        professionalCapacities: '',
        sizeAnnualIncome: '',
        sourceAnnualIncome: [],
        otherSourceAnnualIncome: '',
        estimatedWorth: '',
        experience: { moneyMarket: '', stocksETFs: '', futures: '', options: '', forex: '' },
        frequentlyOperateMarkets: '',
        otherSourceAnnualIncomeSpecify: '',
        riskProfile: '',
        investmentPeriod: '',
        purposeInvestment: '',
        confirmRules: null,
      },
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      sendForm: 'sendForm',
    }),
    agreement() {
      this.modals.info.visible = true;
    },
    closeModal() {
      this.modals.info.visible = false;
    },
    processingForm() {
      console.log(this.document);
      this.sendForm(this.document);
    },
  },
  watch: {},
  created() {},
  mounted() {
    // this.agreement();
  },
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.questionnaire {
  height: 100%;
  overflow: auto;
}

.wrap {
  padding: 30px 15px 100px;
  overflow: auto;
  min-height: 100vh;
  @include breakpoint-down(md) {
    padding: var(--setting-line-height) 15px 100px;
  }
}

section {
  margin-bottom: 40px;
}

.section-tile {
  text-align: center;
  font-size: 22px;
  margin-bottom: 20px;
}

form {
  width: 60%;
  margin: 0 auto;

  h3 {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 12px;
    text-align: center;
  }

  h4 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 6px;
    @include breakpoint-down(md) {
      font-size: 18px;
    }
  }

  @include breakpoint-down(md) {
    padding-top: 25px;
    width: 100%;
  }
}

.item-title {
  font-size: 22px;
  margin-bottom: 20px;
}

.item-block {
  display: flex;
  gap: 0 150px;
  margin-bottom: 40px;

  .col {
    width: 50%;
  }

  @include breakpoint-down(md) {
    display: block;

    .col {
      width: 100%;
      margin-bottom: 14px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.flex {
  display: flex;
  gap: 5px 15px;
  flex-wrap: wrap;
  @include breakpoint-down(md) {
    gap: 10px 15px;
  }
}

.input-wrap {
  //max-width: 300px;
}

.questionnaire {
  .form-info-wrap {
    margin-bottom: 14px;

    label {
      margin-bottom: 4px;
      color: var(--pale-violet);
      font-size: 12px;
      display: block;
    }

    input:not([type='radio']) {
      width: 100%;
      height: 25px;
      border-radius: var(--main-border-radius);
      padding: 0 10px;
      background-color: var(--input-alt-default-bg);
      border: 1px solid var(--input-alt-default-border-outside);
      color: var(--input-alt-default-text);
      box-shadow: inset 0 1px 0 var(--input-default-shadow-inner-color);
      font-size: 12px;
      @include breakpoint-down(md) {
        height: 34px;
      }
    }
  }

  .radio-checkbox-wrap {
    display: flex;
    align-items: center;

    label {
      margin: 0 10px 0 0;
    }
  }

  .confirms-wrap {
    margin: 30px 0 40px;
    border-radius: var(--main-border-radius);
    border: solid 1px var(--input-alt-default-border-outside);
    padding: 30px;

    h4 {
      margin-bottom: 20px;
    }
  }
}
</style>
