<template>
  <div class="boarding">
    <form @submit.prevent="submitForm">
      <!-- <h3>Давайте познакомимся</h3> -->
      <div class="item-block">
        <div v-if="step === 0">
          <h4>Персональная информация</h4>
          <div class="flex">
            <div class="boarding__item">
              <c-label hint-text="Введите ваши Фамилию Имя Отчество. Например: Иванов Иван Иванович" text="Как вас зовут (ФИО):" />
              <input
                v-model="personalData.form.fio"
                autocomplete="off"
                pattern="\D{8,}"
                required
                :disabled="lock()"
                title="Введите ваши Фамилию Имя Отчество"
                type="text"
                @input="validateInputFIO($event.target)"
              />
            </div>

            <div class="boarding__item">
              <c-label hint-text="Укажите ваш пол" text="Пол:" />
              <div class="select-inp-wrap">
                <c-radio v-model="personalData.form.sex" :disabled="lock()" :options="sexOptions" name="sex" />
              </div>
            </div>

            <div class="boarding__item">
              <c-label hint-text="Введите дату вашего рождения" text="Дата вашего рождения:" />
              <input
                v-model="personalData.form.birthdate"
                :max="new Date(new Date().setFullYear(new Date().getFullYear() - 14)).toISOString().split('T')[0]"
                :min="new Date(new Date().setFullYear(new Date().getFullYear() - 80)).toISOString().split('T')[0]"
                autocomplete="bday"
                required
                :disabled="lock()"
                title="Введите дату вашего рождения"
                type="date"
              />
            </div>

            <div class="boarding__item">
              <c-label hint-text="Напишите страну и город где вы родились" text="Место рождения:" />
              <input
                v-model="personalData.form.birthplace"
                autocomplete="off"
                required
                :disabled="lock()"
                title="Напишите страну и город где вы родились"
                type="text"
              />
            </div>

            <div class="boarding__item">
              <c-label hint-text="Введите ваш email" text="Ваш email:" />
              <input
                v-model="personalData.form.email"
                :required="!emailOptional"
                :disabled="lock()"
                autocomplete="email"
                title="Введите ваш email"
                type="email"
              />
              <div class="custom-check">
                <label>
                  <div class="title">Нет email</div>
                  <input v-model="emailOptional" title="" type="checkbox" />
                  <div class="indicator">
                    <img :src="icons.check" alt="" />
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div v-if="step === 1">
          <h4>Идентификация личности</h4>
          <div class="flex">
            <div class="boarding__item">
              <c-label hint-text="Выберите страну вашего гражданства" text="Вы гражданин:" />
              <c-select
                v-model="personalData.form.citizenship"
                :disabled="lock()"
                :options="optionsTaxCountries()"
                title="Выберите страну вашего гражданства"
              />
            </div>

            <div class="boarding__item">
              <c-label hint-text="Выберите идентификационный документ" text="Документ:" />
              <div class="select-inp-wrap">
                <c-radio v-model="personalData.form.identityType" :disabled="lock()" :options="identityTypeOptions" name="identityType" />
              </div>
            </div>

            <div class="boarding__item">
              <c-label hint-text="Введите номер идентификационного документа" text="Номер документа:" />
              <input
                v-model="personalData.form.identity"
                autocomplete="off"
                pattern=".{5,}"
                required
                :disabled="lock()"
                title="Введите номер идентификационного документа"
                type="text"
                @input="validateInputLatinNumeric($event.target)"
              />
            </div>

            <div class="boarding__item">
              <c-label hint-text="Введите государственный орган, выдавший документ" text="Кто выдал:" />
              <input
                v-model="personalData.form.issued"
                autocomplete="off"
                pattern=".{5,}"
                required
                :disabled="lock()"
                title="Введите государственный орган, выдавший документ"
                type="text"
              />
            </div>

            <div class="boarding__item">
              <c-label hint-text="Дата выдачи документа" text="Дата выдчи:" />
              <input
                v-model="personalData.form.issuedDate"
                :max="new Date().toISOString().split('T')[0]"
                autocomplete="off"
                required
                :disabled="lock()"
                title="Дата выдачи документа"
                type="date"
              />
            </div>

            <div class="boarding__item">
              <c-label hint-text="Дата истечения срока действия документа" text="Дата окончания:" />
              <input
                v-model="personalData.form.expDate"
                :min="new Date(new Date().setMonth(new Date().getMonth() + 2)).toISOString().split('T')[0]"
                autocomplete="off"
                :required="!expIndefinite"
                :disabled="lock()"
                title="Дата истечения срока действия документа"
                type="date"
              />
              <div class="custom-check">
                <label>
                  <div class="title">Безсрочный</div>
                  <input v-model="expIndefinite" :disabled="lock()" title="" type="checkbox" />
                  <div class="indicator">
                    <img :src="icons.check" alt="" />
                  </div>
                </label>
              </div>
            </div>

            <div class="boarding__item upload-file">
              <c-label
                hint-text="Сфотогрфиируйте или загрузите документ в формате pdf или jpeg"
                text="Прикрепите идентификационный документ:"
              />
              <c-uploader
                v-model="personalData.files"
                :accept="'application/pdf,image/*'"
                :description="'Загрузите или перетащите файлы'"
                :documentType="'clients_identification'"
                :disabled="lock()"
                @remove="removeFile"
                >Прикрепите файл
              </c-uploader>
            </div>
          </div>
        </div>
        <div v-if="step === 2">
          <h4>Адрес проживания</h4>
          <div class="flex">
            <div class="boarding__item">
              <c-label hint-text="Выберите страну вашего налогового резидентства" text="Налоговый резидент:" />
              <c-select
                v-model="personalData.form.taxCountry"
                :disabled="lock()"
                :options="optionsTaxCountries()"
                title="Выберите страну вашего налогового резидентства"
              />
            </div>
            <div class="boarding__item">
              <c-label text="Вы налоговый резидент USA?:" />
              <div class="select-inp-wrap">
                <c-radio v-model="personalData.form.usTaxResident" :disabled="lock()" :options="usTaxResidentOptions" name="identityType" />
              </div>
            </div>
            <div class="boarding__item">
              <c-label hint-text="Укажите ваш почтовый индекс" text="Почтовый индекс:" />
              <input
                v-model="personalData.form.postalcode"
                autocomplete="postal-code"
                required
                :disabled="lock()"
                title="Почтовый индекс"
                type="text"
              />
            </div>
            <div class="boarding__item">
              <c-label hint-text="Область, Город, Улица, Дом, Квартира" text="Ваш адрес:" />
              <input
                v-model="personalData.form.address"
                autocomplete="off"
                required
                :disabled="lock()"
                title="Область, Город, Улица, Дом, Квартира"
                type="text"
              />
            </div>
            <div class="boarding__item">
              <c-label hint-text="Дата истечения срока действия документа" text="Дата выдачи:" />
              <input
                v-model="personalData.form.addressDate"
                :max="new Date().toISOString().split('T')[0]"
                :min="new Date(new Date().setMonth(new Date().getMonth() - 12)).toISOString().split('T')[0]"
                autocomplete="off"
                required
                :disabled="lock()"
                title="Дата истечения срока действия документа"
                type="date"
              />
            </div>

            <div class="boarding__item upload-file">
              <c-label
                hint-text="Сфотогрфиируйте или загрузите документ в формате pdf или jpeg"
                text="Прикрепите документ, подтверждающий адрес:"
              />
              <c-uploader
                v-model="personalData.files"
                :accept="'application/pdf,image/*'"
                :description="'Загрузите или перетащите файлы'"
                :documentType="'clients_address'"
                :disabled="lock()"
                @remove="removeFile"
                >Прикрепите файл
              </c-uploader>
            </div>
          </div>
        </div>
        <div v-if="step === 3">
          <h4>Настоящим подтверждаю:</h4>
          <!-- Настоящим Клиент подтверждает, что: -->
          <!-- •⁠  ⁠согласен и принимает вышеуказанные условия и присоединяется к Договору на управление инвестициями, размещенному на сайте Управляющего https://ptfin.kz, выражая полное согласие с его условиями; -->
          <!-- •⁠  ⁠понял все вышеуказанные вопросы и заполнил ответы по своей инициативе. Информация, представленная в Соглашении, корректна; -->
          <!-- •⁠  ⁠обязуется информировать Управляющего как только произойдет какое-либо изменение в представленной выше информации; -->
          <!-- •⁠  ⁠если дополнительная информация не предоставлена, Клиент подтверждает, что является собственником средств, которые находятся на его счетах, а также подтверждает, что не проводит никаких операций на своих счетах от имени других лиц и (или) не хранит на этих счетах средства других лиц; -->
          <!-- •⁠  ⁠понимает, что в случае если ответы не отражают реальную ситуацию личного или финансового положения или Клиент не сможет своевременно проинформировать Управляющего о любых изменениях, он может подвергнуться риску, превышающему его допустимую степень риска или его финансовые возможности. -->
          <div class="boarding__item confirmation__item">
            <input v-model="personalData.form.agreement.contract" :disabled="waitingCode" required title="" type="checkbox" />
            <div class="title">
              согласен и принимаю вышеуказанные условия, присоединяюсь к Договору на управление инвестициями, размещенному на сайте
              Управляющего <a href="https://ptfin.kz/documentation">https://ptfin.kz/documentation</a> и выражаю полное согласие с его
              условиями
            </div>
          </div>
          <div class="boarding__item confirmation__item">
            <input v-model="personalData.form.agreement.doRight" :disabled="waitingCode" required title="" type="checkbox" />
            <div class="title">
              понял все вышеуказанные вопросы и заполнил ответы по своей инициативе. Информация, представленная в Соглашении, корректна
            </div>
          </div>
          <div class="boarding__item confirmation__item">
            <input v-model="personalData.form.agreement.updating" :disabled="waitingCode" required title="" type="checkbox" />
            <div class="title">
              обязуюсь информировать Управляющего об изменениях в представленной выше информации и понимаю, что если не своевременно
              проинформирую Управляющего о любых изменениях, то подвергаюсь риску, превышающему мою допустимую степень риска или мои
              финансовые возможности
            </div>
          </div>
          <div class="boarding__item confirmation__item">
            <input v-model="personalData.form.agreement.nominal" :disabled="waitingCode" required title="" type="checkbox" />
            <div class="title">
              подтверждаю, что являюсь собственником средств, которые находятся и будут находиться на моих счетах, а также подтверждаю, что
              не провожу никаких операций на своих счетах от имени других лиц и (или) не храню на этих счетах средства других лиц
            </div>
          </div>
          <div v-if="waitingCode" class="input-wrap">
            <div class="title" title="Введите код из смс">{{ error ? 'Неверный код' : null }}</div>
            <input v-model="code" v-focus placeholder="Введите код" type="text" @input="checkCode($event.target)" />
          </div>
        </div>
      </div>
      <button class="primary" :disabled="lock() && step === 3" type="submit">{{ waitingCode ? 'Новый код?' : 'Отправить' }}</button>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { icons } from '@/assets/svg-img';
// import CCheck from '@/components/UI/ComponentCustomCheck.vue';
import CRadio from '@/components/UI/ComponentCustomRadio.vue';
import CSelect from '@/components/UI/ComponentSelect.vue';
import CUploader from '@/components/UI/ComponentUploader.vue';
import utils from '@/utils';
import CLabel from '@/components/UI/ComponentLabel.vue';

const focus = {
  mounted: (el) => el.focus(),
};

export default {
  name: 'BoardingComponent',
  emits: ['close', 'refuse', 'confirm'],
  components: { CSelect, CRadio, CUploader, CLabel },
  directives: {
    focus,
  },
  data() {
    return {
      icons,
      sexOptions: [
        { value: 'male', title: 'Мужской' },
        { value: 'female', title: 'Женский' },
      ],
      identityTypeOptions: [
        { value: 'passport', title: 'Паспорт' },
        { value: 'id_card', title: 'Удостоверение личности' },
        { value: 'international_passport', title: 'Заграничный паспорт' },
        // { value: 'driver_license', title: 'Водительское удостоверение' },
      ],
      usTaxResidentOptions: [
        { value: 'yes', title: 'Да' },
        { value: 'no', title: 'Нет' },
      ],
      code: null,
      expIndefinite: false,
      emailOptional: false,

      waitingCode: false,
      attempts: 0,
      step: 0,
    };
  },
  props: {
    visible: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      personalData: 'getPersonalData',
      accounts: 'getAccounts',
    }),
  },
  methods: {
    ...mapMutations({
      changeNotifications: 'changeNotifications',
      changeModals: 'changeModals',
    }),
    ...mapActions({
      pullPersonalData: 'pullPersonalData',
      pullAccounts: 'pullAccounts',
      updateAccountSelected: 'updateAccountSelected',
      sendEnrichment: 'sendEnrichment',
      deleteDocuments: 'deleteDocuments',
      createInstruction: 'createInstruction',
      signingInstruction: 'signingInstruction',
    }),
    validateInputFIO(target) {
      target.value = target.value
        .replace(/[^A-Za-zА-Яа-я ]/g, '')
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    },
    validateInputLatinNumeric(target) {
      target.value = target.value.replace(/[^A-Za-z\d]/g, '').toUpperCase();
    },
    optionsTaxCountries() {
      return [
        { text: 'Казахстан', value: 'kz' },
        { text: 'Россия', value: 'ru' },
        { text: 'Кыргызстан', value: 'kg' },
        { text: 'Узбекистан', value: 'uz' },
        { text: 'США', value: 'us' },
      ];
    },
    lock() {
      return this.personalData.options.status === 'opening';
    },
    close() {
      this.$emit('close');
    },
    refuse() {
      this.$emit('refuse');
    },
    async submitForm(e) {
      if (this.step === 0) {
        const { fio, sex, birthdate, birthplace, email } = this.personalData.form;
        this.sendEnrichment({ form: { fio, sex, birthdate, birthplace, email } });
      } else if (this.step === 1) {
        const { citizenship, identityType, identity, issued, issuedDate, expDate } = this.personalData.form;
        const files = this.personalData.files.filter((each) => each.documentType === 'clients_identification' && each.status === 'local');
        this.sendEnrichment({ form: { citizenship, identityType, identity, issued, issuedDate, expDate, files } });
      } else if (this.step === 2) {
        const { taxCountry, usTaxResident, postalcode, address, addressDate } = this.personalData.form;
        const files = this.personalData.files.filter((each) => each.documentType === 'clients_address' && each.status === 'local');
        this.sendEnrichment({ form: { taxCountry, usTaxResident, postalcode, address, addressDate, files } });
      } else if (this.step === 3) {
        const submit = e.target.querySelector('button[type="submit"]');
        submit.disabled = true;
        const response = await this.createInstruction({ form: this.personalData.form, options: { forced: this.waitingCode } });
        let text = '';
        let wait = 60000;
        if (response.status === 'sent') {
          text += 'Код отправлен на номер ' + response.data.chat + ', в ' + utils.getLocaleDateTimeString(response.data.sent);
          if (response.data.sent < new Date().valueOf() - 60 * 1000) {
            wait = 5000;
            text += '<br />Он еще актуален';
          }
          this.changeNotifications({ text, style: 'green', type: 'success', duration: 10 });
          this.waitingCode = true;
          setTimeout(() => (submit.disabled = false), wait);
          return void console.log('Ожидание ввода кода');
        }
      }
      if (this.step < 3) this.step++;
    },
    async checkCode(target) {
      if (this.attempts > 10) {
        this.form.code = '';
        target.disabled = true;
        const text = 'Вы вводите много неверных кодов!!!<br />Начните заново!!!';
        return void this.changeNotifications({ text, style: 'red', type: 'danger', duration: 600 });
      }
      target.value = target.value.replace(/\D+/g, '');
      if (target.value.length === 6) {
        const submit = target.closest('form').querySelector('button[type="submit"]');
        submit.disabled = true;
        await this.processingCode(this.personalData.form);
        submit.disabled = false;
      }
    },
    async processingCode(form) {
      const response = await this.signingInstruction({ form, options: { code: this.code } });
      let text = '';
      if (response.error) {
        this.attempts++;
        if (response.status === 'wrong') text += 'Код не совпадает, попробуйте еще раз';
        else if (response.status === 'outdated') text += 'Код устарел, вам был выслан новый код<br />Введите новый код';
        else text += 'Неизвестная ошибка!!!';
        this.code = '';
        return void this.changeNotifications({ text, style: 'red', type: 'danger', duration: 10 });
      }
      if (response.status === 'signed') {
        this.changeNotifications({
          text: 'Вы подали поручение на заключение договора.<br />Ожидайте обработки вашего поручения',
          style: 'green',
          type: 'success',
          duration: 10,
        });
        await this.pullAccounts();
        this.updateAccountSelected(Object.keys(this.accounts)[0]);
        this.pullPersonalData();
        return void console.log('Принято');
      }
      throw new Error('processingCode unexpected error!!!');
    },
    async removeFile(file) {
      if (file.status === 'local') {
        const index = this.personalData.files.findIndex((each) => each.url === file.url);
        this.personalData.files.splice(index, 1);
      } else {
        await this.deleteDocuments({ revision: file.url });
        this.pullPersonalData();
      }
    },
  },
  watch: {
    personalData: {
      handler(newState) {
        if (newState.options.status === 'opening') this.close();
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.pullPersonalData();
    // this.step = 1;
  },
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.boarding {
  form {
    max-width: 600px;
    @include breakpoint-down(md) {
      max-width: 100%;
      width: 100%;
    }
  }

  h3 {
    margin-bottom: 28px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }

  h4 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 15px;
    @include breakpoint-down(md) {
      margin-bottom: 8px;
    }
  }

  .flex {
    display: flex;
    flex-wrap: wrap;
    gap: 0 20px;
  }

  &__item {
    margin-bottom: 20px;
    width: calc(50% - 10px);
    @include breakpoint-down(md) {
      width: 100%;
      margin-bottom: 12px;
    }

    &.upload-file {
      width: 100%;
    }

    .select-inp-wrap {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 0 10px;
    }

    input {
      width: 100%;
      padding: 12px 8px;

      &:is([type='date']) {
        width: 100%;
        -webkit-appearance: none;
      }
    }
  }

  .custom-check {
    label {
      display: inline-flex !important;
      align-items: center;
      cursor: pointer;
      position: relative;
      color: var(--pale-violet);

      .title {
        font-size: 8px;
      }

      input {
        position: absolute;
        inset: 0;
        opacity: 0;
        height: auto;

        &:checked + .indicator {
          img {
            opacity: 1;
          }
        }
      }

      &:hover {
        .indicator {
          border: 1px solid var(--input-hovered-border-outside-color);
        }
      }
    }

    .indicator {
      width: 10px;
      height: 10px;
      background-color: rgb(130 136 201 / 37%);
      border: solid 1px var(--table-header-shadow-color);
      margin-left: 4px;
      padding: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: border 0.2s ease-in-out;
      flex-shrink: 0;
      @include breakpoint-down(md) {
        width: 15px;
        height: 15px;
      }
    }

    img {
      opacity: 0;
      transition: all 0.2s ease;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.confirmation {
  &__item {
    display: flex;
    width: 100%;

    input {
      width: auto;
      padding: 0;
    }

    .title {
      margin-left: 10px;
      font-size: 13px;
    }
  }
}

// старые стили
form {
  .form-wrap {
    display: flex;
    align-items: center;
    box-shadow: inset 0 1px 0 var(--input-default-shadow-inner-color);
    border-radius: var(--main-border-radius);
    background-color: var(--input-alt-default-bg);
    border: 1px solid var(--input-alt-default-border-outside);
    padding: 2px 5px;

    input {
      text-align: center;
      color: var(--input-alt-default-text);
      padding: 0;
      height: 24px;
      font-size: 14px;
      font-weight: 600;
      flex: 1;

      border: none;

      &:disabled {
        background: transparent;
        opacity: 0.5;
      }
    }

    .flex {
      display: flex;
      width: 100%;
      justify-content: center;
      gap: 5px 15px;
      flex-wrap: wrap;
      // @include breakpoint-down(md) {
      // gap: 10px 15px;
      // }
    }
  }
}
</style>
