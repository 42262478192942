export default {
  pressing(e) {
    e.target.classList.add('pressing');
    setTimeout(() => {
      e.target.classList.remove('pressing');
    }, 100);
  },

  compression(e) {
    e.target.classList.add('compression');
    setTimeout(() => {
      e.target.classList.remove('compression');
    }, 100);
  },
};
