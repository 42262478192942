<template>
  <div class="account-data">
    <div class="wrap">
      <div class="item">
        <c-label text="Номер счета:" />
        <div class="item__value">{{ accountSelected }}</div>
      </div>
      <div class="item">
        <c-label text="Клиент:" />
        <div class="item__value">{{ data?.full_name }}</div>
      </div>
      <div class="item">
        <c-label text="Брокер:" />
        <div class="item__value">{{ data?.broker_name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import CLabel from '@/components/UI/ComponentLabel.vue';

export default {
  name: 'CabinetComponent',
  components: { CLabel },
  data: function () {
    return {
      data: null,
    };
  },
  computed: {
    ...mapGetters({
      getAccounts: 'getAccounts',
      accountSelected: 'getAccountSelected',
    }),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({}),
    getData() {
      this.data = this.getAccounts[this.accountSelected];
    },
  },
  watch: {},
  created() {
    this.getData();
  },
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.account-data {
  .wrap {
    display: flex;
    align-items: center;
    gap: 0 20px;
    @include breakpoint-down(md) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .item {
    //&__title {
    //  color: var(--table-column-text-header-color);
    //  font-size: 12px;
    //}

    @include breakpoint-down(md) {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
