<template>
  <div class="order-form">
    <form>
      <div class="ticker-wrap">
        <div>{{ data.deal.orders[1].action === 'buy' ? 'Купить' : 'Продать' }}</div>
        <div @click.stop="showSymbol">{{ data.deal.general.symbol }}</div>
      </div>

      <div class="form-row">
        <div class="form-col">
          <label>Type</label>
          <component-select v-model="data.deal.orders[1].type" :options="orderTypes" @change="handleSelectChange" />
        </div>
        <div class="form-col">
          <label>Closing type</label>
          <component-select v-model="data.deal.orders[1].tif" :options="timePeriods" />
        </div>
      </div>

      <div class="form-row">
        <div :class="[{ danger: danger && !data.deal.general.quantity }, 'form-col']">
          <label for="quantity">Quantity</label>
          <input v-model="data.deal.general.quantity" name="quantity" type="text" />
        </div>
        <div class="form-col cost">
          <label>Cost</label>
          <div class="cost-value">~ {{ calcCost() }}</div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-col">
          <div :class="[{ danger: danger && !data.deal.orders[1].limitPrice && this.data.deal.orders[1].type === 'limit' }, 'col-item']">
            <label for="limitPrice">Limit Price</label>
            <input :disabled="this.data.deal.orders[1].type !== 'limit'" name="limitPrice" type="text" @input="limitPrice" />
          </div>
        </div>
        <div class="form-col">
          <div :class="[{ danger: danger && !data.deal.orders[1].triggerPrice && this.data.deal.orders[1].type === 'stop' }, 'col-item']">
            <label for="triggerPrice">Stop Price</label>
            <input
              v-model="data.deal.orders[1].triggerPrice"
              :disabled="this.data.deal.orders[1].type !== 'stop'"
              class="order-input"
              name="triggerPrice"
              type="text"
            />
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-col">
          <div class="bid" title="BID">
            <div class="title">BID</div>
            <div class="arrow">&#8595;</div>
            <div>
              <span>{{ utils.getLocateCurrency(bid) }}</span> <br />
              <sup>{{ bidSize }}</sup>
            </div>
          </div>
        </div>
        <div class="form-col">
          <div class="ask" title="ASK">
            <div class="title">ASK</div>
            <div class="arrow">&#8593;</div>
            <div>
              <span>{{ utils.getLocateCurrency(ask) }}</span> <br />
              <sup>{{ askSize }}</sup>
            </div>
          </div>
        </div>
      </div>

      <textarea v-model="data.deal.general.description" />

      <!--      {{ clients }}-->

      <div class="wrapper">
        <test-c-table :columnDefs="columnDefs" :rows="localClients" />
      </div>

      <div class="btn-wrap">
        <div
          v-for="action in actions"
          :key="action.value"
          :class="[action.value, { active: data.deal.orders[1].action === action.value }]"
          class="btn"
          @click.stop="choiceAction(action.value)"
        >
          {{ action.text }}
        </div>
      </div>

      <div class="form-bottom">
        <button class="primary" @click="openModalConfirmOrder">Send order</button>
      </div>
    </form>
  </div>
  <c-modal ref="modalSendOrder" title="Подтверждение заявки">
    <div class="confirm-text">Вы уверены что хотите оформить заявку?</div>
    <div class="confirm-data">
      <div class="confirm-item">
        <div class="title">Направление:</div>
        <span>{{ data.deal.orders[1].action === 'buy' ? 'Покупка' : 'Продажа' }}</span>
      </div>
      <div class="confirm-item">
        <div class="title">Тип ордера:</div>
        <span style="text-transform: uppercase">{{ data.deal.orders[1].type }}</span>
      </div>

      <div class="confirm-item">
        <div class="title">Инструмент:</div>
        <span>{{ data.deal.general.symbol }}</span>
      </div>
      <div class="confirm-item">
        <div class="title">Количество:</div>
        <span>{{ data.deal.general.quantity }}</span>
      </div>
      <div v-show="data.deal.orders[1].type !== 'market'" class="confirm-item">
        <div class="title">Цена:</div>
        <span v-show="data.deal.orders[1].limitPrice">{{ data.deal.orders[1].limitPrice }}</span>
        <span v-show="!data.deal.orders[1].limitPrice">{{ data.deal.orders[1].triggerPrice }}</span>
      </div>
    </div>
    <button class="primary" @click="sendConfirmedOrder(data)">Подтвердить</button>
  </c-modal>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapGetters, mapMutations, mapActions } from 'vuex';
import ComponentSelect from '@/components/UI/ComponentSelect.vue';
// import CTitle from '@/components/UI/ComponentBlockTitle.vue';
import utils from '@/utils';
import CModal from '@/components/UI/ComponentModal.vue';
import clients from '../../temporarily/clients';
import TestCTable from '@/components/UI/TestComponentTable.vue';

export default {
  name: 'mass-order-form',
  components: {
    TestCTable,
    CModal,
    ComponentSelect,
    // , CTitle
  },
  data() {
    return {
      bid: null,
      bidSize: null,
      ask: null,
      askSize: null,
      danger: false,
      clients,
      columnDefs: [
        { name: '', field: 'checkbox', type: 'checkbox', sort: true, flex: '0 0 40px' },
        { name: 'Клиент', field: 'client', type: 'string', sort: true, flex: 2 },
        { name: 'Тип', field: 'type', type: 'string', sort: true, flex: 2 },
        { name: 'Счет', field: 'account', type: 'string', sort: true, flex: 2 },
        { name: 'Телеграм', field: 'telegram', type: 'string', sort: true, flex: 2 },
        { name: 'Количество', field: 'quantity', type: 'string', sort: true, flex: 2 },
        { name: 'Сумма', field: 'summ', type: 'string', sort: true, flex: 2 },
      ],
      localClients: [],
      // show: true,
      data: {},
      // deal: {
      //   general: {
      //     symbol: null,
      //     quantity: null,
      //     description: '',
      //   },
      //   orders: [
      //     {},
      //     {
      //       action: 'buy',
      //       type: 'limit',
      //       tif: 'gtc',
      //       limitPrice: null,
      //       triggerPrice: null,
      //     },
      //   ],
      // },
      // list: [
      //   {
      //     account_id: null,
      //     contact_id: null,
      //     contract_id: null,
      //   },
      // ],
      // },
      actions: [
        { value: 'buy', text: 'Buy' },
        { value: 'sell', text: 'Sell' },
      ],
      orderTypes: [
        { value: 'market', text: 'Market' },
        { value: 'limit', text: 'Limit' },
        { value: 'stop', text: 'Stop' },
      ],
      timePeriods: [
        { value: 'gtc', text: 'GTC - Good till Canceled' },
        { value: 'day', text: 'DAY' },
      ],
    };
  },
  computed: {
    utils() {
      return utils;
    },
    ...mapGetters({
      isMobile: 'isMobile',
      accounts: 'getAccounts',
      // accountSelected: 'getAccountSelected',
      symbols: 'getSymbols',
      // levelI: 'getLevelI',
      selectedSymbol: 'getSelectedSymbol',
      getOrder: 'getOrder',
    }),
  },
  methods: {
    ...mapMutations({
      // changeLevelI: 'changeLevelI',
    }),
    ...mapActions({
      sendOrder: 'sendOrder',
    }),
    handleSelectChange() {
      this.data.deal.general.quantity = null;
      this.data.deal.orders[1].limitPrice = null;
      this.data.deal.orders[1].triggerPrice = null;
    },
    choiceAction(data) {
      this.data.deal.orders[1].action = data;
      // this.show = !this.show;
      // console.log(this.show);
    },
    calcCost() {
      const price = this.data.deal.orders[1].action === 'buy' ? this.ask : this.bid;
      return this.data.deal.general.quantity > 0 && price ? utils.getLocateCurrency(this.data.deal.general.quantity * price) : null;
    },
    openModalConfirmOrder(e) {
      e.preventDefault();
      // if (
      //   !this.data.deal.general.symbol ||
      //   !this.data.deal.general.quantity ||
      //   !this.data.deal.orders[1].limitPrice ||
      //   !this.data.deal.orders[1].type
      // )
      //   return void (this.danger = true);
      this.$refs.modalSendOrder.show = true;
    },
    sendConfirmedOrder(data) {
      console.log('data form :', data);
      // this.sendOrder(data);
      // this.$refs.modalSendOrder.show = false;
    },
    updateAccountSelected(newState) {
      this.data.list[0].account_id = newState;
      this.data.list[0].contact_id = this.accounts[newState].contact_id;
      this.data.list[0].contract_id = this.accounts[newState].contract_id;
    },
    limitPrice(e) {
      const pattern = /^\d{0,9}(\.?\d{0,2})?$/;
      // console.log(e.target.value, pattern.test(e.target.value), this.data.deal.orders[1].limitPrice);
      pattern.test(e.target.value)
        ? (this.data.deal.orders[1].limitPrice = e.target.value)
        : (e.target.value = this.data.deal.orders[1].limitPrice);
    },
    showSymbol() {
      console.log(this.symbols[this.selectedSymbol]);
    },
  },
  watch: {
    accountSelected(newState) {
      this.updateAccountSelected(newState);
    },
    selectedSymbol(newState) {
      this.updateSymbol(newState);
    },
    levelI: {
      handler(newState) {
        // console.log(newState);
        if (this.bid !== newState.bid) this.bid = newState.bid;
        if (this.bidSize !== newState.bidSize) this.bidSize = newState.bidSize;
        if (this.ask !== newState.ask) this.ask = newState.ask;
        if (this.askSize !== newState.askSize) this.askSize = newState.askSize;
      },
      deep: true,
    },
    // data: {
    //   handler(newState, oldState, test) {
    //     console.log(newState.deal.general, oldState.deal.general, test);
    //     if (newState.deal.general.quantity !== oldState.deal.general.quantity) {
    //       console.log('check true');
    //       newState.deal.general.quantity = '!!!';
    //     }
    //   },
    //   deep: true,
    // },
  },
  created() {
    this.order = this.getOrder;
    if (!this.data.list[0].account_id && this.accountSelected) this.updateAccountSelected(this.accountSelected);
    if (!this.data.deal.general.symbol && this.selectedSymbol) this.updateSymbol(this.selectedSymbol);
    // console.log(this.clients);
    for (const each of this.clients) {
      // { name: 'Клиент', field: 'client', type: 'string', sort: true, flex: 1 },
      // { name: 'Тип', field: 'type', type: 'string', sort: true, flex: 1 },
      // { name: 'Счет', field: 'account', type: 'string', sort: true, flex: 1 },
      // { name: 'Телеграм', field: 'telegram', type: 'string', sort: true, flex: 1 },
      // { name: 'Количество', field: 'quantity', type: 'string', sort: true, flex: 1 },
      // { name: 'Сумма', field: 'summ', type: 'string', sort: true, flex: 1 },
      // console.table(each);
      let item = {
        client: each.full_name,
        type: each.contract_type,
        account: each.contract_name,
        telegram: each.telegram_id,
        symbol: '',
      };
      this.localClients.push(item);
    }
  },
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.wrapper {
  height: 400px;
}

.confirm-text {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin: 20px 0;
}

.confirm-item {
  border-bottom: 1px solid var(--input-alt-default-border-outside);
  margin-bottom: 8px;
  padding-bottom: 2px;
  display: flex;
  justify-content: space-between;

  span {
    font-weight: 600;
  }
}

.confirm-data {
  width: 70%;
  margin: 0 auto 50px;
  @include breakpoint-down(md) {
    width: 90%;
    margin: 0 auto 20px;
  }
}

.order-form {
  background: var(--table-header-bg-color);
  box-shadow: -1px 1px 3px var(--table-header-shadow-color);
  padding: 8px 10px 10px;
  border-radius: var(--main-border-radius);

  label {
    color: var(--table-column-text-header-color);
    font-weight: 600;
    font-size: 10px;
    //position: absolute;
    bottom: 100%;
    left: 0;
    display: block;
  }

  form {
    padding: 0 10px;
  }

  .form-row {
    display: flex;
    margin: 0 -5px 6px;

    .form-col {
      width: 50%;
      padding: 0 5px;

      input {
        width: 100%;
        @include breakpoint-down(md) {
          height: 28px;
        }
      }
    }
  }

  textarea {
    width: 100%;
    background-color: var(--input-alt-default-bg);
    border: 1px solid var(--input-alt-default-border-outside);
    color: var(--input-alt-default-text);
    box-shadow: inset 0 1px 0 var(--input-default-shadow-inner-color);
    border-radius: var(--main-border-radius);
    padding: 15px;
    height: 100px;
    font-size: 11px;
    font-weight: 600;
  }
}

.ticker-wrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 600;
  margin-bottom: 10px;

  div {
    padding: 0 20px;
  }
}

.form-bottom {
  padding-top: 15px;
  border-top: 1px solid var(--input-alt-default-border-outside);
}

.bid,
.ask {
  position: relative;
  padding-left: 16px;
  height: 30px;
  font-weight: 600;
  display: flex;
  align-items: center;

  .title {
    margin-right: 8px;
    font-size: 12px;
  }

  .arrow {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    font-size: 25px;
    display: flex;
    align-items: center;
  }

  sup {
    color: var(--white);
    font-size: 10px;
  }

  span {
    font-size: 12px;
  }
}

.bid {
  color: var(--profit-green);
}

.ask {
  color: var(--loss-red);
}

.btn-wrap {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 12px 0 14px;

  input {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    height: 100%;
  }
}

.cost {
  font-size: 12px;
  font-weight: 600;
  border-bottom: 1px solid var(--input-alt-default-border-outside);

  &-value {
    transform: translate(0, 3px);
  }
}

.btn {
  border-radius: var(--main-border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--buy-button-default-text-color);
  height: 24px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  @include breakpoint-down(md) {
    height: 35px;
  }
}

.primary {
  @include breakpoint-down(md) {
    height: 35px;
  }
}

.buy {
  text-align: center;
  flex-grow: 0.5;
  background: rgba(0, 128, 0, 0);
  border: solid 1px rgba(0, 255, 0, 0.6);
  margin-right: 5px;

  &.active {
    background: rgba(0, 255, 0, 0.6);
  }
}

.danger {
  border: 1px solid var(--alert);
}

.sell {
  text-align: center;
  flex-grow: 0.5;
  background: rgba(255, 0, 0, 0);
  border: solid 1px rgba(255, 0, 0, 0.7);
  margin-left: 5px;

  &.active {
    background: rgba(255, 0, 0, 0.7);
  }
}
</style>
