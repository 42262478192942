<template>
  <div class="greeting">
    <div class="title">Вы отправили поручение на открытие счета</div>
    <div class="description">
      Сейчас наши специалисты заняты обработкой Вашего поручения.<br />
      Обычно мы обрабатываем такое поручение в течении следующего рабочего дня.
    </div>
    <div class="title">Воспользуйтесь этим временем:</div>
    <div class="description">
      1. Вы можете ознакомиться с нашим сайтом, лицензиями и документами:<br />
      <a href="https://ptfin.kz/documentation" target="_blank">ptfin.kz/documentation</a>
    </div>
    <div class="description">
      2. Ознакомиться с видео инструкциями по использованию нашего Терминала:<br />
      <a href="https://nc.ptfin.kz/index.php/s/yBLHxJL7NA7ww8k" target="_blank">pbull инструкция</a><br />
      <a href="https://nc.ptfin.kz/index.php/s/anxMax77HyZqjLL" target="_blank">pbull устновить на компьютер</a><br />
      <a href="https://nc.ptfin.kz/index.php/s/4kDSeZrmBf3wGnR" target="_blank">pbull устновить на iphone</a><br />
      <a href="https://nc.ptfin.kz/index.php/s/YKnMHLFsgjpXRit" target="_blank">pbull устновить на android</a><br />
    </div>
    <div class="description">
      3. Заполнить документы для последующей работы:<br />
      <router-link :to="{ name: 'password' }">"Экономический профиль"</router-link> - анкета, которая помогает лучше понять<br />
      ваше финансовое поведение, потребности и цели.<br />
      <router-link :to="{ name: 'password' }">"Инвестиционная декларация"</router-link> - документ, в котором будут<br />
      сформулированы ваши инвестиционные цели, предпочтения и ограничения<br />
    </div>
    <div class="description">
      4. Пополнить Ваш счет, для этого вы можете получить <router-link :to="{ name: 'password' }">Ваши рекавизиты.</router-link><br />
      Это ускорит открытие вашего счета
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'GreetingComponent',
  emits: [],
  components: {},
  directives: {},
  data() {
    return {};
  },
  props: {},
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({}),
  },
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.greeting {
  .title {
    margin-bottom: 10px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }

  .description {
    width: 90%;
    padding-bottom: 10px;
    text-align: center;
    margin: 0 auto;
  }
}
</style>
