<template>
  <div class="auth-template">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'auth-template',
};
</script>

<style lang="scss" scoped>
@import '../style/helper/index';
.auth-template {
  background: url('@/assets/bull.svg') no-repeat center;
  background-size: contain;
  min-height: 100vh;

  @include breakpoint-down(md) {
    background: url('@/assets/bull.svg') no-repeat top center;
    background-size: contain;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
}
</style>
