<template>
  <div class="fixed-scroll">
    <router-view />
    <notifications />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Notifications from '@/components/NotificationComponent.vue';

export default {
  components: {
    Notifications,
  },
  data() {
    return {
      connection: null,
      orientation: '',
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
    }),
  },
  methods: {
    ...mapMutations({
      changeOrder: 'changeOrder',
    }),
    ...mapActions({}),
    checkOrientation() {
      const orientation = window.matchMedia('(orientation: portrait)').matches ? 'portrait' : 'landscape';
      this.orientation = orientation;
      if (this.$route.name === 'order' && orientation === 'landscape') {
        document.body.classList.add('landscape-primary');
      }
      if (orientation === 'portrait') document.body.classList.remove('landscape-primary');
    },
    handleKeydown(e) {
      if (e.key === 'ArrowLeft') this.changeOrder({ action: 'buy' });
      if (e.key === 'ArrowRight') this.changeOrder({ action: 'sell' });
    },
  },
  watch: {
    isLoggedIn(newState) {
      if (newState === false) this.$router.push({ name: 'login' });
    },
    $route(to) {
      if (to.name === 'order' && this.orientation === 'landscape') document.body.classList.add('landscape-primary');
      if (to.name !== 'order' && this.orientation === 'landscape') document.body.classList.remove('landscape-primary');
    },
  },
  created() {
    const root = document.querySelector(':root');
    root.classList.add('dark');
  },
  mounted() {
    this.checkOrientation();
    window.addEventListener('resize', this.checkOrientation);
    window.addEventListener('keydown', this.handleKeydown);
  },
  unmounted() {
    window.removeEventListener('resize', this.checkOrientation);
    window.removeEventListener('keydown', this.handleKeydown);
  },
};
</script>

<style lang="scss">
@import 'style/common-styles/index';

body {
  position: relative;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  @include breakpoint-down(md) {
    overflow-x: inherit;
  }

  .fixed-scroll {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    @include breakpoint-down(md) {
      overflow-x: auto;
      z-index: -99;
      background: linear-gradient(to bottom, rgba(38, 40, 64, 1), rgba(38, 40, 64, 1));
    }

    @media (orientation: landscape) {
      padding-left: env(safe-area-inset-left);
      padding-right: env(safe-area-inset-right);
    }
  }
}
</style>
