<template>
  <div :class="[animation, 'outside-wrap-shadow']" @click="handleClick">
    <div ref="notification" :class="type" :data-test="duration" class="notification">
      <div class="icon">
        <img :src="icons.notifyCheck" alt="" />
      </div>
      <div class="text" v-html="localMessage"></div>
    </div>
  </div>
</template>

<script>
import { icons } from '@/assets/svg-img';

export default {
  name: 'ComponentToast',
  emits: ['close'],
  props: {
    duration: { type: Number },
    message: { type: String },
    index: { type: Number },
    type: { type: String },
  },
  data() {
    return {
      icons,
      animation: 'show-toast',
      localMessage: this.message,
    };
  },
  methods: {
    closeToast() {
      this.animation = 'hide-toast';
      setTimeout(() => {
        this.$emit('close', this.index);
      }, this.duration * 1000);
    },
    handleClick(e) {
      e.preventDefault();
      this.closeToast();
    },
  },
  mounted() {
    this.autoCloseTimeout = setTimeout(() => {
      this.closeToast();
    }, this.duration * 1000);
  },
  beforeUnmount() {
    clearTimeout(this.autoCloseTimeout);
  },
};
</script>

<style lang="scss" scoped>
.outside-wrap-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  margin-bottom: 8px;
  border-radius: var(--main-border-radius);
}

.notification {
  position: relative;
  z-index: calc(infinity);
  display: inline-flex;
  padding: 15px 20px;
  min-width: 150px;
  align-self: center;
  align-items: center;
  box-shadow: 0 4px 5px 4px var(--widget-header-shadow-color);

  &.success {
    background: var(--table-header-bg-color);
    background: linear-gradient(90deg, rgb(32 36 80) -4%, rgb(36, 38, 55) 17%, rgb(28 36 107) 100%);
    box-shadow: inset rgb(62 59 117) 0 0 50px;
    border: solid 1px rgb(40 37 88);
    color: #fff;
  }

  &.danger {
    background: rgba(255, 0, 0, 0.2);
    background: linear-gradient(90deg, rgb(214 21 21 / 80%) -4%, rgb(36, 38, 55) 17%, rgb(36, 38, 55) 100%);
    box-shadow: inset rgb(169 47 47 / 80%) 0 0 50px;
    border: solid 1px rgb(172 36 36 / 80%);
    color: #fff;
  }

  &.warning {
    background: rgba(255, 152, 0, 0.2);
    background: linear-gradient(90deg, rgb(144 107 52) -4%, rgb(36, 38, 55) 17%, rgb(36, 38, 55) 100%);
    box-shadow: inset rgb(174 122 46 / 60%) 0 0 50px;
    border: solid 1px rgb(174 122 46 / 60%);
    color: #fff;
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .text {
    font-weight: 600;
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.show-toast {
  animation: slideDown 0.2s ease-in-out;
}

.hide-toast {
  opacity: 0;
  transform: translateX(100%);
  transition: all 0.3s ease-in-out;
}
</style>
