<template>
  <div class="main-page">
    <div class="widget-bg">
      <page-header :title="$route.meta.title" />

      <div class="wrap">
        <search-list />
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapGetters, mapActions, mapMutations } from 'vuex';
import SearchList from '@/components/SearchList';
import PageHeader from '@/components/mobile/ComponentPageHeader.vue';

export default {
  name: 'MobileSearchList',
  components: { PageHeader, SearchList },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({}),
  },
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: 0 15px;
}
</style>
