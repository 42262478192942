<template>
  <c-modal
    ref="modalSendOrder"
    :visible="visible"
    confirmation
    title="Подтверждение заявки"
    @close="close"
    @confirm="confirm"
    @refuse="refuse"
  >
    <div class="header">
      <div>Вы уверены что хотите {{ data.status === 'approval' ? 'подтвердить' : 'оформить' }} заявку?</div>
    </div>
    <div class="confirm">
      <div class="item">
        <div class="title">Направление:</div>
        <span>{{ data.action === 'buy' ? 'Покупка' : 'Продажа' }}</span>
      </div>
      <div class="item">
        <div class="title">Тип ордера:</div>
        <span style="text-transform: uppercase">{{ data.type }}</span>
      </div>

      <div class="item">
        <div class="title">Инструмент:</div>
        <span>{{ data.instrument.symbol }}</span>
      </div>
      <div class="item">
        <div class="title">Количество:</div>
        <span>{{ data.quantity }}</span>
      </div>
      <div v-if="data.price > 0" class="item">
        <div class="title">Цена:</div>
        <span>{{ utils.getLocateCurrency(data.price) }}</span>
      </div>
      <div v-if="data.limitPrice > 0" class="item">
        <div class="title">Цена:</div>
        <span>{{ utils.getLocateCurrency(data.limitPrice) }}</span>
      </div>
      <div v-if="data.triggerPrice > 0" class="item">
        <div class="title">Stop цена:</div>
        <span>{{ utils.getLocateCurrency(data.triggerPrice) }}</span>
      </div>
    </div>
  </c-modal>
</template>
<script>
import utils from '@/utils';
import CModal from '@/components/UI/ComponentModal.vue';

export default {
  name: 'confirmation-order',
  emits: ['close', 'refuse', 'confirm'],
  components: {
    CModal,
  },
  data() {
    return {};
  },
  computed: {
    utils() {
      return utils;
    },
  },
  props: {
    data: {
      type: Object,
    },
    visible: {
      type: Boolean,
    },
  },
  methods: {
    close() {
      console.log('Модальное окно закрыто  !!!!!!');
      this.$emit('close');
      // this.$refs.modalSendOrder.show = false;
      // Дополнительные действия при закрытии модального окна
    },
    refuse() {
      // console.log('Нажата кнопка "Отказаться"');
      // Дополнительные действия при нажатии на кнопку "Отказаться"
      this.$emit('refuse');
    },
    confirm() {
      // console.log('Нажата кнопка "Подтвердить"');
      this.$emit('confirm');
      // Дополнительные действия при нажатии на кнопку "Подтвердить"
    },
  },
  watch: {
    visible(newState) {
      // console.log('visible state :', newState);
      this.$refs.modalSendOrder.show = newState;
    },
  },
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.header {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin: 20px 0;

  .short-warning {
    font-size: 12px;
    color: rgb(255, 0, 0, 0.7);
    margin: 5px 0;
  }
}

.confirm {
  width: 70%;
  margin: 0 auto 50px;
  @include breakpoint-down(md) {
    width: 90%;
    margin: 0 auto 20px;
  }

  .item {
    border-bottom: 1px solid var(--input-alt-default-border-outside);
    margin-bottom: 8px;
    padding-bottom: 2px;
    display: flex;
    justify-content: space-between;

    span {
      font-weight: 600;
    }
  }
}
</style>
