<template>
  <div class="portfolio">
    <div class="top">
      <c-table :columnDefs="columnDefs" :rows="localAssets" @select="selectRow" />
    </div>
    <div class="bottom">
      <div>
        <c-table :columnDefs="columnDefs" :rows="localCash" />
      </div>
      <div>
        <c-table :columnDefs="totalColumn" :rows="localTotal" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import CTable from '@/components/UI/ComponentTable';

export default {
  name: 'portfolio-table-component',
  components: {
    CTable,
  },
  data() {
    return {
      localAssets: [],
      localCash: [],
      localTotal: [{ key: '', symbol: '', cashes: 0.0, assets: 0.0, amount: 0.0, bp: 0.0 }],
      columnDefs: [
        { name: 'Тикер', field: 'symbol', type: 'string', sort: true, search: 'text', flex: 1 },
        // { name: 'Account', field: 'account', type: 'string' },
        { name: 'Кол-во', field: 'quantity', type: 'number', sort: true, flex: 1 },
        { name: 'Цена', field: 'price', type: 'currency', flex: 1 },
        { name: 'Стоимость', field: 'amount', type: 'currency', sort: true, flex: 1 },
        {
          name: 'Приобретение',
          field: [
            { field: 'average', type: 'currency' },
            { field: 'purchase', type: 'currency' },
          ],
          type: 'composite',
          flex: 2,
        },
        {
          name: 'Прибыль/Убыток',
          field: [
            { field: 'pnl', type: 'currency' },
            { field: 'pnlp', type: 'percent' },
          ],
          type: 'composite',
          sort: true,
          flex: 2,
          color: true,
        },
      ],
      totalColumn: [
        { name: 'Деньги', field: 'cashes', type: 'currency', flex: 1 },
        { name: 'Активы', field: 'assets', type: 'currency', flex: 1 },
        { name: 'Портфель', field: 'amount', type: 'currency', flex: 1 },
        { name: 'Покупательская способность', field: 'bp', type: 'currency', flex: 1 },
      ],
    };
  },
  computed: {
    ...mapGetters({
      portfolio: 'getPortfolio',
    }),
  },
  methods: {
    ...mapMutations({
      setDefaultOrder: 'setDefaultOrder',
      changeOrder: 'changeOrder',
    }),
    ...mapActions({
      updateSelectedSymbol: 'updateSelectedSymbol',
    }),
    height() {
      const element = document.getElementById('your-element-id');
      const height = element.offsetHeight;
      console.log('Высота элемента:', height, 'пикселей');
    },
    selectRow(data) {
      this.updateSelectedSymbol(data.symbol);
      const asset = this.localAssets.find((item) => item.key === data.key);
      this.setDefaultOrder();
      this.changeOrder({ action: asset.quantity > 0 ? 'sell' : 'buy', type: 'limit', quantity: asset.quantity });
    },
  },
  watch: {
    portfolio: {
      handler(newState) {
        this.localAssets = [];
        this.localCash = [];
        for (const each of newState.items) {
          let item = {
            key: each.account + '_' + each.instrument.symbol,
            symbol: each.instrument.symbol,
            amount: each.price * (each.quantity + each.day.quantity),
          };

          if (each.instrument.asset_category === 'CASH') {
            this.localCash.push(item);
          } else {
            item.price = each.price;
            item.quantity = each.quantity + each.day.quantity;
            item.average = each.average;
            item.purchase = (item.quantity === 0.0 ? each.quantity : item.quantity) * each.average;
            item.pnl = each.pnl.all;
            item.pnlp = each.pnl.all / Math.abs(item.purchase);
            this.localAssets.push(item);
          }
        }
        this.localTotal = [
          {
            key: 'USD',
            symbol: 'USD',
            cashes: newState.total.cashes,
            assets: newState.total.assets,
            amount: newState.total.amount,
            bp: newState.total.bp,
          },
        ];
      },
      deep: true,
    },
  },
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
.portfolio {
  display: flex;
  flex-direction: column;
  height: 100%;

  .top {
    max-height: calc(100% - 112px);
  }

  .bottom {
    margin-top: auto;
  }
}
</style>
