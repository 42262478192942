<template>
  <div class="main-template">
    <setting-line-component />
    <router-view />
    <div v-if="isMobile">
      <app-bar />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import SettingLineComponent from '@/components/SettingLineComponent.vue';
import AppBar from '@/components/app-bar/AppBar.vue';

export default {
  name: 'main-template',
  components: { AppBar, SettingLineComponent },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile',
    }),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({}),
  },
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss">
@import '@/style/helper/index';

.main-template {
  min-height: 100vh;
  height: 100%;
  @include breakpoint-down(md) {
    min-height: calc(100vh - (var(--nav-main-height) + var(--setting-line-height) + 30px));
  }
}
</style>
