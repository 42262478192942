<template>
  <div class="keys">
    <form>
      <div class="input-item">
        <c-label text="Key first" />
        <input id="key_first" v-model="dataKeys.key_first" type="text" />
      </div>
      <div class="input-item">
        <c-label text="Key second" />
        <input id="key_second" v-model="dataKeys.key_second" type="text" />
      </div>

      <div class="info">
        <button class="send primary" @click.prevent="send(dataKeys)">Сохранить</button>

        <div class="description">Электронные ключи необходимы для удобства пополнения счета</div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import CLabel from '@/components/UI/ComponentLabel.vue';

export default {
  name: 'CabinetComponent',
  components: { CLabel },
  data: function () {
    return {
      dataKeys: {},
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      newKey: 'newKey',
    }),
    send(data) {
      this.newKey(data);
    },
  },
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.keys {
  form {
    width: 400px;
    @include breakpoint-down(md) {
      width: 100%;
    }

    .input-item {
      margin-bottom: 6px;
    }

    input {
      width: 100%;
    }
  }

  .info {
    display: flex;
    gap: 0 20px;
    margin-top: 20px;

    .description {
      font-size: 11px;
    }
  }
}
</style>
