import logo from '@/assets/ptfin-logo-test.svg';
import logoOriginal from '@/assets/logoOriginal.svg';
import blueLogo from '@/assets/logoBlue.svg';
import bereke from '@/assets/bereke.svg';
import freedom from '@/assets/freedom.svg';
import forte from '@/assets/forte.svg';

const img = {
  logo: logo,
  logoOriginal: logoOriginal,
  blueLogo: blueLogo,
  bereke: bereke,
  freedom: freedom,
  forte: forte,
};

export default img;
