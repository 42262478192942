<template>
  <div class="cabinet-component">
    <tabs :options="{ useUrlFragment: false }">
      <!-- АККАУНТ -->
      <tab name="Аккаунт">
        <div class="block__item-component">
          <div class="block__item-subtitle">Данные аккаунта</div>
          <account-data />
        </div>
        <div class="block__item-component">
          <div class="block__item-subtitle">Документы</div>
          <documents />
        </div>
      </tab>
      <!-- РЕКВИЗИТЫ -->
      <tab name="Реквизиты">
        <div class="block__item-component">
          <div class="block__item-subtitle">Пополнение счета</div>
          <div>
            <bank-requisites />
          </div>
        </div>
      </tab>
      <!-- НАСТРОЙКИ -->
      <tab name="Настройки">
        <div class="block__item-component">
          <div class="block__item-subtitle">Телеграм</div>
          <telegram />
        </div>
        <div class="block__item-component">
          <div class="block__item-subtitle">Ключи</div>
          <key />
        </div>
        <div class="block__item-component">
          <div class="block__item-subtitle">Смена пароля</div>
          <div>
            <change-pass />
          </div>
        </div>
        <div class="block__item-component">
          <div class="block__item-subtitle" style="display: none">Время обновления терминала</div>
          <div class="terminal-data-setting-wrap">
            <terminal-data-setting />
          </div>
        </div>
      </tab>
    </tabs>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import AccountData from '@/components/cabinet/AccountData.vue';
import Documents from '@/components/cabinet/Documents.vue';
import Key from '@/components/cabinet/Key.vue';
import Telegram from '@/components/cabinet/Telegram.vue';
import TerminalDataSetting from '@/components/TerminalDataSetting.vue';
import ChangePass from '@/components/cabinet/ChangePass.vue';
import BankRequisites from '@/components/cabinet/BankRequisites.vue';
import { Tabs, Tab } from '../../../node_modules/vue3-tabs-component';

export default {
  name: 'CabinetComponent',
  components: {
    TerminalDataSetting,
    AccountData,
    Documents,
    Key,
    Telegram,
    ChangePass,
    BankRequisites,
    Tabs,
    Tab,
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({}),
  },
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
.cabinet-component {
  .wrap {
  }

  .block {
    &__item {
    }

    &__item-title {
      font-size: 16px;
      margin-bottom: 8px;
      font-weight: 500;
    }

    &__item-subtitle {
      margin-bottom: 6px;
    }

    &__item-component {
      margin-bottom: 16px;
      padding-left: 15px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 2px;
        top: 5px;
        bottom: 5px;
        width: 2px;
        background: linear-gradient(180deg, rgb(14 24 77 / 60%) -9%, #72768c 48%, #212b68 81%);
      }
    }
  }
}
</style>
