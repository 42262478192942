<template>
  <div class="main-page">
    <div class="">
      <page-header :title="$route.meta.title" />
      <!--      <c-input :disabled="disabled" :icon="icons.keyboard" placeholder="red" type="password" @action="test" />-->
      <div class="total-block-wrap" @click="toggleTotal">
        <div class="total-block">
          <div class="top">
            <div class="title">Total</div>
            <div class="right">
              <div class="value">{{ utils.formatValue(localTotal[0]?.amount, 'currency') }}</div>
              <div class="icon">
                <img :class="!totalShow ? 'up' : 'down'" :src="icons.arrowUp" alt="" />
              </div>
            </div>
          </div>
          <transition name="fade">
            <div v-show="!totalShow" class="body">
              <div class="item">
                <div class="name">Портфель:</div>
                <div class="value">{{ utils.formatValue(localTotal[0]?.amount, 'currency') }}</div>
              </div>
              <div class="item">
                <div class="name">Активы:</div>
                <div class="value">{{ utils.formatValue(localTotal[0]?.assets, 'currency') }}</div>
              </div>
              <div class="item">
                <div class="name">Деньги:</div>
                <div class="value">{{ utils.formatValue(localTotal[0]?.cashes, 'currency') }}</div>
              </div>
              <div class="item">
                <div class="name">Покупательская способность:</div>
                <div class="value">{{ utils.formatValue(localTotal[0]?.bp, 'currency') }}</div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <component-position :columns="assetsDefs" :detailRows="detailRows" :positions="localAssets" @action="selectPosition" />
      <component-position :columns="cashDefs" :positions="localCash" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import utils from '@/utils';
import { icons } from '@/assets/svg-img';
// import CInput from '@/components/UI/ComponentInput.vue';
import ComponentPosition from '@/components/mobile/ComponentPosition.vue';
import PageHeader from '@/components/mobile/ComponentPageHeader.vue';

export default {
  name: 'MobilePortfolio',
  components: {
    ComponentPosition,
    // CInput,
    PageHeader,
  },
  data() {
    return {
      icons,
      utils,
      disabled: false,
      totalShow: true,
      localAssets: [],
      localCash: [],
      localTotal: [{ key: '', symbol: '', cashes: 0.0, assets: 0.0, amount: 0.0, bp: 0.0 }],
      assetsDefs: [
        { field: 'symbol', class: ['symbol', 'action'], title: 'instrument', type: 'string', search: 'text' },
        { field: 'price', class: ['price'], title: 'цена', type: 'currency' },
        { field: 'quantity', class: ['quantity'], title: 'кол-во', type: 'number' },
        { field: 'pnl', class: ['colored'], title: 'pnl', type: 'currency', colored: true },
      ],
      detailRows: [
        { field: 'amount', title: 'Стоимость', type: 'currency' },
        { field: 'purchase', title: 'Приобретение', type: 'currency' },
        { field: 'average', title: 'Средняя цена', type: 'currency' },
        // { field: 'pnl', title: 'PnL общий', type: 'currency', colored: true },
        { field: 'symbol', title: 'buy/sell', type: 'button' },
      ],
      cashDefs: [
        { field: 'symbol', class: ['symbol'], title: 'instrument', type: 'string' },
        { field: 'amount', class: ['quantity'], title: 'кол-во', type: 'currency' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getPortfolio: 'getPortfolio',
    }),
  },
  methods: {
    ...mapMutations({
      setDefaultOrder: 'setDefaultOrder',
      changeOrder: 'changeOrder',
    }),
    ...mapActions({
      updateSelectedSymbol: 'updateSelectedSymbol',
    }),
    test() {
      this.disabled = !this.disabled;
    },
    toggleTotal() {
      this.totalShow = !this.totalShow;
    },
    setPortfolio(newState) {
      // if (newState.items.length === 0) return;
      this.localAssets = [];
      this.localCash = [];
      for (const each of newState.items) {
        let item = {
          key: each.account + '_' + each.instrument.symbol,
          symbol: each.instrument.symbol,
          amount: each.price * (each.quantity + each.day.quantity),
          data: each,
        };

        if (each.instrument.asset_category === 'CASH') {
          this.localCash.push(item);
        } else {
          item.price = each.price;
          item.quantity = each.quantity + each.day.quantity;
          item.average = each.average;
          item.purchase = (item.quantity === 0.0 ? each.quantity : item.quantity) * each.average;
          // item.day = each.pnl.day;
          item.pnl = each.pnl.all;
          this.localAssets.push(item);
        }
      }
      this.localTotal = [
        {
          key: 'USD',
          symbol: 'USD',
          cashes: newState.total.cashes,
          assets: newState.total.assets,
          amount: newState.total.amount,
          bp: newState.total.bp,
        },
      ];
    },
    selectPosition(key) {
      const position = this.localAssets.find((item) => item.key.toString() === key.toString());
      this.updateSelectedSymbol(position.data.instrument.symbol);
      this.setDefaultOrder();
      this.changeOrder({ action: position.quantity > 0 ? 'sell' : 'buy', type: 'limit', quantity: position.quantity });
      this.$router.push({ name: 'order' });
    },
  },
  watch: {
    getPortfolio: {
      handler(newState) {
        this.setPortfolio(newState);
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.setPortfolio(this.getPortfolio);
  },
  unmounted() {},
};
</script>

<style lang="scss" scoped>
.total-block-wrap {
  padding: 0 15px;
  position: sticky;
  top: calc(var(--setting-line-height) + 40px);
  z-index: 5;
}

.total-block {
  padding: 8px 12px;
  border-radius: var(--main-border-radius);
  border: 1px solid var(--input-alt-default-border-outside);
  margin-bottom: 10px;
  box-shadow: 0 3px 6px var(--dropdown-alt-border-outside);
  background: rgba(30, 33, 47, 1);
  overflow: hidden;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .right {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .body {
    padding-top: 15px;
  }

  .title {
    color: rgba(23, 130, 94, 1);
    font-weight: 600;
    font-size: 16px;
  }

  .icon {
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: all 0.2s ease-in-out;

      &.down {
        transform: rotateX(180deg);
      }
    }
  }

  .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    color: rgba(255, 255, 255, 1);

    &:last-child {
      margin-bottom: 0;
    }
  }

  .value {
    font-weight: 600;
  }

  .fade-enter-active,
  .fade-leave-active {
    max-height: 230px;
    transition: max-height 0.22s linear;
  }

  .fade-enter-from,
  .fade-leave-to {
    max-height: 0;
  }
}
</style>
