export default {
  makePersonalData(data) {
    const contact = data.contact;
    const address = [];
    for (const key of ['primary_address_state', 'primary_address_city', 'primary_address_street']) {
      if (contact[key]) address.push(contact[key]);
    }
    let addressDate = null;
    const files = [];
    for (const document of data.contact.documents) {
      if (document.type_document === 'clients_address' && addressDate === null) addressDate = document.active_date;
      files.push({
        status: document.status_id,
        documentType: document.type_document,
        name: document.document_name,
        url: document.document_revision_id,
        type: document.revisions.file_mime_type,
        lastModified: document.revisions.date_modified,
        lastModifiedDate: new Date(document.revisions.date_modified),
      });
    }
    const result = {
      form: {
        fio: contact.full_name,
        sex: contact.salutation,
        birthdate: contact.birthdate,
        birthplace: contact.birthplace,
        email: contact.email,
        citizenship: contact.citizenship,
        identity: contact.identity,
        identityType: contact.identity_type,
        issued: contact.issued,
        issuedDate: contact.issued_date,
        expDate: contact.exp_date ?? null,
        taxCountry: contact.tax_country,
        postalcode: contact.primary_address_postalcode,
        addressDate,
        address: address.join(', '),
        usTaxResident: 'no',
        agreement: {
          contract: false,
          doRight: false,
          updating: false,
          nominal: false,
        },
      },
      options: {
        status: contact.contact_status || null,
      },
      files,
    };
    return result;
  },
};
