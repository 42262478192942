<template>
  <div class="chart-setting">
    <div class="wrap">
      <div class="item" @click="showBlock">
        <div class="icon-chart-setting">
          <img v-if="localChartType" :src="getImageForChartType(localChartType)" alt="Selected Candle Type" />
        </div>
        <div class="block-wrap">
          <div class="block">
            <div class="close" @click.stop="close">
              <img :src="icons.cross" alt="" />
            </div>
            <div
              v-for="{ value, text, img } in chartTypesArray"
              :key="value"
              :class="{ active: localChartType === value, 'chart-type-item': true }"
            >
              <div class="icon"><img :src="img" alt="" /></div>
              {{ text }}
              <input v-model="localChartType" :value="value" name="type-chart" type="radio" />
            </div>
          </div>
        </div>
      </div>

      <div v-show="!isMobile" class="item" @click="showBlock">
        <div class="icon-chart-setting"><img :src="icons.indicator" alt="" /></div>
        <div class="block-wrap">
          <div class="block">
            <div class="close" @click.stop="close">
              <img :src="icons.cross" alt="" />
            </div>
            <div class="title">Main indicators</div>
            <div v-for="indicator in mainIndicatorsArray" :key="indicator.name" class="custom-radio main-indicator">
              <label @click.stop="toggleIndicator(indicator)">
                <div>{{ indicator.name }}</div>
                <div class="indicator"><img v-show="isIndicatorActive(indicator)" :src="icons.check" alt="" /></div>
              </label>
            </div>
            <div class="title">Sub indicators</div>
            <div v-for="{ value, text } in subIndicatorsArray" :key="value" class="custom-radio sub-indicator">
              <label>
                {{ text }}
                <input v-model="localChartSubIndicator" :value="value" name="chart-subIndicator" type="radio" />
                <div class="indicator"><img :src="icons.check" alt="" /></div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="chart-time-update">
        <c-select v-model="localTimeFrameSelected" :options="time" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { icons } from '@/assets/svg-img';
import CSelect from '@/components/UI/ComponentSelect.vue';

export default {
  name: 'ChartSetting',
  components: { CSelect },
  data: function () {
    return {
      icons,
      localTimeUpdate: null,
      localChartType: null,
      localChartMainIndicators: [],
      localChartSubIndicator: null,
      localTimeFrameSelected: null,
      time: [
        { value: 300, text: '5 M' },
        { value: 900, text: '15 M' },
        // { value: 1800, text: '30 M' },
        { value: 3600, text: '1 H' },
        { value: 86400, text: '1 D' },
        { value: 604800, text: '1 W' },
        // { value: 2592000, text: '1 M' },
      ],
      chartTypesArray: [
        { value: 'candle_solid', text: 'Залитые свечи', img: icons.solidCandle },
        { value: 'candle_stroke', text: 'Пустые свечи', img: icons.strokeCandle },
        // { value: 'candle_up_stroke', text: 'Up stroke', img: cross },
        // { value: 'candle_down_stroke', text: 'Uown stroke', img: 'img4' },
        { value: 'ohlc', text: 'Бары', img: icons.bar },
        { value: 'area', text: 'Область', img: icons.area },
      ],
      mainIndicatorsArray: [
        { value: 'MA', name: 'MA' },
        { value: 'EMA', name: 'EMA' },
        { value: 'SAR', name: 'SAR' },
        { value: 'BOLL', name: 'BOLL' },
      ],
      subIndicatorsArray: [
        { value: 'VOL', text: 'VOL' },
        { value: 'MACD', text: 'MACD' },
        { value: 'KDJ', text: 'KDJ' },
      ],
      timeUpdateTerminalArray: [
        { value: '5', text: '5 секунд' },
        { value: '10', text: '10 секунд' },
        { value: '15', text: '15 секунд' },
        { value: '30', text: '30 секунд' },
        { value: '60', text: '1 минута' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      chartType: 'getChartType',
      timeUpdateTerminal: 'getTimeUpdateTerminal',
      chartMainIndicators: 'getChartMainIndicators',
      chartSubIndicator: 'getChartSubIndicator',
      timeFrameSelected: 'getTimeFrameSelected',
      isMobile: 'isMobile',
    }),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      pullSettings: 'pullSettings',
      updateSettings: 'updateSettings',
    }),
    addActiveClass(e) {
      let all = document.querySelectorAll('.buttons-wrap button');
      all.forEach(function (o) {
        o.className = '';
      });
      e.target.className = e.target.className === 'active' ? '' : 'active';
    },
    getImageForChartType(chartType) {
      const selectedChart = this.chartTypesArray.find((item) => item.value === chartType);
      return selectedChart ? selectedChart.img : '';
    },
    toggleIndicator(indicator) {
      const index = this.localChartMainIndicators.findIndex((item) => item.name === indicator.name);
      index === -1 ? this.localChartMainIndicators.push(indicator) : this.localChartMainIndicators.splice(index, 1);
      this.updateSettings({ setting: { chart: { main: this.localChartMainIndicators } }, update: true });
    },
    isIndicatorActive(indicator) {
      return this.localChartMainIndicators.some((item) => item.name === indicator.name);
    },
    showBlock(e) {
      let block = e.currentTarget.querySelector('.block');
      let blocks = document.querySelectorAll('.wrap .block');
      blocks.forEach(function (block) {
        block.classList.remove('show');
      });

      block.classList.add('show');
    },
    close(e) {
      e.currentTarget.parentNode.classList.remove('show');
    },
  },
  watch: {
    timeUpdateTerminal(newState) {
      this.localTimeUpdate = newState;
    },
    localTimeUpdate(newState) {
      if (this.timeUpdateTerminal !== newState)
        this.updateSettings({
          setting: { main: { refresh: newState } },
          update: true,
        });
    },
    chartType(newState) {
      this.localChartType = newState;
    },
    localChartType(newState) {
      if (this.chartType !== newState) this.updateSettings({ setting: { chart: { type: newState } }, update: true });
    },
    chartMainIndicators(newState) {
      this.localChartMainIndicators = newState;
    },
    chartSubIndicator(newState) {
      this.localChartSubIndicator = newState;
    },
    localChartSubIndicator(newState) {
      if (this.chartSubIndicator !== newState)
        this.updateSettings({
          setting: { chart: { sub: newState } },
          update: true,
        });
    },
    timeFrameSelected(newState) {
      this.localTimeFrameSelected = newState;
    },
    localTimeFrameSelected(newState) {
      if (this.timeFrameSelected !== newState)
        this.updateSettings({
          setting: { chart: { timeframe: newState } },
          update: true,
        });
    },
  },
  async created() {
    this.localTimeFrameSelected = this.timeFrameSelected;
  },
  mounted() {
    this.localChartType = this.chartType;
    this.localChartMainIndicators = this.chartMainIndicators;
    this.localChartSubIndicator = this.chartSubIndicator;
    this.localTimeUpdate = this.timeUpdateTerminal;
  },
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.custom-radio {
  margin-right: 8px;

  label {
    position: relative;
    color: var(--white);
    font-size: 11px;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;

    input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
    }

    @include breakpoint-down(md) {
      font-size: 14px;
    }

    &:hover {
      .indicator {
        border: 1px solid var(--input-hovered-border-outside-color);
      }
    }
  }
}

.wrap {
  display: flex;

  .item {
    margin-right: 10px;
    position: relative;

    span {
      cursor: pointer;
    }

    .title {
      margin-bottom: 6px;
      font-weight: 600;
      color: #7d86b2;
    }

    &:last-child {
      margin-right: 0;
    }

    .block-wrap {
      position: absolute;
      top: 100%;
      min-width: 200px;
      z-index: 21;
      border-radius: var(--main-border-radius);
      background: linear-gradient(to bottom, var(--app-bg-grad-top-color), var(--app-bg-grad-bottom-color));
    }

    .block {
      border-color: var(--dropdown-list-border-color);
      background: linear-gradient(to bottom, var(--widget-bg-top-color), var(--widget-bg-bottom-color));
      color: var(--dropdown-list-text-color);
      box-shadow: 0 2px 10px 0 var(--overlay-box-shadow-color);
      padding: 30px 10px 10px;
      display: none;

      &.show {
        display: block;
      }

      .close {
        width: 15px;
        height: 15px;
        position: absolute;
        top: 12px;
        right: 12px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.main-indicator {
  .indicator {
    width: 14px;
    height: 14px;
    background-color: rgb(130 136 201 / 37%);
    border: solid 1px var(--table-header-shadow-color);
    margin-left: 4px;
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border 0.2s ease-in-out;
  }
}

.sub-indicator {
  input:checked + .indicator {
    img {
      opacity: 1;
    }
  }

  .indicator {
    width: 14px;
    height: 14px;
    background-color: rgb(130 136 201 / 37%);
    border: solid 1px var(--table-header-shadow-color);
    margin-left: 4px;
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border 0.2s ease-in-out;
    @include breakpoint-down(md) {
      width: 22px;
      height: 22px;
    }

    img {
      opacity: 0;
      transition: all 0.2s ease;
    }
  }
}

.icon-chart-setting {
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: var(--main-border-radius);
  border: solid 1px var(--table-totals-divider-line-color);
  transition: all 0.3s ease-in-out;
  box-shadow: 0 1px 1px var(--table-header-shadow-color);
  background: var(--input-alt-default-bg);

  &:hover {
    box-shadow: -1px 1px 3px var(--table-header-shadow-color);
    transform: translate(0, -1px);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.2s ease-in-out;
  }
}

.chart-type-item {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
  transition: opacity 0.2s ease-in-out;
  opacity: 0.6;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover,
  &.active {
    opacity: 1;

    .icon {
      img {
        transform: scale(1.2);
      }
    }
  }

  .icon {
    width: 25px;
    height: 25px;
    margin-right: 6px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.1s ease-in-out;
    }
  }

  input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    cursor: pointer;
    opacity: 0;
  }
}

.chart-time-update {
  height: 30px;
  cursor: pointer;
  border-radius: var(--main-border-radius);
  transition: all 0.3s ease-in-out;
  box-shadow: 0 1px 1px var(--table-header-shadow-color);
  background: var(--input-alt-default-bg);

  &:deep(.custom-select) {
    height: 100%;

    .custom-select-wrap {
      height: 100%;
    }

    @include breakpoint-down(md) {
      .select-header {
        height: 100%;
      }
    }
  }

  select {
    height: 30px;
    cursor: pointer;
  }
}
</style>
