<template>
  <div class="options-component">
    <div style="margin-bottom: 10px">Опционы {{ selectedSymbol }}</div>
    <div class="filters">
      <!--
      <div class="filters__item">
        <div class="filters__item-title">Volume:</div>
        <c-select v-model="localVolume" :options="volume" />
      </div>
      -->
      <!--
      <div class="filters__item">
        <div class="filters__item-title">expiration Type:</div>
        <c-select v-model="localExpirationType" :options="expirationType" />
      </div>
      -->
      <!--
      <div class="filters__item">
        <div class="filters__item-title">Options Range:</div>
        <c-select v-model="localOptionsRange" :options="optionsRange" />
      </div>
      -->
      <!--
      <div class="filters__item">
        <div class="filters__item-title">Date:</div>
        <c-select v-model="existDates.selected" :options="existDates.list" />
      </div>
      -->
      <div class="filters__item">
        <div class="filters__item-title">Expiration:</div>
        <c-select v-model="localExpirations.selected" :options="localExpirations.list" @change="getOptons" />
      </div>
      <div class="filters__item">
        <div class="refresh filters__item-title">
          <div class="icon" @click="refresh">
            <img :class="[waiting === null ? '' : 'rotation']" :src="icons.refresh" alt="" />
          </div>
          Обновить
        </div>
      </div>
    </div>
    <div class="charts">
      <div v-for="chart in charts" :key="chart.name" class="charts__item">
        <c-o-i-chart :lines="chart.data" :title="chart.name" />
      </div>
    </div>
    <div class="options-tables-wrap">
      <div class="options-tables__item left">
        <div class="title">Calls</div>
        <c-table :columnDefs="columns.calls" :rows="data.calls" />
      </div>
      <div class="options-tables__item">
        <c-table :columnDefs="columns.strike" :rows="data.strikes" />
      </div>
      <div class="options-tables__item right">
        <div class="title">Puts</div>
        <c-table :columnDefs="columns.puts" :rows="data.puts" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { icons } from '@/assets/svg-img';
import utils from '@/utils';
import CTable from '@/components/UI/ComponentTable';
import COIChart from '@/components/UI/ComponentOptionInfoChart';
import CSelect from '@/components/UI/ComponentSelect.vue';

function initialData() {
  return JSON.parse(JSON.stringify({ puts: [], calls: [], strikes: [] }));
}

function initialExpirations() {
  return JSON.parse(JSON.stringify({ selected: null, list: [{ value: null, text: 'Пусто' }] }));
}

function initialChart({ name, type }) {
  return JSON.parse(
    JSON.stringify({
      name,
      data: { lines: [], axisX: { name: 'strike', type: 'int' }, axisY: { name, type } },
    }),
  );
}

export default {
  name: 'OptionsComponent',
  components: {
    CSelect,
    CTable,
    COIChart,
  },
  data() {
    return {
      icons,
      columns: {
        calls: [
          { name: 'Код', field: 'symbol_raw', type: 'string', flex: 8 },
          // { name: 'Покупка', field: 'bid', type: 'string', flex: 5 },
          // { name: 'Продажа', field: 'ask', type: 'string', flex: 5 },
          { name: 'VI', field: 'volatility', type: 'number', flex: 5 },
          { name: 'Delta', field: 'delta', type: 'number', flex: 3 },
          { name: 'Gamma', field: 'gamma', type: 'number', flex: 3 },
          { name: 'Vega', field: 'vega', type: 'number', flex: 3 },
          { name: 'Theta', field: 'theta', type: 'number', flex: 3 },
          { name: 'Теоретическая цена', field: 'theo', type: 'currency', flex: 5 },
          { name: 'Interest', field: 'open_interest', type: 'number', flex: 5 },
        ],
        strike: [{ name: 'Страйк', field: 'strike', type: 'string', flex: 1 }],
        puts: [
          { name: 'Interest', field: 'open_interest', type: 'number', flex: 5 },
          { name: 'Теоретическая цена', field: 'theo', type: 'currency', flex: 5 },
          { name: 'Theta', field: 'theta', type: 'number', flex: 3 },
          { name: 'Vega', field: 'vega', type: 'number', flex: 3 },
          { name: 'Gamma', field: 'gamma', type: 'number', flex: 3 },
          { name: 'Delta', field: 'delta', type: 'number', flex: 3 },
          { name: 'VI', field: 'volatility', type: 'number', flex: 5 },
          // {
          //   name: 'Продажа',
          //   field: [
          //     { field: 'ask', type: 'currency' },
          //     { field: 'ask_size', type: 'number' },
          //   ],
          //   type: 'composite',
          //   flex: 5,
          // },
          // {
          //   name: 'Покупка',
          //   field: [
          //     { field: 'bid', type: 'currency' },
          //     { field: 'bid_size', type: 'number' },
          //   ],
          //   type: 'composite',
          //   flex: 5,
          // },
          { name: 'Код', field: 'symbol_raw', type: 'string', flex: 8 },
        ],
      },
      data: initialData(),
      existDates: {
        selected: null,
        list: [{ value: null, text: 'Пусто' }],
      },
      waiting: null,
      // localVolume: 'all',
      // volume: [
      //   { value: 'all', text: 'All' },
      //   { value: 'ghenerator than 0', text: 'Ghenerator than 0' },
      // ],
      // localExpirationType: 'all',
      // expirationType: [
      //   { value: 'all', text: 'All' },
      //   { value: 'standart', text: 'Standart' },
      //   { value: 'W/Q/M', text: 'Weekly/Quarterly/Monthly' },
      // ],
      // localOptionsRange: 'all',
      // optionsRange: [
      //   { value: 'all', text: 'All' },
      //   { value: 'NeartheMoney', text: 'Near the Money' },
      // ],
      // localSize: 1,
      // size: [
      //   { value: 1, text: '1' },
      //   { value: 2, text: '2' },
      //   { value: 3, text: '3' },
      //   { value: 4, text: '4' },
      //   { value: 5, text: '5' },
      //   { value: 6, text: '6' },
      //   { value: 7, text: '7' },
      //   { value: 8, text: '8' },
      //   { value: 9, text: '9' },
      //   { value: 10, text: '10' },
      // ],
      localExpirations: initialExpirations(),
      charts: {},
      // volatility: initialVolatility(),
      // payments: initialPayments(),
    };
  },
  computed: {
    ...mapGetters({
      selectedSymbol: 'getSelectedSymbol',
      expirations: 'getExperations',
      options: 'getOptions',
    }),
    utils() {
      return utils;
    },
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      pullExpirations: 'pullExpiration',
      pullOptions: 'pullOptions',
      updateOptions: 'updateOptions',
    }),
    async refresh() {
      this.waiting = this.localExpirations.selected;
      // e.target.classList.add('rotation');
      await this.updateOptions(this.selectedSymbol);
      // e.target.classList.remove('rotation');
    },
    chartsClear() {
      this.charts['volatility'] = initialChart({ name: 'volatility', type: 'float' });
      this.charts['delta'] = initialChart({ name: 'delta', type: 'float' });
      this.charts['theta'] = initialChart({ name: 'theta', type: 'float' });
      this.charts['vega'] = initialChart({ name: 'vega', type: 'float' });
      this.charts['payment'] = initialChart({ name: 'payment', type: 'float' });
    },
    fillArrays(newState) {
      this.data = initialData();
      this.chartsClear();
      const set = new Set();
      for (const each of newState) {
        set.add(each.strike);
        each.symbol = each.symbol_raw;
        each.type === 'C' ? this.data.calls.push(each) : this.data.puts.push(each);
      }
      this.charts['volatility'].data.lines.push({ name: 'calls', data: this.data.calls }, { name: 'puts', data: this.data.puts });
      this.charts['delta'].data.lines.push({ name: 'calls', data: this.data.calls }, { name: 'puts', data: this.data.puts });
      this.charts['theta'].data.lines.push({ name: 'calls', data: this.data.calls }, { name: 'puts', data: this.data.puts });
      this.charts['vega'].data.lines.push({ name: 'calls', data: this.data.calls }, { name: 'puts', data: this.data.puts });
      const payments = { calls: [], puts: [], total: [] };
      for (const strike of set) {
        const callsP = this.calcCalls(strike);
        const putsP = this.calcPuts(strike);
        payments.calls.push({ payment: callsP, strike });
        payments.puts.push({ payment: putsP, strike });
        payments.total.push({ payment: callsP + putsP, strike });

        this.data.strikes.push({ strike: strike });
      }
      this.charts['payment'].data.lines.push(
        { name: 'calls', data: payments.calls },
        { name: 'puts', data: payments.puts },
        { name: 'total', data: payments.total },
      );
    },
    getExperations() {
      // console.log('getExperations');
      if (!this.selectedSymbol) return;
      this.pullExpirations({ symbol: this.selectedSymbol });
    },
    getOptons() {
      if (this.waiting !== this.localExpirations.selected) {
        this.waiting = this.localExpirations.selected;
        this.data = initialData();
        this.chartsClear();
        this.pullOptions({ symbols: [this.selectedSymbol], expirations: [this.localExpirations.selected] });
      }
    },
    calcCalls(strike) {
      let amount = 0.0;
      for (let i = 0; i < this.data.calls.length; i++) {
        if (parseInt(this.data.calls[i].strike) < parseInt(strike)) {
          amount += (this.data.calls[i].open_interest * (parseInt(strike) - parseInt(this.data.calls[i].strike))) / 10;
        } else {
          return amount;
        }
      }
      return amount;
    },
    calcPuts(strike) {
      let amount = 0.0;
      for (let i = this.data.puts.length - 1; i >= 0; i--) {
        if (parseInt(this.data.puts[i].strike) > parseInt(strike)) {
          amount += (this.data.puts[i].open_interest * (parseInt(this.data.puts[i].strike) - parseInt(strike))) / 10;
        } else {
          return amount;
        }
      }
      return amount;
    },
  },

  watch: {
    // selectedSymbol(newSymbol) {
    //   if (newSymbol) {
    //     this.getExperations();
    //   }
    // },
    expirations: {
      handler(newState) {
        this.localExpirations = initialExpirations();
        if (newState.length > 0) {
          this.localExpirations.list = [];
          newState.sort((a, b) => (new Date(a) > new Date(b) ? 1 : new Date(a) < new Date(b) ? -1 : 0));
          for (const date of newState) {
            this.localExpirations.list.push({ value: date, text: utils.getLocaleDateString(date) });
          }
          this.localExpirations.selected = this.localExpirations.list[0].value;
          this.getOptons();
        }
      },
      deep: true,
    },
    options: {
      handler(newState) {
        if (newState.length > 0) {
          this.fillArrays(newState);
          this.waiting = null;
        }
      },
      deep: true,
    },
    // localExpirations(newState, oldState) {
    //   console.log(JSON.stringify(newState.selected), JSON.stringify(oldState.selected));
    //   // if (newState.selected !== oldState.selected)
    //   this.pullOptions();
    // },
  },
  created() {
    this.getExperations();
    // this.getOptons();
  },
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
.options-component {
  .filters {
    display: flex;
    align-items: flex-end;
    margin-bottom: 30px;
    gap: 30px;

    &__item {
      flex: 1;
    }

    &__item-title {
      margin-bottom: 4px;
    }
  }

  .charts {
    display: flex;
    gap: 15px;
    margin-bottom: 30px;

    &__item {
      width: 300px;
      height: 300px;
    }
  }

  .options-tables {
    &-wrap {
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }

    &__item {
      margin: 2px;

      &.left,
      &.right {
        flex: 2;
      }

      .title {
        margin-bottom: 5px;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  .refresh {
    display: flex;
    font-size: 12px;

    .icon {
      width: 30px;
      height: 30px;
      border-radius: var(--main-border-radius);
      border: solid 1px var(--table-totals-divider-line-color);
      transition: all 0.3s ease-in-out;
      box-shadow: 0 1px 1px var(--table-header-shadow-color);
      background: var(--input-alt-default-bg);
      margin-right: 4px;
      padding: 6px;
      flex-shrink: 0;
      cursor: pointer;

      &:hover {
        box-shadow: -1px 1px 3px var(--table-header-shadow-color);
        transform: translate(0, -1px);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.2s ease-in-out;

        &.rotation {
          animation-name: rotate;
          animation-duration: 2s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }

        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}
</style>
