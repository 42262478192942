<template>
  <div class="option-chart-wrap">
    <div class="title">
      {{ title }}
    </div>
    <div v-show="tooltipVisible" class="tooltip">
      <div v-if="info.strike">Strike: {{ info.strike }}</div>
      <div class="wrap">
        <div v-for="each in info.lines" :key="each.name" :style="'color:' + each.color">{{ each.name }}: {{ each.value }}</div>
      </div>
    </div>
    <canvas ref="chart" @mouseleave="handleMouseLeave" @mousemove="handleMouseMove" />
  </div>
</template>

<script>
import utils from '@/utils';

function initialInfo() {
  return JSON.parse(JSON.stringify({ strike: '', lines: [] }));
}

export default {
  name: 'ComponentChart',
  emits: [],
  data() {
    return {
      ctx: null,
      axisX: {},
      axisY: {},
      points: [],
      border: 10,
      colors: ['red', 'rgba(119, 119, 252, 0.8)', 'green'],
      tooltipContent: '',
      tooltipVisible: false,
      info: initialInfo(),
    };
  },
  props: {
    title: { type: String, default: () => '' },
    name: { type: String, default: () => '' },
    lines: { type: Object, required: true },
  },
  methods: {
    handleMouseLeave() {
      this.tooltipVisible = false;
    },
    handleMouseMove(e) {
      const rect = this.$refs.chart.getBoundingClientRect();
      const x = e.clientX - rect.left;
      // const y = e.clientY - rect.top;

      const dataX = this.nearestAxisX(x);
      this.$refs.chart.style.cursor = 'crosshair';
      if (dataX) {
        this.tooltipVisible = true;
        this.tooltipContent = this.formatTooltipContent(dataX);
      }
    },
    formatTooltipContent(data) {
      // Форматирование данных для отображения в tooltip
      if (Object.keys(data).length > 0) {
        this.info.strike = data[this.axisX.name];
        for (const index in this.lines.lines) {
          const infoLine = this.info.lines.find((each) => each.name === this.lines.lines[index].name);
          const pointY = this.lines.lines[index].data.find((each) => each[this.axisX.name] === data[this.axisX.name]);
          infoLine.value = pointY[this.axisY.name];
        }
      }
    },
    nearestAxisX(x) {
      let minDistance = Infinity;
      let result = {};

      if (this.points.length === 0 && this.lines.lines.length !== 0) this.points = this.getPoints(this.lines.lines[0].data);
      for (const point of this.points) {
        const distance = Math.sqrt((x - point.x) ** 2);
        if (distance < minDistance) {
          result = point.data; // Сохраняем данные точки
          minDistance = distance;
        }
      }
      return result;
    },
    preparation() {
      this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
      this.ctx.lineWidth = 2;
      this.ctx.beginPath();
      // this.ctx.moveTo(20, this.ctx.canvas.height - 20);
      // this.ctx.lineTo(this.ctx.canvas.width - 20, this.ctx.canvas.height - 20);
      // this.ctx.stroke();

      // this.ctx.lineWidth = 2;
      // this.ctx.beginPath();
      // this.ctx.moveTo(20, 20);
      // this.ctx.lineTo(20, this.ctx.canvas.height - 20);
      this.ctx.stroke();
    },
    curve(line, color) {
      this.points = this.getPoints(line.data);
      this.info.lines.push({ name: line.name, color, value: '' });
      if (this.points.length === 0) return;
      this.ctx.strokeStyle = color;
      this.ctx.lineWidth = 2;

      this.ctx.beginPath();
      this.ctx.moveTo(this.points[0].x, this.points[0].y);

      for (let i = 1; i < this.points.length - 1; i++) {
        const xc = (this.points[i].x + this.points[i + 1].x) / 2;
        const yc = (this.points[i].y + this.points[i + 1].y) / 2;
        this.ctx.quadraticCurveTo(this.points[i].x, this.points[i].y, xc, yc);
      }
      this.ctx.lineTo(this.points.at(-1).x, this.points.at(-1).y);

      this.ctx.stroke();
    },
    getPoints(line) {
      const rangeX = utils.findMinMax(line, this.axisX.name, this.axisX.type);
      const stepX = (this.ctx.canvas.width - this.border * 2) / (rangeX.max - rangeX.min);
      const rangeY = utils.findMinMax(line, this.axisY.name, this.axisY.type);
      const stepY = (this.ctx.canvas.height - this.border * 2) / (rangeY.max - rangeY.min);
      const result = [];
      for (let i = 0; i < line.length; i++) {
        if (line[i][this.axisY.name] !== null) {
          result.push({
            x: this.border + (utils.typing(line[i][this.axisX.name], this.axisX.type) - rangeX.min) * stepX,
            y: this.ctx.canvas.height - this.border - (utils.typing(line[i][this.axisY.name], this.axisY.type) - rangeY.min) * stepY,
            data: line[i],
          });
        }
      }
      return result;
    },
    chart() {
      this.preparation();
      this.info = initialInfo();
      this.axisX = this.lines.axisX;
      this.axisY = this.lines.axisY;
      for (const index in this.lines.lines) {
        this.curve(this.lines.lines[index], this.colors[index]);
      }
    },
  },
  watch: {
    lines() {
      this.chart();
    },
  },
  mounted() {
    this.ctx = this.$refs.chart.getContext('2d');
    this.chart();
  },
};
</script>

<style lang="scss" scoped>
.option-chart-wrap {
  position: relative;
  height: 100%;
  width: 100%;
  border: 1px solid var(--input-alt-default-border-outside);
  border-radius: var(--main-border-radius);
  box-shadow: 0 2px 10px 0 var(--overlay-box-shadow-color);

  .title {
    text-transform: capitalize;
    background: var(--table-header-bg-color);
    box-shadow: -1px 1px 3px var(--table-header-shadow-color);
    color: var(--table-column-text-header-color);
    padding: 5px 10px;
    height: 28px;
  }

  canvas {
    display: block;
    height: calc(100% - 28px);
    width: 100%;
  }

  .tooltip {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.25);
    padding: 5px;
    color: #fff;
    text-transform: capitalize;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 28px;
    font-weight: 600;
    font-size: 12px;
    backdrop-filter: blur(3px);

    .wrap {
      display: flex;
      gap: 4px;
      font-size: 10px;
    }
  }
}
</style>
