<template>
  <div>
    <div @click="createPrintPage('<h1>Print me title</h1>')">333333</div>
    <div ref="printContent" hidden="">
      <div class="contract" style="width: 200mm; margin: 0 auto; padding: 20mm; height: 297mm">
        <div class="contract__header" style="display: flex; justify-content: space-between">
          <div>
            <div class="logo" style="width: 130px">
              <img :src="img.logoOriginal" alt="" />
            </div>
            <div class="company-name">Partner Finance Limited</div>

            <div>
              BIN 201140900123 / AFSA-A-LA-2020-0042<br />
              Z05K7P2, Казахстан, Астана,<br />
              ул. Туркистан 8/2, этаж 4, офис 1<br />
              тел.: +7 727 346 90 25<br />
              e-mail: info@ptfin.kz<br />
              No 015______ от 30.11.2023______
            </div>
          </div>

          <div class="bank-name">
            Филиал АО «Bereke Bank»<br />
            в г. Алматы
          </div>
        </div>
        <div class="contract__body">
          <p style="text-align: center">Уведомление</p>
          <p>
            Partner Finance Limited (Управляющий) обладает лицензией AFSA-A-LA-2020-0042 условия получения и использования которой
            направлены на защиту интересов Клиентов. Для исполнения данной цели Управляющему необходимо всесторонне понять Клиента, характер
            его бизнеса, источник средств и иметь полное понимание его инвестиционных целей, финансового положения и инвестиционного опыта.
          </p>
          <p>
            В соответствии с политикой, процедурами и программами по противодействию отмыванию денежных средств, Клиент подтверждает, что
            все заявления, сделанные в данном Соглашении, и вся информация, предоставленная Управляющему в документальной или иной форме,
            является полной, правдивой и точной. Информация предоставляется по инициативе Клиента, и он соглашается нести за нее полную
            ответственность и не возражает против того, что информация собирается для подтверждения его деловой репутации в соответствии с
            практикой, применяемой на международных рынках ценных бумаг.
          </p>
          <p>
            Непредоставление информации, запрошенной в данном Соглашении, лишает Управляющего возможности правильно оценить пригодность
            запрашиваемых услуг, что приведёт к невозможности предоставлять услуги Клиенту.
          </p>
          <p>
            Информация, представленная в этом Соглашении, должна оставаться конфиденциальной и не должна раскрываться Управляющим третьей
            стороне без согласия Клиента, за исключением случаев, когда это явным образом требуется в соответствии с применимыми законами и
            правилами. Клиент соглашается и признает, что, если требуется раскрыть любую такую информацию, Управляющий должен без
            дополнительного согласия Клиента предоставлять информацию только в той степени, которая требуется соответствующими правилами, и
            только лицу, указанному в таких правилах.
          </p>
        </div>
        <div class="contract__footer">
          <div
            class="contract__footer-bottom"
            style="padding: 20px 0 60px; border-top: solid 1px rgba(125, 134, 178, 0.29); text-align: center; font-size: 12px"
          >
            www.ptfin.kz | info@ptfin.kz
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { img } from '@/assets/svg-img';

export default {
  data() {
    return {
      img,
    };
  },
  methods: {
    createPrintPage() {
      const printContent = this.$refs.printContent.innerHTML;
      const styles = `
        @page {
          size: A4;
          margin: 20mm;
        }
        @media print {
          body {
            font-family: Montserrat, sans-serif;
            margin: 0;
            padding: 0;
          }
          .contract {
            width: 210mm;
            height: 297mm;
            background: #fff;
            color: #000;
            page-break-inside: avoid;
          }
          .logo {
            width: 130px;
          }
          .company-name, .bank-name {
            font-weight: 600;
          }
          .contract__body {
            padding-top: 100px;
            margin-bottom: 100px;
          }
          .contract__footer {
            position: fixed;
            bottom: 0;
            width: 100%;
          }
          .contract__footer-bottom {
            padding: 20px 0 20px;
            border-top: solid 1px rgba(125, 134, 178, 0.29);
            text-align: center;
            font-size: 12px;
          }
        }
      `;
      const w = window.open('', '_blank');
      w.document.write(`
        <html>
          <head>
            <style>${styles}</style>
          </head>
          <body>${printContent}</body>
        </html>
      `);
      w.document.close();
      w.focus();
      w.print();
    },
  },
};
</script>

<style lang="scss" scoped>
.contract {
  background: #fff;
  color: #000;
  width: 200mm;
  margin: 0 auto;

  .logo {
    width: 130px;
  }

  .company-name {
    font-weight: 600;
  }

  .bank-name {
    font-weight: 600;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__body {
    min-height: calc(100vh - 200px);
    margin-bottom: 200px; /* Space for footer */
  }

  &__footer {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  &__footer-bottom {
    padding: 20px 0 60px;
    border-top: solid 1px rgba(125, 134, 178, 0.29);
    text-align: center;
    font-size: 12px;
  }
}
</style>
