<template>
  <div v-if="toasts.length > 0" class="notify-wrap">
    <div v-for="(toast, index) in toasts" :key="toast.id">
      <Toast :data-index="index" :duration="toast.duration" :message="toast.text" :type="toast.type" @close="detouch(index)" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Toast from '@/components/UI/ComponentToast.vue';

export default {
  name: 'NotificationComponent',
  components: { Toast },
  data() {
    return {
      toasts: [],
    };
  },
  computed: {
    ...mapGetters({
      notifications: 'getNotifications',
    }),
  },
  methods: {
    ...mapMutations({
      cleanNotification: 'cleanNotification',
    }),
    detouch(index) {
      this.toasts.splice(index, 1);
    },
  },
  watch: {
    notifications: {
      handler(newState) {
        if (newState.length > 0) {
          for (const notification of newState) {
            const id = Date.now() + Math.random();
            this.toasts.push({ ...notification, id });
          }
          this.cleanNotification();
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.notify-wrap {
  position: absolute;
  top: 20px;
  right: 10px;
}
</style>
