<template>
  <div class="page-header">
    <div v-show="title !== 'Портфель'" class="back" @click="goBack">
      <div class="icon"><img :src="icons.left" alt="" /></div>
      Назад
    </div>
    <div class="left">
      <slot name="left"></slot>
    </div>
    <div class="title">{{ title }}</div>
    <div class="right">
      <slot name="right"></slot>
    </div>
  </div>
</template>
<script>
import { icons } from '@/assets/svg-img';

export default {
  name: 'ComponentPageHeader',
  components: {},
  data() {
    return { icons };
  },
  computed: {},
  props: {
    title: {
      type: String,
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.page-header {
  height: 40px;
  position: sticky;
  top: var(--setting-line-height);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  z-index: 11;
  margin-bottom: 5px;

  .left {
    position: absolute;
    left: 0;
  }

  .right {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0px, -50%);
  }

  .title {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
  }

  .back {
    color: rgba(125, 134, 178, 1);
    display: flex;
    align-items: center;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0px, -50%);

    .icon {
      height: 25px;
      width: 30px;
      display: flex;
      align-items: center;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
