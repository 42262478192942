<template>
  <div class="confirms">
    <div hidden>
      The Client hereby confirms that:
      <p>
        agrees to and accepts the above terms and joins the Investment Management Agreement posted on the Managers's website
        https://ptfin.kz, expressing full agreement with its terms;
      </p>
      <p>
        understood all the above Questionnaire questions and filled out the answers on his own initiative. The information provided in the
        Questionnaire is correct;
      </p>
      <p>
        undertakes to inform the Manager as soon as there is any change in the provided above information; undertakes to inform the Manager
        as soon as there is any change in the provided above information;
      </p>
      <p>
        if no additional information is provided, the Client confirms that he is the owner of the funds that are in his accounts and also
        confirms that he does not conduct any operations on his accounts on behalf of other persons, and (or) does not store funds of other
        persons in these accounts; if no additional information is provided, the Client confirms that he is the owner of the funds that are
        in his accounts and also confirms that he does not conduct any operations on his accounts on behalf of other persons, and (or) does
        not store funds of other persons in these accounts;
      </p>
      <p>
        understands that if the answers do not reflect the real situation of the personal or financial situation, or the Client is unable to
        inform the Manager about any changes in a timely manner, he may be exposed to a risk exceeding his permissible degree of risk or his
        financial capabilities.
      </p>
    </div>
    <div>
      Настоящим Клиент подтверждает, что:
      <p>
        согласен и принимает вышеуказанные условия и присоединяется к Договору на управление инвестициями, размещенному на сайте
        Управляющего https://ptfin.kz, выражая полное согласие с его условиями;
      </p>
      <p>понял все вышеуказанные вопросы и заполнил ответы по своей инициативе. Информация, представленная в Соглашении, корректна;</p>
      <p>обязуется информировать Управляющего как только произойдет какое-либо изменение в представленной выше информации;</p>
      <p>
        если дополнительная информация не предоставлена, Клиент подтверждает, что является собственником средств, которые находятся на его
        счетах, а также подтверждает, что не проводит никаких операций на своих счетах от имени других лиц и (или) не хранит на этих счетах
        средства других лиц;
      </p>
      <p>
        понимает, что в случае если ответы не отражают реальную ситуацию личного или финансового положения или Клиент не сможет своевременно
        проинформировать Управляющего о любых изменениях, он может подвергнуться риску, превышающему его допустимую степень риска или его
        финансовые возможности.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'RulesQuestionnaire',
  emits: [],
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({}),
  },
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
.confirms {
  margin-bottom: 20px;

  p {
    margin-bottom: 15px;
  }
}
</style>
