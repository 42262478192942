/* eslint-disable no-unused-vars */
const lng = navigator.browserLanguage || navigator.language || navigator.userLanguage;
// currency = ['USD', 'EUR', 'RUB', 'GBP', 'CAD', 'CZK', 'CNH'];
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

export default {
  pause(time = 1000) {
    return new Promise((resolve) => {
      setTimeout(resolve, time);
    });
  },
  getLocaleDateTimeString(date = null) {
    if (date === null) date = new Date();
    return new Date(date).toLocaleDateString(lng, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    });
  },
  getLocaleDateString(date = null) {
    if (date === null) date = new Date();
    return new Date(date).toLocaleDateString(lng, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  },
  getLocaleTimeString(date = null) {
    if (date === null) date = new Date();
    return new Date(date).toLocaleTimeString(lng, {});
  },
  getAmount(num) {
    return Number(num) % 1 === 0 ? Number(num).toLocaleString(lng) : Number(num).toFixed(2).toLocaleString(lng);
  },
  getLocateCurrency(num, curr = 'USD') {
    return Number(num).toLocaleString(lng, {
      style: 'currency',
      currency: curr,
    });
  },
  // getLocatePercent(num) {
  //   return (Number(num) * 100).toLocaleString(lng, {
  //     style: 'percent',
  //   });
  // },
  getLocatePercent(num) {
    // const percentValue = Number(num) / 100;
    return num.toLocaleString(lng, {
      style: 'percent',
      minimumFractionDigits: 2, // Минимум 2 знака после запятой
      maximumFractionDigits: 2, // Максимум 2 знака после запятой
    });
  },
  async externalAuth({ login, password }) {
    const authenticationDetails = new AuthenticationDetails({ Username: login, Password: password });
    const userData = {
      Username: login,
      Pool: new CognitoUserPool({ UserPoolId: 'us-east-1_CZEBlNVuv', ClientId: '3tgca6mnp6g138dbkcs7lq7j0a' }),
    };

    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (session) => {
          resolve({ type: 'onSuccess', session });
        },
        onFailure: (err) => {
          resolve({ type: 'error', err });
          // console.debug('Authentication failed: ', err);
        },
        // onFailure: (error) => {
        // reject({ type: 'error', error }),
        // }
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          resolve({ type: 'newPasswordRequired', userAttributes, requiredAttributes });
        },
        mfaRequired: (challengeName, challengeParameters) => {},
        totpRequired: (challengeName, challengeParameters) => {
          resolve({ type: 'totpRequired', cognitoUser, challengeName, challengeParameters });
        },
        customChallenge: (challengeParameters) => {},
        mfaSetup: (challengeName, challengeParameters) => {},
        selectMFAType: (challengeName, challengeParameters) => {},
        //  => {
        // В случае, если требуется изменить пароль
        // console.log('New password required:', userAttributes, requiredAttributes);
        // },
      });
    });
  },
  externalTOTP({ cognito, code }) {
    return new Promise((resolve, reject) => {
      if (cognito.challengeName === 'SOFTWARE_TOKEN_MFA') {
        cognito.cognitoUser.sendMFACode(
          code,
          {
            onSuccess: (session) => resolve({ type: 'onSuccess', session }),
            onFailure: (error) => resolve({ type: 'error', error }),
          },
          cognito.challengeName,
        );
      }
    });
  },
  async revokeToken({ token }) {
    const response = await fetch('https://cognito-idp.us-east-1.amazonaws.com/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-amz-json-1.1',
        Origin: 'https://app.alpaca.markets',
        Referer: 'https://app.alpaca.markets/',
        'X-Amz-Target': 'AWSCognitoIdentityProviderService.RevokeToken',
        'X-Amz-User-Agent': 'aws-amplify/5.0.4 js',
      },
      body: JSON.stringify({ ClientId: '3tgca6mnp6g138dbkcs7lq7j0a', Token: token }),
    });

    return { status: response.status, text: await response.text() };
  },
  formatValue: function (value, type) {
    if (value === undefined) return '';
    if (type === 'currency') return this.getLocateCurrency(value);
    if (type === 'number') return this.getAmount(value);
    if (type === 'percent') return this.getLocatePercent(value);
    if (type === 'date') return this.getLocaleDateString(value);
    if (type === 'datetime') return this.getLocaleDateTimeString(value);
    if (type === 'composite') {
      const result = [];
      for (const item of value) if (item.value) result.push(this.formatValue(item.value, item.type));
      return result.join(' / ');
    }
    return value;
  },
  round(num, decimal) {
    return Math.round(num * Math.pow(10, decimal)) / Math.pow(10, decimal);
  },
  getValue(row, field) {
    let keys = field.split('.');
    if (keys.length > 1) return this.getValue(row[keys.shift()], keys.join('.'));
    return row[keys[0]];
  },
  toFloat(value) {
    return parseFloat(value.replace(',', '.') || '0');
  },
  toLocaleString(value, decimal = 2) {
    return this.round(value, decimal).toString().replace('.', ',');
  },
  getColorClass(value) {
    if (Array.isArray(value)) value = value[0].value;
    return value > 0 ? 'profit' : value < 0 ? 'loss' : '';
  },
  findMinMax(arr, name, type = 'float') {
    let min = null,
      max = null;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][name] !== null) {
        const value = this.typing(arr[i][name], type);
        if (min === null) min = max = value;
        min = value < min ? value : min;
        max = value > max ? value : max;
      }
    }
    return { min, max };
  },
  typing(value, type = 'string') {
    if (type === 'float') return parseFloat(value);
    if (type === 'int') return parseInt(value);
    return value.toString();
  },
  readFileBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result.split(','));
        // const base64String = e.target.result.split(',')[1];
        // console.log('Base64 Encoded String:', base64String);
        // resolve(base64String);
      };
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  },
  readFileBuffer(file) {
    return new Promise((resolve, reject) => {
      if (!(file instanceof File)) {
        reject(new Error('Переданный объект не является файлом'));
        return;
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        // resolve(event.target.result);
        resolve(new Uint8Array(event.target.result));
        // const arrayBuffer = event.target.result;
        // const buffer = Buffer.from(arrayBuffer);
        // resolve(buffer);
      };
      reader.onerror = (event) => {
        reject(new Error('Ошибка чтения файла'));
      };
      reader.readAsArrayBuffer(file);
    });
  },
  async readFilePDF(file) {
    const globalThis = {}; // <script src="https://mozilla.github.io/pdf.js/build/pdf.js"></script>
    if (file.type !== 'application/pdf') return void console.error('Please select a PDF file.');
    const unit8Array = await this.readFileBuffer(file);

    const { pdfjsLib } = globalThis;
    pdfjsLib
      .getDocument(unit8Array)
      .promise.then((pdf) => {
        console.log('PDF loaded');

        const pagesPromises = [];
        for (let i = 1; i <= pdf.numPages; i++) {
          pagesPromises.push(
            pdf.getPage(i).then((page) => {
              const viewport = page.getViewport({ scale: 1.5 });
              const canvas = document.createElement('canvas');
              const context = canvas.getContext('2d');
              canvas.height = viewport.height;
              canvas.width = viewport.width;

              const renderContext = {
                canvasContext: context,
                viewport: viewport,
              };

              return page.render(renderContext).promise.then(() => {
                const img = document.createElement('img');
                img.src = canvas.toDataURL();
              });
            }),
          );
        }

        return Promise.all(pagesPromises);
      })
      .then(() => console.log('All pages rendered'));
  },
};

// function getPnL(type, amount, purchase, decor) {
//   let color = '', str = 'error';
//
//   if (purchase !== 0.0) {
//     color = 'style=\'color:' + ((amount < 0) ? 'red' : 'green') + '\'';
//     str = (type === 'percent') ? amount.toFixed(2) + ' ' + decor : parseFloat(amount).toLocaleString(lng, {
//       style: 'currency',
//       currency: decor,
//     });
//   }
//
//   return '<span ' + color + '>' + str + '</span>';
// }
//
// function getPrice(price, curr = 'USD') {
//   return '<span title=\'' + price.symbol + ': ' + getLocaleDateString(price.date_time) + ' close\' >' + getLocateCurrency(price.close, curr) + '</span>';
// }
//
