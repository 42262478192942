<template>
  <div class="documents">
    <div v-for="item in documents" :key="item.id" class="documet">
      <!-- <a :href="documentUrl + item.revisions.id" :download="item.document_name"></a> -->
      <!-- <a :href="documentUrl"></a> -->
      <!-- <div class="icon"><img :src="icons.pdf" alt="" @click="downloadFile(item.revisions.id, item.document_name)" /></div> -->
      <div class="icon"><img :src="icons.pdf" alt="" :download="item.document_name" /></div>
      <div class="title">{{ item.document_name }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { icons } from '@/assets/svg-img';

export default {
  name: 'CabinetComponent',
  components: {},
  data: function () {
    return {
      icons,
      documentUrl: process.env.VUE_APP_DOCUMENT_URL,
    };
  },
  computed: {
    ...mapGetters({
      documents: 'getDocuments',
    }),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      pullDocuments: 'pullDocuments',
    }),
    // async downloadFile(url, filename) {
    //   console.log(this.documentUrl, url, filename);
    //   const headers = { 'Access-Control-Allow-Origin': '*' };
    //   const data = await fetch(this.documentUrl + url, {
    //     mode: 'no-cors',
    //     // credentials: 'include',
    //     headers,
    //   });
    //   const blob = await data.blob();
    //   const objectUrl = URL.createObjectURL(blob);

    //   const link = document.createElement('a');

    //   link.setAttribute('href', objectUrl);
    //   link.setAttribute('download', filename);
    //   link.style.display = 'none';

    //   document.body.appendChild(link);

    //   link.click();

    //   document.body.removeChild(link);
    // },
  },
  watch: {},
  created() {},
  mounted() {
    this.pullDocuments();
  },
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.documents {
  display: flex;
  gap: 20px;
  @include breakpoint-down(md) {
    flex-direction: column;
    gap: 10px;
  }

  .documet {
    display: flex;
    align-items: flex-end;
    word-break: break-word;
    position: relative;

    a {
      position: absolute;
      inset: 0;
      cursor: pointer;
    }

    .icon {
      width: 25px;
      height: 25px;
      margin-right: 5px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .title {
    width: 100px;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
