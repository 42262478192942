<template>
  <div class="authentication">
    <div class="authentication-form">
      <div class="wrapper-inner">
        <div class="logo">
          <img :src="img.blueLogo" alt="" />
        </div>
        <div v-bind:class="status.error ? 'error' : 'success'">{{ status.text }}</div>
        <form @submit.prevent="signIn">
          <div :hidden="requireCode" class="input-wrap">
            <!-- <c-label hint-text="Ваш login это номер телефона указанный при регистрации" text="Login" /> -->
            <c-label hint-text="Ваш номер телефона является вашим логином, после регистрации" text="Login" />
            <input id="username" v-model="form.login" placeholder="login" required title="Login" type="text" />
          </div>
          <div :hidden="requireCode" class="input-wrap">
            <c-label text="Password" />
            <input
              id="password"
              v-model="form.password"
              autocomplete="off"
              placeholder="Password"
              required
              title="Password"
              type="password"
            />
          </div>
          <div :hidden="!requireCode" class="input-wrap">
            <c-label hint-text="Введите код из смс" text="Code" />
            <input
              id="code"
              v-model="form.code"
              autocomplete="off"
              placeholder="Code"
              title="Code"
              type="text"
              @input="checkCode($event.target)"
            />
          </div>
          <button :hidden="requireCode" class="primary" type="submit" @click="animate">Login</button>
        </form>

        <div class="hint-link">
          <router-link :to="{ name: 'password' }">забыли пароль?</router-link>
          <router-link :to="{ name: 'registration' }">нет аккаунта?</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import animation from '@/utils/animation';
import { img } from '@/assets/svg-img';
import CLabel from '@/components/UI/ComponentLabel.vue';

export default {
  name: 'auth-view',
  components: { CLabel },
  data() {
    return {
      img,
      requireCode: false,
      form: { login: '', password: '', code: '' },
      status: { text: '', error: false },
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      login: 'login',
      resetState: 'resetState',
    }),
    animate(e) {
      animation.pressing(e);
    },
    async signIn() {
      this.status = { text: 'Try!!!', error: false };
      if (this.form.login.at(0) === '8') this.form.login = '+7' + this.form.login.slice(1);
      const result = await this.login({ login: this.form.login, password: this.form.password, code: this.form.code });
      if (result.status === 'totp') {
        this.requireCode = true;
        this.status = { text: result.text, error: false };
      }
      if (result.status === 'logged') this.$router.push({ name: 'index' });
      this.status = { text: result.text, error: true };
    },
    checkCode(code) {
      code.value = code.value.replace(/\D+/g, '');
      if (code.value.length === 6) this.signIn();
    },
  },
  watch: {},
  created() {
    this.resetState();
  },
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
.info-wrap {
  display: flex;
  font-size: 11px;
  margin-top: 20px;
  justify-content: space-between;
  color: var(--pale-violet);

  a {
    color: inherit;
    font-size: 10px;
  }
}

.hint-link {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;

  a {
    color: rgba(163, 166, 204, 1);
  }
}
</style>
